import { useMantineTheme } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import { ColumnDef, PaginationState } from '@tanstack/react-table';
import cn from 'clsx';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ActionIcon,
  Badge,
  CopyIcon,
  Group,
  Menu,
  MenuDropdown,
  MenuItem,
  MenuTarget,
  PasswordModal,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Text,
  Title,
} from 'src/components';
import { Switch } from 'src/components/FormFields';
import { Table } from 'src/components/table';
import { CustomPagination } from 'src/components/table/tableFooter/TablePagination';
import { STATUS_MAPPING, WALLET_PASSWORD } from 'src/helper/constants';
import { actionCb } from 'src/utils/action';
import { getFEDate } from 'src/utils/date';
import { getPriceValue, getRowValue } from 'src/utils/table';
import classes from './WalletBot.module.scss';
import { STATUS_FILTER_MAPPING } from './constants';
import { useActions, useIndexData } from './selectorData';

const getInvoiceCount = (
  invoicesCount: Record<string, number>,
  label: string
) => {
  return get(invoicesCount, String(STATUS_FILTER_MAPPING[label])) || 0;
};

const WalletBot = () => {
  const navigate = useNavigate();
  const [activeFilter, setActiveFilter] = useState('Paid');
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [isPassword, setIsPassword] = useState(true);
  const [sortObj, setSortObj] = useState({
    createdAt: 'desc',
  } as Record<string, unknown>);
  const sortKey = (Object.keys(sortObj)[0] || 'createdAt') as string;
  const sortVal = (sortObj[sortKey] || 'asc') as string;
  const {
    balances,
    invoices,
    connectedStatus,
    invoicesCount,
    invoicesLoading,
    balancesLoading,
    apiServer,
  } = useIndexData();
  const switchValue: any = apiServer === 'prod';
  const isConnected = connectedStatus === 'connected';
  const { getInvoices, getWallet, syncUpdateWalletApiPrefix } = useActions();
  const hidePagination = true;
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: pageNumber || 1,
    pageSize: pageSize ?? 10,
  });
  const theme = useMantineTheme();
  const updateLabel = (cLabel: string) => {
    getInvoices(
      {
        status: STATUS_FILTER_MAPPING[cLabel] as string,
      },
      actionCb(() => {
        setActiveFilter(cLabel);
      })
    );
  };
  const filterEl = (
    <div className={classes.filterWrapper}>
      <Menu width={94} onChange={setDropdownOpened}>
        <MenuTarget>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              cursor: 'pointer',
              padding: '2px 5px',
              border: dropdownOpened ? '0.5px solid #696A72' : '',
              background: dropdownOpened ? '#F5F7FF' : 'transparent',
              borderRadius: '2px',
            }}
          >
            <Text color="primaryBlue" size="xs">
              Filter: {activeFilter} (
              {getInvoiceCount(invoicesCount, activeFilter)})
            </Text>
            <ActionIcon
              variant="transparent"
              color="primaryBlue"
              size={20}
              style={{ marginLeft: '4px' }}
            >
              {dropdownOpened ? <IconChevronUp /> : <IconChevronDown />}
            </ActionIcon>
          </div>
        </MenuTarget>
        <MenuDropdown className={classes.filterDropdown}>
          {['Pending', 'Paid', 'Cancelled'].map((label, index) => {
            return (
              <MenuItem
                onClick={() => updateLabel(label)}
                key={index}
                className={cn({
                  [classes.labelActive]: activeFilter === label,
                })}
              >
                {label} ({getInvoiceCount(invoicesCount, label)})
              </MenuItem>
            );
          })}
        </MenuDropdown>
      </Menu>
    </div>
  ) as any;
  const balanceColumns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        header: 'Currency',
        accessorKey: 'asset',
        cell: (info) => getRowValue(info.getValue()),
        footer: (props) => props.column.id,
      },
      {
        header: 'Balance',
        accessorKey: 'value_usd',
        cell: (info) => getPriceValue(info.getValue()),
        footer: (props) => props.column.id,
      },
      {
        header: 'Address',
        accessorKey: 'address',
        cell: (info) => {
          const val = info.getValue() as string;
          if (!val) return '-';
          return (
            <div className={classes.addressColumn}>
              <span>{val}</span>
              <CopyIcon text={val} />
            </div>
          );
        },
        footer: (props) => props.column.id,
      },
    ],
    []
  );
  const invoiceColumns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        header: 'UID',
        accessorKey: 'uid',
        cell: (info) => getRowValue(info.getValue()),
        footer: (props) => props.column.id,
      },
      {
        header: 'Amount',
        accessorKey: 'amount',
        cell: (info) => getPriceValue(info.getValue()),
        footer: (props) => props.column.id,
        sortable: true,
      },
      {
        header: 'Currency',
        accessorKey: 'invoice_currency',
        cell: (info) => getRowValue(info.getValue()),
        footer: (props) => props.column.id,
        sortable: true,
      },
      {
        header: 'Currency Amount',
        accessorKey: 'invoice_amount',
        cell: (info) => getRowValue(info.getValue()),
        footer: (props) => props.column.id,
        sortable: true,
      },
      {
        header: 'Created On',
        accessorKey: 'createdAt',
        cell: (info) => getFEDate(info.getValue(), '', '-'),
        footer: (props) => props.column.id,
        sortable: true,
      },
      {
        header: 'Paid On',
        accessorKey: 'paidAt',
        cell: (info) => getFEDate(info.getValue(), '', '-'),
        footer: (props) => props.column.id,
        sortable: true,
      },
      {
        header: 'Status',
        accessorKey: 'status',
        cell: (info) =>
          STATUS_MAPPING[info.getValue() as string] ||
          getRowValue(info.getValue()),
        footer: (props) => props.column.id,
        sortable: true,
      },
      {
        header: filterEl,
        accessorKey: 'filter',
        cell: () => '',
        footer: (props) => props.column.id,
        width: '150px',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeFilter]
  );

  useEffect(() => {
    getWallet();
    getInvoices({
      status: 'paid',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Stack>
        <Group>
          <Title size={32} className={classes.title}>
            WalletBot
            <Badge
              ml={24}
              color={!isConnected ? 'red' : 'green'}
              sx={{
                textTransform: 'capitalize',
                color: !isConnected
                  ? theme.colors.primaryRed[1]
                  : theme.colors.primaryGreen[1],
              }}
              size="md"
            >
              {connectedStatus}
            </Badge>
          </Title>
        </Group>
        <Group className={classes.tabGroup}>
          <div className={classes.apiSwitchWrapper}>
            <div className={classes.apiSwitch}>
              <span>Dev</span>
              <Switch
                label=""
                onLabel=""
                offLabel=""
                value={switchValue}
                onChange={(e) => {
                  syncUpdateWalletApiPrefix(e.target.checked ? 'prod' : 'dev');
                  getWallet();
                  getInvoices({
                    status: STATUS_FILTER_MAPPING[activeFilter] || 'paid',
                  });
                }}
              />
              <span>Prod</span>
            </div>
          </div>
          <Tabs defaultValue="balances" className={classes.balanceTab}>
            <TabList>
              <Tab value="balances">Balances</Tab>
              <Tab value="invoices">Invoices</Tab>
            </TabList>
            <TabPanel value="balances" pt="xs">
              {!isPassword && (
                <Table
                  data={balances}
                  columns={balanceColumns}
                  hasPagination={false}
                  totalCount={balances.length}
                  // pageSize={pageSize}
                  hasMultiselection={false}
                  onPaginationChange={() => {}}
                  manualPagination={false}
                  toolbarContent={{
                    title: '',
                    hasSearchbox: true,
                  }}
                  hideToolbar
                  hideFooter
                  onHeaderRefresh={() => {
                    getWallet();
                  }}
                  refreshLoading={balancesLoading}
                />
              )}
            </TabPanel>
            <TabPanel value="invoices" pt="xs" className={classes.invoicesTab}>
              {!isPassword && (
                <Table
                  data={
                    !!sortKey && !!sortVal
                      ? orderBy(
                          invoices,
                          sortKey,
                          sortVal === 'asc' ? 'asc' : 'desc'
                        )
                      : invoices
                  }
                  columns={invoiceColumns}
                  totalCount={invoices?.length}
                  // pageCount={invoices?.TotalPages}
                  // pageSize={pageSize}
                  pageNumber={pageNumber}
                  hasPagination={false}
                  hasMultiselection={false}
                  toolbarContent={{
                    title: '',
                    hasSearchbox: true,
                  }}
                  hideToolbar
                  onPaginationChange={({ pageIndex, pageSize: tPageSize }) => {
                    setPageNumber(pageIndex);
                    setPageSize(tPageSize);
                    // handleFetchData(pageIndex, tPageSize, searchVal);
                  }}
                  manualPagination={false}
                  onDownload={() => {
                    // TODO: export feature
                  }}
                  loading={invoicesLoading}
                  hideFooter
                  sortObj={sortObj}
                  onSortChange={(k, v) => {
                    setSortObj({
                      [k]: v,
                    });
                  }}
                />
              )}
              {!hidePagination && (
                <CustomPagination
                  pageCount={invoices?.length}
                  pagination={pagination}
                  setPagination={setPagination}
                  onPaginationChange={() => {}}
                  theme={theme}
                  className={classes.pagination}
                />
              )}
            </TabPanel>
          </Tabs>
        </Group>
      </Stack>
      <PasswordModal
        isOpened={isPassword}
        onClose={() => {
          setIsPassword(false);
          navigate(-1);
        }}
        onSubmit={(pass) => {
          if (pass === WALLET_PASSWORD) {
            setIsPassword(false);
          }
        }}
      />
    </>
  );
};

export default WalletBot;
