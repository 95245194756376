import moment from 'moment';

const FE_DATE_FORMAT = 'MM-DD-YY h:mm A';

export const getFEDate = (
  val: string | unknown,
  format?: string,
  defaultValue?: string
) => {
  if (!val) return defaultValue || '';
  return moment(String(val)).format(format || FE_DATE_FORMAT);
};
