import { AnyAction } from 'redux';
import { defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/merchantLocation';

const initialState = {
  merchantLocations: {
    ...defaultObj,
  },
  verifyLocationCSV: {
    ...defaultObj,
  },
  addLocationCSV: {
    ...defaultObj,
  },
};

const MerchantLocationReducer = (
  state = initialState,
  action: AnyAction
): typeof initialState => {
  const { type, payload } = action;

  switch (type) {
    case Types.SYNC_RESET_LOCATION_CSV: {
      return {
        ...state,
        verifyLocationCSV: {
          ...defaultObj,
        },
      };
    }
    case Types.SYNC_RESET_MERCHANT_LOCATIONS: {
      return {
        ...state,
        merchantLocations: {
          ...defaultObj,
        },
      };
    }
    case Types.GET_MECHANT_LOCATIONS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          merchantLocations: {
            ...prevState.merchantLocations,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          merchantLocations: {
            data: payload?.Data || {},
            loading: false,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          merchantLocations: {
            data: {},
            error: payload,
            loading: false,
          },
        }),
      });
    case Types.VERIFY_LOCATION_CSV:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          verifyLocationCSV: {
            ...prevState.verifyLocationCSV,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          verifyLocationCSV: {
            data: payload || {},
            loading: false,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          verifyLocationCSV: {
            data: {},
            error: payload,
            loading: false,
          },
        }),
      });
    case Types.ADD_LOCATION_CSV:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          addLocationCSV: {
            ...prevState.addLocationCSV,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          addLocationCSV: {
            data: payload || {},
            loading: false,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          addLocationCSV: {
            data: {},
            error: payload,
            loading: false,
          },
        }),
      });
    default:
      return state;
  }
};

export default MerchantLocationReducer;
