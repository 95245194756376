import { Drawer, useMantineTheme } from '@mantine/core';
import { ColumnDef } from '@tanstack/react-table';
import find from 'lodash/find';
import { useState } from 'react';
import { Avatar, Badge, Button, Group, Text } from 'src/components';
import { Input, Switch } from 'src/components/FormFields';
import { Table } from 'src/components/table';
import { actionCb } from 'src/utils/action';
import CloseButton from '../components/CloseButton';
import SuccessMessage from '../components/SuccessMessage';
import classes from './AddMerchantDrawer.module.scss';
import { useActions, useIndexData } from './selectorData';

const AddMerchantDrawer = ({
  opened,
  setOpened,
  onSuccessCb = () => {},
  clientId,
}) => {
  const {
    availableMerchants,
    addClientMerchantsLoading,
    availableMerchantsLoading,
  } = useIndexData();
  const { addClientMerchants } = useActions();
  const [editRows, setEditRows] = useState([]);
  const theme = useMantineTheme();
  const [selectedRows, setSelectedRows] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const selectedRowsLength = selectedRows.length;
  const displayMerchants = availableMerchants.map((m, i) => ({
    ...m,
    Id: i + 1,
    IsActive: false,
  }));
  const editIds = editRows.map((row) => row.Id);
  const isEditing = editRows.length > 0;
  const columns = [
    {
      header: '#',
      accessorKey: 'Id',
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      width: '100px',
    },
    {
      header: 'Merchant Name',
      accessorKey: 'MerchantName',
      cell: (info: any) => {
        return (
          <Group>
            <Avatar
              radius="xl"
              size="md"
              color="primaryBlue"
              src={info.row.original.MerchantLogo}
              className={classes.avatar}
            />
            <Text>{info.getValue()}</Text>
          </Group>
        );
      },
      footer: (props) => props.column.id,
    },
    {
      header: 'Status',
      accessorKey: 'IsActive',
      cell: (info) => (
        <Badge
          py={10}
          color={info.getValue() ? 'green' : 'primaryRed'}
          sx={{
            textTransform: 'capitalize',
            color: info.getValue()
              ? theme.colors.primaryGreen[1]
              : theme.colors.primaryRed[1],
          }}
          size="md"
        >
          {info.getValue() ? 'Active' : 'Inactive'}
        </Badge>
      ),
      footer: (props) => props.column.id,
      renderEdit: (value: any, name: string, onValue: any) => {
        return (
          <Switch
            label=""
            onLabel="ON"
            offLabel="OFF"
            value={value}
            onChange={(e) => {
              onValue(e.target.checked, name);
            }}
          />
        );
      },
    },
    {
      header: 'Rewards %',
      accessorKey: 'SavingsPercentage',
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      renderEdit: (value: any, name: string, onValue: any) => {
        return (
          <Input
            placeholder="Rewards"
            value={value}
            onChange={(e) => {
              onValue(e.target.value, name);
            }}
            rightSection={<>%</>}
          />
        );
      },
      width: '150px',
    },
  ] as ColumnDef<any>[];

  return (
    <Drawer
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      opened={opened}
      onClose={() => setOpened(false)}
      padding="lg"
      size="80%"
      position="right"
      className="my-class"
      sx={{ display: 'flex' }}
    >
      <div className={classes.inner}>
        {isSuccess ? (
          <SuccessMessage
            onClick={() => {
              onSuccessCb();
              setIsSuccess(false);
              setOpened(false);
            }}
            message={`${selectedRowsLength} new merchant${
              selectedRowsLength > 1 ? 's' : ''
            } have been added successfully.`}
          />
        ) : (
          <>
            <div className={classes.closeButton}>
              <CloseButton
                onClick={() => {
                  setOpened(false);
                }}
              />
            </div>
            <div className={classes.title}>
              <h2>Add New Merchants</h2>
              <p>Set Merchant status and rewards percentage below.</p>
            </div>
            <Table
              data={displayMerchants}
              columns={columns}
              totalCount={1}
              hasPagination={false}
              hasMultiselection={true}
              buttonCancel={{}}
              buttonSubmit={{}}
              toolbarContent={{
                title: '',
                hasSearchbox: false,
                displayTotal: false,
              }}
              onButtonSubmit={() => {}}
              onPaginationChange={() => {}}
              manualPagination={false}
              onDownload={() => {}}
              setSearch={() => {}}
              loading={availableMerchantsLoading}
              selectColumnWidth="120px"
              selectAllOneLine
              inlineEdit={{
                rows: editRows,
                onEditChange: (obj: any, val: any, name: string) => {
                  setEditRows(
                    editRows.map((row: any) => {
                      if (row.Id === obj.Id) {
                        row[name] = val;
                      }
                      return row;
                    })
                  );
                },
              }}
              selectedColor
              hideFooter
              hideToolbar
              onSelectionChange={(val) => {
                const newSelectedRows = displayMerchants.filter(
                  (m, i) => val[i]
                );
                setSelectedRows(newSelectedRows);
                if (isEditing) {
                  setEditRows(
                    newSelectedRows.map((row) => {
                      if (editIds.includes(row.Id)) {
                        return find(editRows, { Id: row.Id });
                      }
                      return row;
                    })
                  );
                }
              }}
              enableSelectionWhenEdit
            />
            <div className={classes.footer}>
              {selectedRowsLength > 0 && (
                <span>
                  Adding {selectedRowsLength} new merchant
                  {selectedRowsLength > 1 ? 's' : ''}
                </span>
              )}
              <Button
                type="button"
                onClick={() => {
                  if (!isEditing) {
                    setEditRows(selectedRows);
                  } else {
                    addClientMerchants(
                      clientId,
                      editRows.map((r) => ({
                        ...r,
                        Id: 0,
                        SavingsPercentage: r.SavingsPercentage
                          ? parseFloat(String(r.SavingsPercentage))
                          : 0,
                      })),
                      actionCb(() => {
                        setIsSuccess(true);
                      })
                    );
                  }
                }}
                disabled={selectedRowsLength <= 0}
                loading={addClientMerchantsLoading}
              >
                Save and Add
              </Button>
            </div>
          </>
        )}
      </div>
    </Drawer>
  );
};

export default AddMerchantDrawer;
