import { AppShell, useMantineTheme } from '@mantine/core';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Header, Navbar } from '../../components';

export const Layout = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const token = localStorage.getItem('token');
  // if(!token)
  //  return <Navigate to="/login" replace state={{ path: location.pathname }} />
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const openNavbar = () => {
    setOpened(true);
  };

  const closeNavbar = () => {
    setOpened(false);
  };
  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="md"
      navbar={<Navbar opened={opened} onCloseNavbar={closeNavbar} />}
      // footer={<Footer />}
      header={
        <Header
          onOpenNavbar={openNavbar}
          onCloseNavbar={closeNavbar}
          opened={opened}
        />
      }
    >
      <Outlet />
    </AppShell>
  );
};
