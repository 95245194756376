import get from 'lodash/get';
import { matchRoutes, useLocation } from 'react-router-dom';
import { ROUTES } from 'src/helper/constants';

const useCurrentPath = () => {
  const location = useLocation();
  const matches = matchRoutes(ROUTES, location);
  const matchRouter = get(matches, '[0].route.path');

  return matchRouter;
};

export default useCurrentPath;
