import { useMantineTheme } from '@mantine/core';
import { IconPlus } from '@tabler/icons';
import { ColumnDef } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Edit, Trash } from 'tabler-icons-react';
import {
  GiftCardGateway,
  GiftCardGateways,
} from 'src/business/app/constants/merchant.constant';
import { TypeMerchant } from 'src/business/app/types/TypeMerchant';
import {
  Avatar,
  Badge,
  Button,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  Title,
} from 'src/components';
import { Input, Switch } from 'src/components/FormFields';
import { ContentHeader } from 'src/components/Page';
import { Table } from 'src/components/table';
import { actionCb } from 'src/utils/action';
import AddMerchantDrawer from './AddMerchantDrawer';
import classes from './ClientDetail.module.scss';
import { useActions, useIndexData } from './selectorData';

export const ClientDetail = () => {
  const { id: clientId } = useParams();
  const theme = useMantineTheme();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [opened, setOpened] = useState(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [resetSelection, setResetSelection] = useState('0');
  const [searchVal, setSearchVal] = useState<string>('');
  const [editRows, setEditRows] = useState<TypeMerchant[]>([]);
  const isEditing = editRows.length > 0;
  const {
    getClientMerchants,
    updateClientMerchants,
    syncUpdateClientMerchants,
    getClientAvailableMerchants,
    deleteClientMerchants,
  } = useActions();
  const { clientData, clientDetailLoading, updateClientMerchantsLoading } =
    useIndexData();
  const merchants = clientData.ClientMerchants || {};
  const onEditRows = (rows: any) => {
    if (isEditing) {
      updateClientMerchants(
        clientId,
        editRows.map((r) => ({
          ...r,
          SavingsPercentage: r.SavingsPercentage
            ? parseFloat(String(r.SavingsPercentage))
            : r.SavingsPercentage,
        })),
        actionCb((res) => {
          setEditRows([]);
          setResetSelection(resetSelection === '1' ? '2' : '1');
          const newRecords = res.Results || [];
          syncUpdateClientMerchants(newRecords);
        })
      );
    } else {
      setEditRows(rows);
    }
  };
  const onDeleteRows = (rows: any) => {
    if (isEditing) {
      setDeleteLoading(true);
      deleteClientMerchants(
        clientId,
        editRows,
        actionCb(
          () => {
            setDeleteLoading(false);
            setEditRows([]);
            setResetSelection(resetSelection === '1' ? '2' : '1');
            setPageNumber(1);
            initData(pageSize);
          },
          () => {
            setDeleteLoading(false);
          }
        )
      );
    } else {
      setEditRows(rows);
    }
  };
  const columns = [
    {
      header: 'Id',
      accessorKey: 'Id',
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      width: '100px',
    },
    {
      header: 'Merchant Name',
      accessorKey: 'MerchantName',
      cell: (info: any) => {
        return (
          <Group>
            <Avatar
              radius="xl"
              size="md"
              color="primaryBlue"
              src={info.row.original.MerchantLogo}
              className={classes.avatar}
            />
            <Text>{info.getValue()}</Text>
          </Group>
        );
      },
      footer: (props) => props.column.id,
    },
    {
      header: 'Status',
      accessorKey: 'IsActive',
      cell: (info) => (
        <Badge
          py={10}
          color={info.getValue() ? 'green' : 'primaryRed'}
          sx={{
            textTransform: 'capitalize',
            color: info.getValue()
              ? theme.colors.primaryGreen[1]
              : theme.colors.primaryRed[1],
          }}
          size="md"
        >
          {info.getValue() ? 'Active' : 'Inactive'}
        </Badge>
      ),
      footer: (props) => props.column.id,
      renderEdit: (value: any, name: string, onValue: any) => {
        return (
          <Switch
            label=""
            onLabel="ON"
            offLabel="OFF"
            value={value}
            onChange={(e) => {
              onValue(e.target.checked, name);
            }}
          />
        );
      },
    },
    {
      header: 'Rewards %',
      accessorKey: 'SavingsPercentage',
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      renderEdit: (value: any, name: string, onValue: any) => {
        return (
          <Input
            placeholder="Rewards"
            value={value}
            onChange={(e) => {
              onValue(e.target.value, name);
            }}
            rightSection={<>%</>}
          />
        );
      },
      width: '150px',
    },
    {
      header: 'Gateway',
      accessorKey: 'GatewayId',
      cell: (info) => {
        const type: GiftCardGateway = info.getValue() as GiftCardGateway;
        return GiftCardGateways[type];
      },
      footer: (props) => props.column.id,
    },
  ] as ColumnDef<TypeMerchant>[];
  const reloadData = (size, page, cb = null) => {
    getClientMerchants(
      clientId,
      {
        pageNumber: page,
        pageSize: size,
      },
      cb
    );
  };
  const initData = (tPageSize = 20) => {
    setLoading(true);
    reloadData(tPageSize, 1, () => {
      setLoading(false);
    });
  };

  useEffect(() => {
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LoadingOverlay visible={loading} />
      <Stack>
        <Group>
          <Title size={32} className={classes.title}>
            {clientData.ClientName} Merchants
          </Title>
        </Group>
        <div className={classes.mainContent}>
          <ContentHeader title="Merchants" className={classes.contentHeader}>
            <Button
              rightIcon={<IconPlus size={20} />}
              onClick={() => {
                setOpened(true);
                getClientAvailableMerchants(clientId);
              }}
            >
              Add Merchants
            </Button>
          </ContentHeader>
          <Table
            data={merchants.Items || []}
            columns={columns}
            totalCount={merchants?.TotalCount}
            pageCount={merchants?.TotalPages}
            pageSize={pageSize}
            pageNumber={pageNumber}
            hasPagination={true}
            hasMultiselection={true}
            buttonCancel={{
              children: (
                <Text underline size="md" weight={500}>
                  Cancel
                </Text>
              ),
              variant: 'subtle',
              sx: (theme) => ({
                '&:disabled': {
                  backgroundColor: theme.colors.gray[0],
                },
              }),
              onClick: isEditing
                ? () => {
                    setEditRows([]);
                    setResetSelection(resetSelection === '1' ? '2' : '1');
                  }
                : null,
              disabled: isEditing && updateClientMerchantsLoading,
            }}
            buttonSubmit={{
              leftIcon: isEditing ? null : <Edit />,
              children: isEditing ? 'Save' : 'Edit',
              loading: isEditing && updateClientMerchantsLoading,
            }}
            buttonDelete={
              isEditing
                ? {
                    leftIcon: <Trash />,
                    variant: 'subtle',
                    sx: (theme) => ({
                      '&:disabled': {
                        backgroundColor: theme.colors.gray[0],
                      },
                    }),
                    children: (
                      <Text size="md" weight={500}>
                        Delete
                      </Text>
                    ),
                    loading: deleteLoading,
                  }
                : undefined
            }
            toolbarContent={{
              title: '',
              hasSearchbox: false,
              displayTotal: false,
            }}
            onButtonSubmit={onEditRows}
            onButtonDelete={onDeleteRows}
            onPaginationChange={({ pageIndex, pageSize: tPageSize }) => {
              setPageNumber(pageIndex);
              setPageSize(tPageSize);
              reloadData(tPageSize, pageIndex);
            }}
            manualPagination={true}
            onDownload={() => {
              // TODO: export feature
            }}
            resetSelection={resetSelection}
            search={searchVal}
            setSearch={(val: string) => {
              setSearchVal(val);
            }}
            loading={clientDetailLoading}
            selectColumnWidth="120px"
            selectAllOneLine
            inlineEdit={{
              rows: editRows,
              onEditChange: (obj: any, val: any, name: string) => {
                setEditRows(
                  editRows.map((row: any) => {
                    if (row.Id === obj.Id) {
                      row[name] = val;
                    }
                    return row;
                  })
                );
              },
            }}
            selectedColor
          />
        </div>
      </Stack>
      <AddMerchantDrawer
        opened={opened}
        setOpened={setOpened}
        onSuccessCb={() => {
          setPageNumber(1);
          initData(pageSize);
        }}
        clientId={clientId}
      />
    </>
  );
};
