import { Drawer } from '@mantine/core';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { Plus, Refresh } from 'tabler-icons-react';
import {
  GiftCardGateway,
  GiftCardGateways,
} from 'src/business/app/constants/merchant.constant';
import { TypeMerchant } from 'src/business/app/types/TypeMerchant';
import { Avatar, Button, Group, Stack, Text } from 'src/components';
// import { applySearch } from "src/utils";
import { Confirm } from 'src/components/common/ModelDialog';
import { Table } from 'src/components/table';
import { ERoutes } from 'src/helper/constants';
import { actionCb } from 'src/utils/action';
import { AddEditMerchants } from '../addEditMerchants';
import { useActions, useIndexData } from './selectorData';

export const NewMerchants = () => {
  const {
    getNewMerchants,
    addMerchant,
    syncResetMerchantLocations,
    syncResetLocationCSV,
    getUnconfirmMerchants,
    confirmMerchants,
    updateMerchant,
    addLocationCSV,
    getMerchantLocations,
  } = useActions();
  const hideUnconfirmMerchant = true;
  const { newMerchants, newMerchantsLoading } = useIndexData();
  const merchantRecord = (newMerchants || []) as TypeMerchant[];
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [opened, setOpened] = useState<boolean>(false);
  const [closeDrawer, setCloseDrawer] = useState<boolean>(false);
  const [selectedMerchant, setSelectedMerchant] = useState<TypeMerchant[]>([]);
  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        header: '#',
        accessorKey: 'IdDisplay',
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: 'Merchant Name',
        accessorKey: 'LegalName',
        cell: (info: CellContext<TypeMerchant, any>) => (
          <Group>
            <Avatar
              radius="xl"
              size="md"
              color="primaryBlue"
              src={info.row.original.LogoUrl}
            />
            <Text>{info.getValue()}</Text>
          </Group>
        ),
        footer: (props) => props.column.id,
      },
      {
        header: 'Gateway',
        accessorKey: 'GiftCardGatewayId',
        cell: (info) => {
          const type: GiftCardGateway = info.getValue() as GiftCardGateway;
          return GiftCardGateways[type];
        },
        footer: (props) => props.column.id,
      },
    ],
    []
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleFetchData = (pSearch: string) => {
    getNewMerchants({
      // searchText: pSearch,
    });
  };
  const toolbarContentRight = merchantRecord.length > 0 && (
    <Button
      leftIcon={<Refresh strokeWidth={2} />}
      variant="subtle"
      color="primaryBlue"
      sx={(theme) => ({ '&:hover': { backgroundColor: theme.colors.gray[0] } })}
      onClick={() => {
        if (!newMerchantsLoading) {
          handleFetchData('');
        }
      }}
    >
      <Text underline size="md" weight={500}>
        Refresh
      </Text>
    </Button>
  );
  const onAddRows = (rows: any) => {
    syncResetMerchantLocations();
    syncResetLocationCSV();
    setSelectedMerchant(rows);
    setOpened(true);
  };
  const handleActionCb = ({ other, res, cb, values }: any) => {
    const thisMerchantId = res[0]?.Id || '';
    const cbFn = () => {
      const newMerchants = selectedMerchant.map((m, i) => {
        if (i === other?.index - 1) {
          return res[0];
        }
        return m;
      });
      setSelectedMerchant(newMerchants);
      if (cb) cb();
      if (other?.isSaveList) {
        confirmMerchants(newMerchants.map((m) => m.Id));
      }
    };
    const locationFile = (values?.LocationFiles || [])[0];
    if (
      !!locationFile &&
      locationFile.path &&
      locationFile.type &&
      locationFile.lastModified &&
      !!thisMerchantId
    ) {
      const fData = new FormData();
      fData.append('File', locationFile);
      addLocationCSV(
        thisMerchantId,
        fData,
        actionCb(() => {
          cbFn();
        })
      );
    } else {
      cbFn();
    }
  };
  useEffect(() => {
    handleFetchData('');
    syncResetMerchantLocations();
    syncResetLocationCSV();
    if (!hideUnconfirmMerchant) {
      getUnconfirmMerchants(
        actionCb((res) => {
          const unconfirmMechants = res || [];
          if (res.length) {
            getMerchantLocations(res[0]?.Id);
            setSelectedMerchant(unconfirmMechants);
            setOpened(true);
          }
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Stack>
        <Table
          data={merchantRecord.map((m, index) => ({
            ...m,
            IsActive: false,
            IdDisplay: index + 1,
          }))}
          columns={columns}
          hasPagination={false}
          totalCount={merchantRecord.length}
          // pageSize={pageSize}
          hasMultiselection={true}
          buttonCancel={{
            color: 'primaryBlue',
            children: (
              <Text underline size="md" weight={500}>
                Cancel
              </Text>
            ),
            variant: 'subtle',
            sx: (theme) => ({
              '&:disabled': {
                backgroundColor: theme.colors.gray[0],
              },
            }),
          }}
          buttonSubmit={{
            leftIcon: <Plus />,
            color: 'primaryBlue',
            children: 'Add Merchant',
          }}
          toolbarContent={{
            title: 'New Merchants',
            hasSearchbox: true,
            toolbarContentRight: toolbarContentRight,
            displayTotal: merchantRecord.length > 0,
          }}
          onButtonSubmit={onAddRows}
          refreshMerchantRecord={() => {
            if (!newMerchantsLoading) {
              handleFetchData('');
            }
          }}
          showEmptySate={true}
          onPaginationChange={() => {}}
          manualPagination={false}
          clientSearch
          onRowClick={(row) => {
            onAddRows([row.original]);
          }}
        />
      </Stack>
      <Drawer
        withCloseButton={false}
        opened={opened}
        onClose={() => setOpened(false)}
        padding="lg"
        size="80%"
        position="right"
        className="my-class"
        sx={{ display: 'flex' }}
      >
        <AddEditMerchants
          selectedMerchant={selectedMerchant}
          setIsSuccess={setIsSuccess}
          setOpened={setOpened}
          type="Add"
          onClose={(val) => {
            if (val !== true) {
              setCloseDrawer(true);
            } else {
              setOpened(false);
            }
          }}
          onSubmit={(payload, cb, other) => {
            const values = other?.values;
            if (payload.Id > 0) {
              updateMerchant(
                payload.Id,
                payload,
                actionCb((res) => {
                  handleActionCb({ other, res: [res], cb, values });
                })
              );
            } else {
              addMerchant(
                [payload],
                actionCb((res) => {
                  handleActionCb({ other, res, cb, values });
                })
              );
            }
          }}
          onStepCb={(thisActive: number) => {
            syncResetMerchantLocations();
            syncResetLocationCSV();
            const thisSelectedMerchant = selectedMerchant[thisActive - 1];
            if (
              !!thisSelectedMerchant?.Id &&
              thisSelectedMerchant?.IsPhysical
            ) {
              getMerchantLocations(thisSelectedMerchant.Id);
            }
          }}
        />
      </Drawer>
      {!isSuccess && (
        <Confirm
          isOpened={closeDrawer}
          location={ERoutes.NewMerchants}
          setPreviousBack={(value: boolean) => {
            setCloseDrawer(false);
            if (value) setOpened(false);
          }}
        ></Confirm>
      )}
    </>
  );
};
