import * as clientActions from 'src/store/actions/client';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  const clientAvailableMerchantsStore = useReducerData(
    'client',
    'clientAvaialbleMerchants',
    {}
  );
  const addClientMerchantsStore = useReducerData(
    'client',
    'addClientMerchants',
    {}
  );
  return {
    availableMerchants: clientAvailableMerchantsStore.data?.Items || [],
    availableMerchantsLoading: clientAvailableMerchantsStore.loading || false,
    addClientMerchantsLoading: addClientMerchantsStore.loading,
  };
};

export const useActions = () => {
  return useStoreActions({
    ...clientActions,
  });
};
