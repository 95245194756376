import { Table as MantineTable, useMantineTheme } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { IconRefresh } from '@tabler/icons';
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils';
import {
  ColumnDef,
  FilterFn,
  PaginationState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import cn from 'clsx';
import compact from 'lodash/compact';
import get from 'lodash/get';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { LoadingOverlay, Paper } from 'src/components';
import { IButtonProps } from 'src/components/Button';
import { Checkbox } from 'src/components/FormFields';
import { EmptyState } from '../common/EmptyState/EmptyState';
import classes from './Table.module.scss';
import { TableContext } from './context';
import { TableBody } from './tableBody';
import { TableFooter } from './tableFooter';
import { TableHeader } from './tableHeader';
import { TableToolbar, TableToolbarProps } from './tableToolbar';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

export interface TableProps<T> {
  data: T[];
  columns: ColumnDef<T>[];
  totalCount?: number;
  pageSize?: number;
  hasPagination: boolean;
  hasMultiselection: boolean;
  buttonCancel?: IButtonProps;
  buttonSubmit?: IButtonProps;
  toolbarContent: TableToolbarProps;
  refreshMerchantRecord?: () => void;
  onButtonSubmit?: (rows: T[]) => void;
  onButtonDelete?: (rows: T[]) => void;
  showEmptySate?: boolean;
  onPaginationChange: (val: PaginationState) => void;
  pageCount?: number;
  pageNumber?: number;
  currentPage?: number;
  manualPagination?: boolean;
  onDownload?: () => void;
  resetSelection?: string;
  search?: string;
  setSearch?: (val: string) => void;
  onRowClick?: (row?: any) => void;
  clientSearch?: boolean;
  loading?: boolean;
  hideToolbar?: boolean;
  hideFooter?: boolean;
  onHeaderRefresh?: () => void;
  sortObj?: any;
  onSortChange?: (key: string, val: string) => void;
  refreshLoading?: boolean;
  selectColumnWidth?: string;
  selectAllOneLine?: boolean;
  inlineEdit?: any;
  selectedColor?: boolean;
  enableSelectionWhenEdit?: boolean;
  onSelectionChange?: any;
  buttonDelete?: IButtonProps;
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export function Table<T>({
  data,
  columns,
  hasPagination,
  hasMultiselection,
  buttonCancel,
  buttonSubmit,
  buttonDelete,
  toolbarContent,
  refreshMerchantRecord,
  onButtonSubmit,
  onButtonDelete,
  showEmptySate,
  onPaginationChange,
  pageSize,
  pageCount,
  pageNumber,
  manualPagination,
  onDownload,
  resetSelection,
  search,
  setSearch,
  onRowClick,
  clientSearch,
  loading,
  hideToolbar,
  onHeaderRefresh,
  hideFooter,
  sortObj,
  onSortChange,
  refreshLoading,
  totalCount,
  selectColumnWidth,
  selectAllOneLine,
  inlineEdit,
  selectedColor,
  onSelectionChange,
  enableSelectionWhenEdit,
}: TableProps<T>) {
  const isEditing = (get(inlineEdit, 'rows') || []).length > 0;
  const [value, setValue] = useDebouncedState('', 50);
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: pageNumber || 1,
    pageSize: pageSize ?? 10,
  });
  const isSelected = useMemo(
    () => data.length > 0 && !!Object.keys(rowSelection).length,
    [data.length, rowSelection]
  );
  const ApiDataEmpty = useMemo(() => !!data.length, [data.length]);
  const manualPaginationOptions = manualPagination
    ? {
        onPaginationChange: setPagination,
        pageCount: pageCount,
        manualPagination: manualPagination,
      }
    : {};
  const paginationOptions = hasPagination
    ? {
        getPaginationRowModel: getPaginationRowModel(),
        ...manualPaginationOptions,
      }
    : {};
  const table = useReactTable({
    data,
    columns: compact([
      ApiDataEmpty && hasMultiselection
        ? {
            header: ({ table }) => (
              <div className={classes.checkboxAll}>
                <Checkbox
                  checked={table.getIsAllRowsSelected()}
                  indeterminate={table.getIsSomeRowsSelected()}
                  onChange={table.getToggleAllRowsSelectedHandler()}
                  size={'xs'}
                  disabled={!enableSelectionWhenEdit && isEditing}
                />
                <span
                  onClick={() => {
                    if (!isEditing) {
                      table.toggleAllRowsSelected();
                    }
                  }}
                >
                  {selectAllOneLine ? (
                    <>Select All</>
                  ) : (
                    <>
                      Select
                      <br />
                      All
                    </>
                  )}
                </span>
              </div>
            ),
            accessorKey: 'selectAll',
            cell: ({ row }) => (
              <div
                className="px-1"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                style={{ display: 'inline-block' }}
              >
                <Checkbox
                  checked={row.getIsSelected()}
                  indeterminate={row.getIsSomeSelected()}
                  onChange={row.getToggleSelectedHandler()}
                  size={'xs'}
                  disabled={!enableSelectionWhenEdit && isEditing}
                />
              </div>
            ),
          }
        : undefined,
      ...columns,
    ]),
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      globalFilter: value,
      rowSelection,
    },
    onGlobalFilterChange: setValue,
    globalFilterFn: fuzzyFilter,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: true,
    ...paginationOptions,
  });
  const isEmpty = table.getRowModel().rows.length === 0;
  const theme = useMantineTheme();
  useEffect(() => {
    if (onSelectionChange) onSelectionChange(rowSelection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);
  useEffect(() => {
    if (resetSelection === '1' || resetSelection === '2') {
      setRowSelection({});
    }
  }, [resetSelection]);
  useEffect(() => {
    if (pageNumber && pageNumber !== pagination.pageIndex) {
      setPagination({
        ...pagination,
        pageIndex: pageNumber,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  return (
    <TableContext.Provider
      value={{
        table,
        value,
        setValue,
        theme,
        buttonCancel,
        buttonDelete,
        isSelected,
        buttonSubmit,
        onButtonSubmit,
        onButtonDelete,
        pageCount,
      }}
    >
      <Fragment>
        {!hideToolbar && (
          <TableToolbar
            title={toolbarContent.title!}
            hasSearchbox={toolbarContent.hasSearchbox}
            displayTotal={toolbarContent.displayTotal}
            toolbarContentRight={toolbarContent.toolbarContentRight}
            search={search}
            setSearch={setSearch}
            clientSearch={clientSearch}
            totalRecords={totalCount || 0}
            page={pagination.pageIndex || 0}
            pageSize={pagination.pageSize || 0}
            manualPagination={manualPagination}
          />
        )}
        <Paper
          radius="sm"
          sx={{ minHeight: '40rem', display: 'flex', flexDirection: 'column' }}
          className={classes.tableWrapper}
        >
          <LoadingOverlay
            visible={loading || false}
            className={classes.loadingOverlay}
          />
          {!!onHeaderRefresh && (
            <span
              onClick={onHeaderRefresh}
              className={cn(classes.headerRefresh, {
                [classes.isRefreshLoading]: refreshLoading,
              })}
            >
              <IconRefresh size={24} />
            </span>
          )}
          <MantineTable
            horizontalSpacing="sm"
            verticalSpacing="sm"
            mb={60}
            className="table-body"
          >
            <TableHeader
              columns={columns}
              sortObj={sortObj}
              onSortChange={onSortChange}
              hasMultiselection={hasMultiselection}
              selectColumnWidth={selectColumnWidth}
            />
            <TableBody
              onRowClick={onRowClick}
              inlineEdit={inlineEdit}
              columns={columns}
              selectedColor={selectedColor}
            />
          </MantineTable>
          {isEmpty && showEmptySate && (
            <EmptyState
              name="findNewMerchant"
              onClick={refreshMerchantRecord}
            />
          )}
        </Paper>

        {!isEmpty && !hideFooter ? (
          <TableFooter
            hasPagination={hasPagination}
            pageSize={pageSize}
            onPaginationChange={isEditing ? () => {} : onPaginationChange}
            onDownload={onDownload}
            setPagination={isEditing ? () => {} : setPagination}
            pagination={pagination}
          />
        ) : null}
      </Fragment>
    </TableContext.Provider>
  );
}
