import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Group, LoadingOverlay, Stack, Title } from 'src/components';
import { PageCard } from 'src/components/Page';
import { Confirm } from 'src/components/common/ModelDialog';
import { useCallbackPrompt } from 'src/helper/hooks';
import { actionCb } from 'src/utils/action';
import { AddEditMerchants } from '../merchants/addEditMerchants';
import classes from './MerchantDetail.module.scss';
import { useActions, useIndexData } from './selectorData';

export const MerchantDetail = () => {
  const { merchantDetail, merchantDetailLoading } = useIndexData();
  const {
    getMerchant,
    updateMerchant,
    getMerchantLocations,
    syncResetLocationCSV,
    syncResetMerchantLocations,
    syncUpdateMerchantDetail,
    addLocationCSV,
  } = useActions();
  const navigate = useNavigate();
  const { id: merchantId } = useParams();
  const [isWarning, setIsWarning] = useState(false);
  const [formIsChanged, setFormIsChanged] = useState<boolean>(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(formIsChanged);
  const loadMerchant = () => {
    syncResetLocationCSV();
    syncResetMerchantLocations();
    if (merchantId) {
      getMerchant(merchantId);
      getMerchantLocations(merchantId);
    }
  };

  useEffect(() => {
    loadMerchant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantId]);

  return (
    <>
      <Stack>
        <Group>
          <Title size={32}>Edit Merchant</Title>
        </Group>
        <PageCard className={classes.content} fixedFooter>
          <LoadingOverlay visible={merchantDetailLoading} />
          <AddEditMerchants
            notScroll
            selectedMerchant={[merchantDetail]}
            setIsSuccess={() => {}}
            setOpened={() => {}}
            type="Edit"
            onClose={() => {}}
            onSubmit={(payload, cb, other) => {
              const updateMerchantFn = () => {
                if (!payload.IsPhysical) {
                  syncResetMerchantLocations();
                }
                updateMerchant(
                  payload.Id,
                  payload,
                  actionCb((res) => {
                    syncUpdateMerchantDetail(res);
                    setFormIsChanged(false);
                    getMerchantLocations(merchantId || '', () => {});
                    if (cb) cb();
                  })
                );
              };
              const values = other?.values;
              const locationFile = (values?.LocationFiles || [])[0];
              if (
                !!locationFile &&
                locationFile.path &&
                locationFile.type &&
                locationFile.lastModified
              ) {
                const fData = new FormData();
                fData.append('File', locationFile);
                addLocationCSV(
                  payload.Id,
                  fData,
                  actionCb(() => {
                    updateMerchantFn();
                  })
                );
              } else {
                updateMerchantFn();
              }
            }}
            footerFixed
            onFormChange={(val) => {
              setFormIsChanged(val);
            }}
            onBack={() => {
              navigate('/all-merchants');
            }}
          />
        </PageCard>
      </Stack>
      <Confirm
        isOpened={isWarning || !!showPrompt}
        location={''}
        setPreviousBack={(value: boolean) => {
          if (showPrompt) {
            if (value) {
              if (typeof confirmNavigation === 'function') {
                confirmNavigation();
              }
            } else {
              if (typeof cancelNavigation === 'function') {
                cancelNavigation();
              }
            }
          } else {
            setIsWarning(false);
            if (value) {
              navigate('/all-merchants');
            }
          }
        }}
        des="By going back, any changes made will be lost. Do you want to continue?"
        submitText="Yes, go back"
      ></Confirm>
    </>
  );
};
