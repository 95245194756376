import {
  ColorScheme,
  ColorSchemeProvider,
  Global,
  MantineProvider,
  // useMantineTheme,
} from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { NotificationsProvider } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from './modules/routes';
import themeCustom from './theme/themeCustom';
import './App.css';

// import { AuthState } from "./app/types";

export const GlobalStyle = () => {
  return (
    <Global
      styles={(theme) => ({
        '*, *::before, *::after': {
          boxSizing: 'border-box',
        },

        body: {
          color: theme.colors.primaryBlack[0],
        },
      })}
    />
  );
};

export default function App() {
  //const authstate: AuthState = useAppSelector((state) => state.auth);
  // const userFullName = authstate.user.FullName;
  // const userRole = authstate.user.Role;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();
  // const handleLogOut = () => {
  //   localStorage.removeItem("token");
  //   navigate("/");
  // };
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'color-scheme',
    defaultValue: 'light',
  });

  const toggleColorScheme = (value: ColorScheme) => {
    setColorScheme(value || colorScheme === 'dark' ? 'light' : 'dark');
  };
  // const theme = useMantineTheme();

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          colorScheme: colorScheme,
          ...(themeCustom as any),
        }}
      >
        <NotificationsProvider autoClose={5000} position="top-right">
          <GlobalStyle />
          <AppRoutes />
        </NotificationsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}
