import React from 'react';
import { Grid, GridCol, Text } from 'src/components';
import { FormikInputText, FormikNumberInput } from 'src/components/FormFields';

const DetailSection = () => {
  return (
    <>
      <Text size={16} weight={500} underline color="default">
        Review Merchant Details
      </Text>
      <Grid>
        <GridCol span={4}>
          <FormikInputText
            label="Merchant Name"
            placeholder="Merchant Name"
            name="LegalName"
            required
          />
        </GridCol>
        <GridCol span={3}>
          <FormikNumberInput
            label="Minimum Card Purchase"
            placeholder="Minimum Card Purchase"
            name="MinimumCardPurchase"
            min={5}
            defaultValue={5}
            prefix="$"
            hideControls
            required
          />
        </GridCol>
        <GridCol span={3}>
          <FormikNumberInput
            label="Maximum Card Purchase"
            placeholder="Maximum Card Purchase"
            prefix="$"
            name="MaximumCardPurchase"
            hideControls
            required
          />
        </GridCol>
        <GridCol span={2}>
          <FormikNumberInput
            label="Discount from Gateway"
            placeholder="Discount from Gateway"
            prefix=""
            name="RevenueShare"
            hideControls
            suffix="%"
            required
          />
        </GridCol>
      </Grid>
      <Grid>
        <GridCol span={4}>
          <FormikInputText
            label="Website"
            placeholder="Website"
            name="Website"
          />
        </GridCol>
        <GridCol span={8}>
          <FormikInputText
            label="Description"
            placeholder="Description"
            name="Description"
          />
        </GridCol>
      </Grid>
    </>
  );
};

export default DetailSection;
