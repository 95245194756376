import { Field } from 'formik';
// import get from "lodash/get";
import MultiSelect, { IMultiSelectProps } from '../MultiSelect';

type Props = IMultiSelectProps & {
  name: string;
  onChangeTrigger?: () => void;
};

const FormikMultiSelect = (props: Props) => {
  const { name, onChangeTrigger, ...otherProps } = props;

  return (
    <Field name={name}>
      {(fObj: any) => {
        const {
          field, // { name, value, onChange, onBlur }
          form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        } = fObj;
        const { setFieldValue } = form;
        const value = field.value;
        // const errorMessage = get(errors, field.name);
        return (
          <MultiSelect
            {...otherProps}
            {...field}
            value={value || []}
            onChange={(val) => {
              setFieldValue(name, val?.length ? val : null);
              if (onChangeTrigger) onChangeTrigger();
            }}
          />
        );
      }}
    </Field>
  );
};

export default FormikMultiSelect;
