import { TypeMerchant } from 'src/business/app/types/TypeMerchant';

const getBooleanValue = (value: boolean) => value;

export const transformFormData = (values: TypeMerchant): TypeMerchant => {
  // eslint-disable-next-line no-console

  const Data = {
    Id: values.Id || 0,
    LegalName: values.LegalName,
    SystemName: values.SystemName,
    Description: values.Description,
    Website: values.Website,
    RevenueShare: values.RevenueShare,
    TermsAndConditions: values.TermsAndConditions,
    LogoUrl: values.LogoUrl,
    CardImageUrl: values.CardImageUrl,
    CardholderAgreement: values.CardholderAgreement,
    IsPhysical: getBooleanValue(!!values.IsPhysical),
    IsOnline: getBooleanValue(!!values.IsOnline),
    IsRegional: getBooleanValue(!!values.IsRegional),
    AcceptsTips: getBooleanValue(!!values.AcceptsTips),
    IsPayLater: getBooleanValue(!!values.IsPayLater),
    IsVariablePurchase: getBooleanValue(!!values.IsVariablePurchase),
    IsACHEnabled: getBooleanValue(!!values.IsACHEnabled),
    HasInventory: false,
    IsActive: getBooleanValue(!!values.IsActive),
    MinimumCardPurchase: values.MinimumCardPurchase,
    MaximumCardPurchase: values.MaximumCardPurchase,
    SavingsPercentage: values.SavingsPercentage,
    GiftCardGatewayId: values.GiftCardGatewayId,
    PaymentInstructions: '',
    DeepLink: '',
    Images: null,
    product: null,
    categories: values.Categories,
    tags: values.Tags,
  };
  return Data;
};

export const editTransformFormData = (values: TypeMerchant): TypeMerchant => {
  const Data = {
    Id: values.Id,
    LegalName: values.LegalName,
    SystemName: values.SystemName,
    Description: values.Description,
    Website: values.Website,
    RevenueShare: values.RevenueShare,
    MinimumCardPurchase: values.MinimumCardPurchase,
    MaximumCardPurchase: values.MaximumCardPurchase,
    GiftCardGatewayId: values.GiftCardGatewayId,
    TermsAndConditions: values.TermsAndConditions,
    LogoUrl: values.LogoUrl,
    CardImageUrl: values.CardImageUrl,
    CardholderAgreement: values.CardholderAgreement,
    PurchaseFee: 0,
    MarketingFee: 0,
    MinimumDiscount: 0,
    Level1: 0,
    Level2: 0,
    Level3: 0,
    Level4: 0,
    Level5: 0,
    Level6: 0,
    Level7: 0,
    IsDeleted: false,
    IsPhysical: getBooleanValue(!!values.IsPhysical),
    IsOnline: getBooleanValue(!!values.IsOnline),
    CreatedDate: new Date(),
    CreatedBy: 0,
    ModifiedDate: new Date(),
    ModifiedBy: 0,
    IsRegional: getBooleanValue(!!values.IsRegional),
    AcceptsTips: getBooleanValue(!!values.AcceptsTips),
    UsageInstructions: '',
    UsageInstructionsBak: '',
    PaymentGatewayId: 1,
    IsHtmlDescription: false,
    PurchaseInstructions: '',
    BalanceInstructions: '',
    AmountPerCard: 0,
    ProcessingMessage: '',
    HasBarcode: false,
    IsVoidable: false,
    ReceiptMessage: '',
    CssBorderCode: '',
    AlderSku: '',
    NgcSku: '',
    AcceptedCurrency: '',
    IsPayLater: getBooleanValue(!!values.IsPayLater),
    IsVariablePurchase: getBooleanValue(!!values.IsVariablePurchase),
    IsACHEnabled: getBooleanValue(!!values.IsACHEnabled),
    HasInventory: false,
    IsActive: getBooleanValue(!!values.IsActive),
    SavingsPercentage: values.SavingsPercentage,
    PaymentInstructions: '',
    DeepLink: '',
    categories: values.Categories,
    tags: values.Tags,
  };
  return Data;
};

export const getInitialValues = (
  currentMerchant: any,
  mechantLocationData: any,
  type: string
) => {
  const MerchantCategories = currentMerchant?.MerchantCategories || [];
  const MerchantTags = currentMerchant?.MerchantTags || [];
  return {
    Id: currentMerchant?.Id ?? 0,
    LegalName: currentMerchant?.LegalName ?? '',
    SystemName: currentMerchant?.SystemName ?? '',
    GiftCardGatewayId: currentMerchant?.GiftCardGatewayId ?? '',
    Description: currentMerchant?.Description ?? '',
    Website: currentMerchant?.Website ?? '',
    TermsAndConditions: currentMerchant?.TermsAndConditions ?? '',
    LogoUrl: currentMerchant?.LogoUrl ?? '',
    CardImageUrl: currentMerchant?.CardImageUrl ?? '',
    CardholderAgreement: currentMerchant?.CardholderAgreement ?? '',
    IsRegional: currentMerchant?.IsRegional,
    HasInventory: currentMerchant?.HasInventory,
    IsActive: currentMerchant?.IsActive,
    IsPhysical: currentMerchant?.IsPhysical,
    IsOnline: currentMerchant?.IsOnline,
    IsVariablePurchase: currentMerchant?.IsVariablePurchase,
    MinimumCardPurchase: currentMerchant?.MinimumCardPurchase ?? '',
    MaximumCardPurchase: currentMerchant?.MaximumCardPurchase ?? '',
    AcceptsTips: currentMerchant?.AcceptsTips,
    IsPayLater: currentMerchant?.IsPayLater,
    IsACHEnabled: !!currentMerchant?.IsACHEnabled,
    SavingsPercentage: currentMerchant?.SavingsPercentage ?? '',
    RevenueShare: currentMerchant?.RevenueShare ?? '',
    Categories: MerchantCategories.length
      ? MerchantCategories.map((c: any) => c.CategoryId)
      : null,
    Tags: MerchantTags.length ? MerchantTags.map((c: any) => c.TagId) : null,
    currentLocationData: mechantLocationData,
    currentType: type,
  };
};
