import * as walletActions from 'src/store/actions/wallet';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    apiServer: useReducerData('wallet', 'apiServer', 'prod'),
    balances: useReducerData('wallet', 'wallet.data.balances', []),
    connectedStatus: useReducerData(
      'wallet',
      'wallet.data.wallet_bot.status',
      ''
    ),
    invoices: useReducerData('wallet', 'invoices.data', []),
    invoicesLoading: useReducerData('wallet', 'invoices.loading', false),
    invoicesCount: useReducerData('wallet', 'wallet.data.counts', {}),
    balancesLoading: useReducerData('wallet', 'wallet.loading', false),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...walletActions,
  });
};
