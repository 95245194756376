import { useMantineTheme } from '@mantine/core';
import { Dropzone, DropzoneProps, MIME_TYPES } from '@mantine/dropzone';
import {
  IconFileText,
  IconFileUpload,
  IconPhoto,
  IconTrashX,
  IconX,
} from '@tabler/icons';
import { useState } from 'react';
import { Group, Stack, Text } from 'src/components';

export function Dropzones(props: Partial<DropzoneProps>) {
  type fileProps = {
    fileName: string;
    fileSize: number;
  };
  // function humanFileSize(size: number) {
  //   var i = Math.floor(Math.log(size as number) / Math.log(1024));
  //   return (
  //     ((size as number) / Math.pow(1024, i)).toFixed(2) * 1 +
  //     " " +
  //     ["B", "kB", "MB", "GB", "TB"][i]
  //   );
  // }
  const theme = useMantineTheme();
  const [hasFile, setHasFile] = useState(false);
  const [files, setFiles] = useState<fileProps>({ fileName: '', fileSize: 0 });
  // const [uploadedFile, setuploadedFiled] = useState<any>();
  // const handleSubmit = (files: any) => {
  //   setHasFile(true);
  // };
  const handleRemoveFiles = () => {
    setHasFile(false);
    setFiles({ fileName: '', fileSize: 0 });
  };
  return (
    <>
      <Dropzone
        hidden={hasFile}
        onDrop={(files) => {
          //const formData = new FormData();
          setFiles({ fileName: files[0].name, fileSize: files[0].size });
          setHasFile(true);
        }}
        onReject={() => {
          // TODO: files at param
        }}
        maxSize={3 * 1024 ** 2}
        accept={[MIME_TYPES.csv]}
        {...props}
      >
        <Stack
          spacing="lg"
          align={'center'}
          style={{ minHeight: '8rem', pointerEvents: 'none' }}
        >
          <Dropzone.Accept>
            <IconPhoto size={50} stroke={1.5} />
            <Text size="xl" inline>
              Drop here
            </Text>
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX
              size={50}
              stroke={1.5}
              color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconFileUpload size={50} stroke={1.5} color="#00abfb" />
          </Dropzone.Idle>

          <div>
            <Text size="xl" inline>
              Click to upload or Drag & Drop
            </Text>
          </div>
        </Stack>
      </Dropzone>
      {hasFile && (
        <Group
          position="apart"
          spacing={5}
          style={{ border: '2px dashed #ced4da', maxWidth: '25rem' }}
        >
          <Group
            ml={15}
            spacing="sm"
            align={'center'}
            style={{ minHeight: '2rem', pointerEvents: 'none' }}
          >
            <IconFileText size={24} strokeWidth={2} color={'#00abfb'} />
            <Stack style={{ gap: 0 }}>
              <Text size={'sm'}>{files.fileName}</Text>
              <Text size={'sm'}>
                {
                  ((files.fileSize as number) / 1024).toFixed(2) + 'KBs'
                  // files.fileSize > 0 && files.fileSize.toFixed(2).toString()
                }
              </Text>
            </Stack>
          </Group>
          <IconTrashX
            size={24}
            strokeWidth={2}
            color={'#bf4040'}
            onClick={handleRemoveFiles}
          />
        </Group>
      )}
    </>
  );
}
