import { Paper as ManPaper, PaperProps } from '@mantine/core';
import cn from 'clsx';
import classes from './Paper.module.scss';

type IProps = PaperProps & {
  className?: string;
};

const Paper = ({ className, ...other }: IProps) => {
  return <ManPaper {...other} className={cn(classes.wrapper, className)} />;
};
export default Paper;
