import { Dispatch } from 'redux';
import { apiGetBalances, apiGetInvoices, apiGetWallet } from 'src/api';
import Types from '../types/wallet';

export const syncUpdateWalletApiPrefix =
  (apiServer: string) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: false,
      type: Types.SYNC_UPDATE_API_PREFIX,
      payload: { apiServer },
    });
  };

export const getWallet = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_WALLET,
    asyncCall: () => {
      return apiGetWallet();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getBalances = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_BALANCES,
    asyncCall: () => {
      return apiGetBalances();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
interface InvoiceFilter {
  status: string;
}
export const getInvoices =
  (filters: InvoiceFilter, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_INVOICES,
      asyncCall: () => {
        return apiGetInvoices(filters);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
