import { Text as ManText, TextProps } from '@mantine/core';
import cn from 'clsx';
import classes from './Text.module.scss';

type IProps = TextProps & {
  className?: string;
};

const Text = ({ className, ...other }: IProps) => {
  return (
    <ManText
      {...other}
      className={cn(
        classes.wrapper,
        {
          [classes.colorDefault]: other.color === 'default',
        },
        className
      )}
    />
  );
};
export default Text;
