import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { AnyAction } from 'redux';
import { defaultArray, defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/client';

const initialState = {
  clients: {
    ...defaultArray,
  },
  clientDetail: {
    ...defaultObj,
  },
  clientMerchants: {
    ...defaultObj,
  },
  clientAvailableMerchants: {
    ...defaultObj,
  },
  updateClientMerchants: {
    ...defaultObj,
  },
  addClientMerchants: {
    ...defaultObj,
  },
};

const ClientReducer = (
  state = initialState,
  action: AnyAction
): typeof initialState => {
  const { type, payload } = action;

  switch (type) {
    // SYNC
    case Types.SYNC_UPDATE_CLIENT_MERCHANTS: {
      return {
        ...state,
        clientMerchants: {
          ...state.clientMerchants,
          data: {
            ...state.clientMerchants.data,
            ClientMerchants: {
              ...get(state, 'clientMerchants.data.ClientMerchants'),
              Items: (
                get(state, 'clientMerchants.data.ClientMerchants.Items') || []
              ).map((merchant) => {
                const foundMerchant =
                  find(action.payload, { Id: merchant.Id }) || {};
                if (!isEmpty(foundMerchant)) {
                  return { ...foundMerchant };
                }
                return merchant;
              }),
            },
          },
        },
      };
    }
    // GET
    case Types.GET_CLIENTS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          clients: {
            ...prevState.clients,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          clients: {
            data: payload?.Data || [],
            loading: false,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          clients: {
            data: [],
            error: payload,
            loading: false,
          },
        }),
      });
    case Types.GET_CLIENT_DETAIL:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          clientDetail: {
            ...prevState.clientDetail,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          clientDetail: {
            data: payload?.Data || {},
            loading: false,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          clientDetail: {
            data: {},
            error: payload,
            loading: false,
          },
        }),
      });
    case Types.GET_CLIENT_MERCHANTS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          clientMerchants: {
            ...prevState.clientMerchants,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          clientMerchants: {
            data: payload?.Data || {},
            loading: false,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          clientMerchants: {
            data: {},
            error: payload,
            loading: false,
          },
        }),
      });
    case Types.GET_CLIENT_AVAILABLE_MERCHANTS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          clientAvaialbleMerchants: {
            ...prevState.clientAvaialbleMerchants,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          clientAvaialbleMerchants: {
            data: payload?.Data || {},
            loading: false,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          clientAvaialbleMerchants: {
            data: {},
            error: payload,
            loading: false,
          },
        }),
      });
    case Types.UPDATE_CLIENT_MERCHANTS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          updateClientMerchants: {
            ...prevState.updateClientMerchants,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          updateClientMerchants: {
            data: payload?.Data || {},
            loading: false,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          updateClientMerchants: {
            data: {},
            error: payload,
            loading: false,
          },
        }),
      });
    case Types.ADD_CLIENT_MERCHANTS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          addClientMerchants: {
            ...prevState.addClientMerchants,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          addClientMerchants: {
            data: payload?.Data || {},
            loading: false,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          addClientMerchants: {
            data: {},
            error: payload,
            loading: false,
          },
        }),
      });
    default:
      return state;
  }
};

export default ClientReducer;
