import { NavLink, useMantineTheme } from '@mantine/core';
import cn from 'clsx';
import get from 'lodash/get';
import map from 'lodash/map';
import { ReactNode } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, ThemeIcon } from 'src/components';
import { MenuItem } from 'src/components/Navbar';
import { useCurrentPath } from 'src/helper/hooks';
import classes from './NavbarItem.module.scss';

interface NavbarItemProps {
  text: string;
  icon: ReactNode;
  index: number;
  itemIndex: number;
  tooltip: string;
  menuIsOpened: boolean;
  openedChange?: (opened: boolean) => void;
  urlLink: string;
  onClick: () => void;
  onCloseNavbar: () => void;
  subMenus?: MenuItem[];
  activeSubUrls?: string[];
}

const getSubIsActive = (
  url: string,
  merchantId: string | undefined,
  location: any
) => {
  const realUrl = url.includes(':id')
    ? url.replace(':id', merchantId || '')
    : url;
  return realUrl === location.pathname;
};

export const NavbarItem = ({
  menuIsOpened,
  text,
  icon,
  tooltip,
  urlLink,
  onClick,
  onCloseNavbar,
  subMenus,
  openedChange,
  activeSubUrls,
}: NavbarItemProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id: merchantId } = useParams();
  const currentPath = useCurrentPath();
  const theme = useMantineTheme();
  const active =
    location.pathname === urlLink ||
    (activeSubUrls || []).includes(currentPath);
  const thisIsSubLink =
    (subMenus || []).filter((s) => s.urlLink === location.pathname).length > 0;
  return (
    <Box sx={{ width: '100%' }} className={classes.navbarItem}>
      {!subMenus && (
        <NavLink
          sx={{
            color: theme.colors.primaryBlack[0],
            '&[data-active]': {
              color: theme.colors.primaryBlack[0],
              fontWeight: 'bold',
            },
          }}
          label={text}
          icon={
            <ThemeIcon variant={active ? 'filled' : 'light'} size={26} p={4}>
              {icon}
            </ThemeIcon>
          }
          title={tooltip}
          onClick={onClick}
          active={active}
        />
      )}
      {subMenus && (
        <NavLink
          sx={{ color: theme.colors.primaryBlack[0] }}
          label={text}
          icon={
            <ThemeIcon variant={active ? 'filled' : 'light'} size={30} p={4}>
              {icon}
            </ThemeIcon>
          }
          childrenOffset={subMenus ? 32 : 0}
          title={tooltip}
          rightSection={undefined}
          opened={menuIsOpened}
          className={cn(classes.navButtonHasSub, {
            [classes.bold]: thisIsSubLink,
          })}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            const tagName = get(e, 'target.tagName') as string;
            if (!['path', 'svg'].includes(tagName)) {
              if (!menuIsOpened) {
                navigate(urlLink);
                if (openedChange) openedChange(!menuIsOpened);
              } else {
                if (location.pathname !== urlLink) {
                  navigate(urlLink);
                } else {
                  if (openedChange) openedChange(!menuIsOpened);
                }
              }
            } else {
              if (openedChange) openedChange(!menuIsOpened);
            }
          }}
        >
          {map(subMenus, (menu, i) => {
            let menuActive = location.pathname === menu.urlLink;
            (menu.activeUrls || []).map((url: string) => {
              if (getSubIsActive(url, merchantId, location)) {
                menuActive = true;
              }
            });
            return (
              <NavLink
                key={i}
                sx={{
                  borderLeft: `1px solid ${theme.colors.primaryBlue[2]}`,
                  color: theme.colors.secondaryBlack[0],
                  '&[data-active]': {
                    color: theme.colors.secondaryBlack[0],
                  },
                }}
                label={menu.text}
                icon={menu.icon}
                title={menu.tooltip}
                onClick={() => {
                  navigate(menu.urlLink);
                  onCloseNavbar();
                }}
                active={menuActive}
              />
            );
          })}
        </NavLink>
      )}
    </Box>
  );
};
