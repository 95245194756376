import { createTypes } from './createTypes';

export default createTypes(
  'GET_ALL_MERCHANTS',
  'GET_NEW_MERCHANTS',
  'GET_MERCHANT',
  'GET_MERCHANT_CATEGORIES',
  'GET_MERCHANT_TAGS',
  'GET_UNCONFIRM_MERCHANTS',
  'ADD_MERCHANT',
  'UPDATE_MERCHANT',
  'CONFIRM_MERCHANTS',
  // CUSTOM
  'SYNC_UPDATE_MERCHANT'
);
