import { LoaderProps, Loader as ManLoader } from '@mantine/core';
import cn from 'clsx';
import classes from './Loader.module.scss';

type IProps = LoaderProps & {
  className?: string;
};

const Loader = ({ className, ...other }: IProps) => {
  return <ManLoader {...other} className={cn(classes.wrapper, className)} />;
};
export default Loader;
