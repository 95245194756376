import { useEffect, useState } from 'react';
import { Button, Modal, Stack } from 'src/components';
import { TextInput } from 'src/components/FormFields';
import classes from './PasswordModal.module.scss';
interface Props {
  isOpened: boolean;
  onClose: () => void;
  onSubmit: (val: string) => void;
}

const PasswordModal = (props: Props) => {
  const { isOpened, onClose, onSubmit } = props;
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (isOpened) {
      setPassword('');
    }
  }, [isOpened]);

  return (
    <Modal
      centered
      withCloseButton={true}
      size={'lg'}
      opened={isOpened}
      onClose={onClose}
      closeOnClickOutside={false}
      closeOnEscape={false}
      title="Please Enter Your Password"
      className={classes.modal}
    >
      <Stack>
        <div className={classes.inner}>
          <TextInput
            placeholder="Password"
            type="password"
            label="Password"
            className={classes.password}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSubmit(password);
              }
            }}
          />
          <Button
            size="sm"
            onClick={() => {
              if (password) {
                onSubmit(password);
              }
            }}
          >
            Confirm
          </Button>
        </div>
      </Stack>
    </Modal>
  );
};

export default PasswordModal;
