import { CheckboxProps, Checkbox as ManCheckbox } from '@mantine/core';
import cn from 'clsx';
import React from 'react';
import classes from './Checkbox.module.scss';

type IProps = CheckboxProps & {
  className?: string;
};

const Checkbox = ({ className = '', ...other }: IProps) => {
  return <ManCheckbox {...other} className={cn(classes.wrapper, className)} />;
};

export default Checkbox;
