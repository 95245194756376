import * as clientActions from 'src/store/actions/client';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  const clientMerchantsStore = useReducerData('client', 'clientMerchants', {});
  const updateClientMerchantsStore = useReducerData(
    'client',
    'updateClientMerchants',
    {}
  );
  return {
    clientData: clientMerchantsStore.data || {},
    clientDetailLoading: clientMerchantsStore.loading || false,
    updateClientMerchantsLoading: updateClientMerchantsStore.loading || false,
  };
};

export const useActions = () => {
  return useStoreActions({
    ...clientActions,
  });
};
