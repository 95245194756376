import { Menu as ManMenu, MenuProps } from '@mantine/core';
// import cn from "clsx";
import React from 'react';
// import classes from "./Menu.module.scss";

type IProps = MenuProps;

const MenuTarget = ManMenu.Target;
const MenuDropdown = ManMenu.Dropdown;
const MenuItem = ManMenu.Item;

const Menu = (props: IProps) => {
  return <ManMenu {...props} />;
};

export { MenuTarget, MenuDropdown, MenuItem };
export default Menu;
