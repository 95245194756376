import { Field } from 'formik';
// import get from "lodash/get";
import NumberInput, { INumberInputProps } from '../NumberInput';

type Props = INumberInputProps & {
  name: string;
};

const FormikNumberInput = (props: Props) => {
  const { name, ...otherProps } = props;

  return (
    <Field name={name}>
      {(fObj: any) => {
        const {
          field, // { name, value, onChange, onBlur }
          form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        } = fObj;
        const { setFieldValue } = form;
        const value = field.value;
        // const errorMessage = get(errors, field.name);
        return (
          <NumberInput
            {...otherProps}
            {...field}
            value={value}
            onChange={(val) => {
              setFieldValue(name, val);
            }}
          />
        );
      }}
    </Field>
  );
};

export default FormikNumberInput;
