import cn from 'clsx';
import { Paper } from 'src/components';
import classes from './PageCard.module.scss';

interface IProps {
  children: React.ReactNode;
  className?: string;
  fixedFooter?: boolean;
}

const PageCard = ({ children, fixedFooter, className }: IProps) => {
  return (
    <Paper
      radius={12}
      sx={{ minHeight: '40rem', display: 'flex', flexDirection: 'column' }}
      className={cn(
        classes.wrapper,
        {
          [classes.hasFixedFooter]: fixedFooter,
        },
        className
      )}
    >
      {children}
    </Paper>
  );
};
export default PageCard;
