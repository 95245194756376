import { ButtonProps, Button as ManButton } from '@mantine/core';
import cn from 'clsx';
import React from 'react';
import classes from './Button.module.scss';

export type IButtonProps = ButtonProps & {
  className?: string;
  onClick?: () => void | undefined;
  isLink?: boolean;
};

const Button = ({ className = '', isLink, ...other }: IButtonProps) => {
  return (
    <ManButton
      {...other}
      className={cn(
        classes.wrapper,
        {
          [classes.isLink]: isLink,
        },
        className
      )}
    />
  );
};

export default Button;
