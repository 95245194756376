import { IconSearch } from '@tabler/icons';
import { Fragment, ReactNode, useContext } from 'react';
import { Filter } from 'tabler-icons-react';
import { Group, Stack, Title } from 'src/components';
import { Input } from 'src/components/FormFields';
import { TableContext } from '../context';
import classes from './TableToolbar.module.scss';

export interface TableToolbarProps {
  title: string;
  hasSearchbox?: boolean;
  toolbarContentRight?: ReactNode;
  searchboxPlaceholder?: string;
  search?: string;
  setSearch?: (val: string) => void;
  clientSearch?: boolean;
  displayTotal?: boolean;
}
interface TotalToolbarProps {
  totalRecords: number;
  page: number;
  pageSize: number;
  manualPagination?: boolean;
}
type ToolbarProps = TotalToolbarProps & TableToolbarProps;

export const TableTotal = ({
  page,
  totalRecords,
  pageSize,
  manualPagination,
}: TotalToolbarProps) => {
  return (
    <span className={classes.tableTotal}>
      (
      <b>
        {!manualPagination ? 1 : pageSize * (page - 1) + 1}-
        {!manualPagination ? totalRecords : page * pageSize}
      </b>{' '}
      <span>of</span> {totalRecords})
    </span>
  );
};

export const TableToolbar = (props: ToolbarProps) => {
  const {
    title,
    hasSearchbox,
    toolbarContentRight,
    searchboxPlaceholder,
    search,
    setSearch,
    clientSearch,
    displayTotal,
    totalRecords,
    page,
    pageSize,
    manualPagination,
  } = props;
  const { theme, value, setValue } = useContext(TableContext);
  return (
    <Stack>
      <Group position="apart">
        <Group>
          <Title size={32}>{title}</Title>
        </Group>
        <Group spacing={12}>
          {displayTotal && (
            <TableTotal
              totalRecords={totalRecords}
              page={page}
              pageSize={pageSize}
              manualPagination={manualPagination}
            />
          )}
          {toolbarContentRight}
          {hasSearchbox && (
            <Fragment>
              <Input
                value={(clientSearch ? value : search) || ''}
                icon={<IconSearch color={theme.colors.primaryBlack[0]} />}
                placeholder={searchboxPlaceholder ?? 'Search'}
                onChange={(event: any) => {
                  if (!clientSearch) {
                    if (setSearch) setSearch(event.currentTarget.value);
                  } else {
                    setValue(event.currentTarget.value);
                  }
                }}
              />
              <Filter color={theme.colors.primaryBlue[3]} />
            </Fragment>
          )}
        </Group>
      </Group>
    </Stack>
  );
};
