import { faker } from '@faker-js/faker';
import { tempUser } from 'src/business/app/types/types';
// import { faker } from '@faker-js/faker/locale/de';

export const USERS: tempUser[] = [];

export function createRandomUser(index: number): tempUser {
  return {
    userId: index + 1,
    firstName: faker.internet.userName(),
    lastName: faker.internet.userName(),
    email: faker.internet.email(),
    phone: faker.phone.number(),
    status: faker.helpers.shuffle<tempUser['status']>([
      'Verified',
      'Active',
      'Inactive',
      'Inprocess',
    ])[0]!,
    appName: faker.animal.cat(),
  };
}

Array.from({ length: 10 }).forEach((_, i) => {
  USERS.push(createRandomUser(i));
});
