import { IconCirclePlus } from '@tabler/icons';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { TypeMerchant } from 'src/business/app/types/TypeMerchant';
import { Button, Stack, Text } from 'src/components';

export const getColumnHeader = (
  onAddLocation: (merchantId: string) => void
) => {
  const columnHelper = createColumnHelper<TypeMerchant>();
  const defaultColumns: ColumnDef<TypeMerchant, any>[] = [
    columnHelper.accessor((row) => row.LegalName, {
      id: 'LegalName',
      minSize: 20,
      maxSize: 40,
      cell: (props) => (
        <Stack align={'start'}>
          <Text>{props.getValue()}</Text>
        </Stack>
      ),
      header: () => <span>Merchant</span>,
    }),

    // columnHelper.accessor((row) => row.IsActive, {
    //   id: "IsActive",
    //   minSize: 20,
    //   maxSize: 40,
    //   cell: (props) => (
    //     <Stack align={"start"}>
    //       <Text>{props.getValue()}</Text>
    //     </Stack>
    //   ),
    //   header: () => <span>Status</span>,
    // }),
    columnHelper.accessor((row) => row.IsActive, {
      id: 'status',
      minSize: 20,
      maxSize: 40,
      cell: (props) => (
        <Stack align={'start'}>
          <Text>{props.getValue()}</Text>
          {/* <Badge
            size="lg"
            radius="sm"
            variant="filled"
            style={{
              fontSize: 14,
              fontWeight: "normal",
              backgroundColor: BadgeStatus[props.getValue()].color,
            }}
          >
            {BadgeStatus[props.getValue()].text}
          </Badge> */}
        </Stack>
      ),
      header: () => <span>Status</span>,
    }),
    columnHelper.accessor((row) => row.Level7, {
      id: 'noOfLocations',
      minSize: 20,
      maxSize: 40,
      cell: (props) => (
        <Stack align={'start'}>
          {props.getValue() > 5 && <Text>{Math.round(props.getValue())}</Text>}
          {(!props.getValue() || props.getValue() < 5) && (
            <Button
              leftIcon={<IconCirclePlus />}
              variant="light"
              onClick={() => onAddLocation(props.row.id)}
            >
              Add location
            </Button>
          )}
        </Stack>
      ),
      header: () => <span>No Of Locations</span>,
    }),
    columnHelper.accessor((row) => row.AcceptedCurrency, {
      id: 'AcceptedCurrency',
      minSize: 20,
      maxSize: 40,
      cell: (props) => (
        <Stack align={'start'}>
          <Text weight={'bold'} size={16}>
            {props.getValue()}
          </Text>
        </Stack>
      ),
      header: () => <span>Action</span>,
    }),
  ];
  return defaultColumns;
};
