import { Tabs as ManTabs, TabsProps } from '@mantine/core';
import cn from 'clsx';
import classes from './Tabs.module.scss';

type IProps = TabsProps & {
  className?: string;
};

const TabList = ManTabs.List;
const TabPanel = ManTabs.Panel;
const Tab = ManTabs.Tab;

const Tabs = ({ className, ...other }: IProps) => {
  return <ManTabs {...other} className={cn(classes.wrapper, className)} />;
};

export { TabList, TabPanel, Tab };
export default Tabs;
