import { Button, Popover, Text } from '@mantine/core';
import { IconCopy } from '@tabler/icons';
import { useState } from 'react';
import classes from './CopyIcon.module.scss';

interface Props {
  text: string;
}

const CopyIcon = ({ text }: Props) => {
  const [copyOpen, setCopyOpen] = useState(false);
  return (
    <Popover width={200} position="top" withArrow shadow="md" opened={copyOpen}>
      <Popover.Target>
        <Button
          className={classes.button}
          onClick={() => {
            setCopyOpen(true);
            navigator.clipboard.writeText(text);
            setTimeout(() => {
              setCopyOpen(false);
            }, 1000);
          }}
        >
          <IconCopy size={12} />
        </Button>
      </Popover.Target>
      <Popover.Dropdown className={classes.popoverContent}>
        <Text size="sm">Copied</Text>
      </Popover.Dropdown>
    </Popover>
  );
};

export default CopyIcon;
