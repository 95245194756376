import { Table } from '@mantine/core';
import { IconArrowUp, IconSearch } from '@tabler/icons';
import { rankItem } from '@tanstack/match-sorter-utils';
import {
  FilterFn,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useState } from 'react';
import { Box, Button, Group, Pagination, Stack, Title } from 'src/components';
import { Input, MultiSelect } from 'src/components/FormFields';
import { USERS } from '../../temp/mockData';
import { getColumnHeader } from './tableHeader';
export const Home = () => {
  const [sorting, setSorting] = useState<SortingState>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activePage, setActivePage] = useState(0);

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
      itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  const data = USERS;
  const table = useReactTable({
    data: data,
    columns: getColumnHeader(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    globalFilterFn: fuzzyFilter,
    enableMultiRowSelection: true,
  });

  return (
    <Stack>
      <Stack>
        <Title order={4}>Users</Title>
        <Group position="apart">
          <Box>
            <MultiSelect
              data={['All', '10', '20', '30']}
              // label="Your favorite frameworks/libraries"
              placeholder="Filer"
              searchable
              nothingFound="Nothing found"
            />
          </Box>
          <Group>
            <Input
              icon={<IconSearch />}
              placeholder="Search by Name, Phone or email"
            />
            <Button>Search</Button>
          </Group>
        </Group>
      </Stack>

      <Stack>
        <Stack sx={{ minHeight: 200 }}>
          <Table striped highlightOnHover>
            <thead>
              <tr>
                {table.getFlatHeaders().map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                        style={{ display: 'flex' }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {!!header.column.getIsSorted() && (
                          <IconArrowUp
                            width={16}
                            height={16}
                            style={{
                              transform:
                                header.column.getIsSorted() === 'desc'
                                  ? 'rotate(180deg)'
                                  : 'rotate(0deg)',
                              transition: 'transform 0.3s ease-in-out',
                            }}
                          />
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Stack>

        {data ? (
          <Pagination
            color={'#0A4ABF'}
            style={{ paddingTop: 10 }}
            styles={(theme) => ({
              item: {
                '&[data-active]': {
                  backgroundColor: theme.colors.blue[3],
                },
              },
            })}
            align={'end'}
            position={'right'}
            page={activePage}
            total={data.length}
            withEdges
            withControls
          />
        ) : null}
      </Stack>
    </Stack>
  );
};
