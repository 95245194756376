import { MIME_TYPES } from '@mantine/dropzone';
import React, { useState } from 'react';
import { Dropzone, Grid, GridCol, Stack, Text } from 'src/components';
import {
  FormikMultiSelect,
  FormikRadioGroup,
  Radio,
} from 'src/components/FormFields';
import { useOptions } from 'src/helper/hooks';
import { actionCb } from 'src/utils/action';
import classes from './AddEditMerchants.module.scss';
import LocationCard from './LocationCard';
import { useActions, useCategoryTagData } from './selectorData';

interface Props {
  isPOError: boolean;
  isPhysical: boolean;
  formProps: any;
  onPhysicalChange: (val: string) => void;
  locationFiles?: any[];
  onLocationRemove: (index: number) => void;
  merChantId: string | number;
  formChangeTrigger?: () => void;
}

const CategoryTag = ({
  isPOError,
  formProps,
  isPhysical,
  onPhysicalChange,
  locationFiles,
  onLocationRemove,
  formChangeTrigger,
}: Props) => {
  const { values } = formProps;
  // TODO: hide Location for now
  const hideLocation = false;
  const { verifyLocationLoading, verifyLocationData, mechantLocationLoading } =
    useCategoryTagData();
  const hasUploadedLocation = !!locationFiles?.length;
  const existingLocationItems = values.currentLocationData?.Items || [];
  const hasExistingLocation = !!existingLocationItems.length;
  const displayLocationFile =
    (hasUploadedLocation || hasExistingLocation) && !hideLocation;
  const fileDisplaying =
    (hasExistingLocation
      ? [{ name: 'Location.csv', size: 10000, isExisting: true }]
      : locationFiles) || [];
  const { verifyLocationCSV, syncResetMerchantLocations } = useActions();
  const [csvView, setCsvView] = useState([] as boolean[]);
  const { categoryOptions, tagOptions } = useOptions();
  const updateCsvView = (index: number, val: boolean, isRemove?: boolean) => {
    const newCsvView = [...csvView];
    if (isRemove) {
      setCsvView(newCsvView.filter((v, i) => i !== index));
    } else {
      newCsvView[index] = val;
      setCsvView(newCsvView);
    }
  };

  return (
    <>
      <Text size={16} weight={500} underline color="default">
        Assign Categories & Tags
      </Text>
      {isPOError && (
        <span className={classes.poErrorMsg}>
          A merchant must be either Physical or Online, or both.
        </span>
      )}
      <Grid>
        <GridCol span={3}>
          <FormikRadioGroup
            name="IsPhysical"
            label="Merchant is Physical"
            onChange={onPhysicalChange}
            isYesNo
          >
            <Radio value="yes" label="Yes" />
            <Radio value="no" label="No" />
          </FormikRadioGroup>
        </GridCol>
        <GridCol span={3}>
          <FormikRadioGroup name="IsOnline" label="Merchant is Online" isYesNo>
            <Radio value="yes" label="Yes" />
            <Radio value="no" label="No" />
          </FormikRadioGroup>
        </GridCol>
        <GridCol span={3}>
          <FormikRadioGroup
            name="AcceptsTips"
            label="Merchant Accepts Tips"
            isYesNo
          >
            <Radio value="yes" label="Yes" />
            <Radio value="no" label="No" />
          </FormikRadioGroup>
        </GridCol>
        <GridCol span={3}>
          <FormikRadioGroup
            name="IsVariablePurchase"
            label="Merchant is Variable Purchase"
            isYesNo
          >
            <Radio value="yes" label="Yes" />
            <Radio value="no" label="No" />
          </FormikRadioGroup>
        </GridCol>
        <GridCol span={3}>
          <FormikRadioGroup
            name="IsPayLater"
            label="Merchant is Pay Later"
            isYesNo
          >
            <Radio value="yes" label="Yes" />
            <Radio value="no" label="No" />
          </FormikRadioGroup>
        </GridCol>
        <GridCol span={3}>
          <FormikRadioGroup
            name="IsACHEnabled"
            label="Merchant Accepts ACH"
            isYesNo
          >
            <Radio value="yes" label="Yes" />
            <Radio value="no" label="No" />
          </FormikRadioGroup>
        </GridCol>
      </Grid>
      <Grid>
        {/* <GridCol span={4}>
          <FormikMultiSelect
            data={categoryOptions}
            label="Categories"
            placeholder="Categories"
            required
            name="Categories"
            dropdownPosition="top"
            onChangeTrigger={formChangeTrigger}
          />
        </GridCol> */}
        <GridCol span={4}>
          <FormikMultiSelect
            data={tagOptions}
            label="Tags"
            placeholder="Tags"
            required
            name="Tags"
            dropdownPosition="top"
            onChangeTrigger={formChangeTrigger}
          />
        </GridCol>
      </Grid>
      {!!isPhysical && (
        <Grid>
          <GridCol span={12}>
            {!displayLocationFile && (
              <Dropzone
                loading={verifyLocationLoading || mechantLocationLoading}
                multiple={false}
                label="Assign Location(s)"
                required
                onDrop={(f) => {
                  if (f[0] && !hideLocation) {
                    if (formChangeTrigger) formChangeTrigger();
                    const fData = new FormData();
                    fData.append('File', f[0]);
                    verifyLocationCSV(
                      fData,
                      actionCb(() => {
                        formProps.setFieldValue(
                          'LocationFiles',
                          f?.length ? f : null
                        );
                      })
                    );
                  }
                }}
                maxSize={3 * 1024 ** 2}
                accept={[MIME_TYPES.csv]}
              />
            )}
            {displayLocationFile && (
              <Stack style={{ gap: 24 }} className={classes.fileLists}>
                {fileDisplaying.map((f: any, i: number) => {
                  const firstCSVObj = f.isExisting
                    ? existingLocationItems[0] || {}
                    : verifyLocationData[0] || {};
                  const csvViewHeader = Object.keys(firstCSVObj);
                  const csvViewContent = f.isExisting
                    ? existingLocationItems
                    : verifyLocationData;
                  return (
                    <LocationCard
                      csvView={csvView}
                      updateCsvView={updateCsvView}
                      fileObj={f}
                      index={i}
                      onView={() => {
                        updateCsvView(i, !csvView[i]);
                      }}
                      onRemove={() => {
                        syncResetMerchantLocations();
                        onLocationRemove(i);
                        updateCsvView(i, false, true);
                        if (formChangeTrigger) formChangeTrigger();
                      }}
                      csvViewHeader={csvViewHeader}
                      csvViewContent={csvViewContent}
                      key={i}
                    />
                  );
                })}
              </Stack>
            )}
          </GridCol>
        </Grid>
      )}
    </>
  );
};

export default CategoryTag;
