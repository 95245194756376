import { Field } from 'formik';
// import get from "lodash/get";
import { IGroupProps, RadioGroup } from '../Radio';

type Props = IGroupProps & {
  name: string;
  isYesNo?: boolean;
};

const FormikRadioGroup = (props: Props) => {
  const { name, isYesNo, onChange, ...otherProps } = props;

  return (
    <Field name={name}>
      {(fObj: any) => {
        const {
          field, // { name, value, onChange, onBlur }
          form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        } = fObj;
        const { setFieldValue } = form;
        const value = field.value;
        // const errorMessage = get(errors, field.name);
        return (
          <RadioGroup
            {...otherProps}
            {...field}
            value={isYesNo ? (value ? 'yes' : 'no') : value}
            onChange={(val) => {
              if (onChange) {
                onChange(val);
              } else {
                setFieldValue(name, isYesNo ? val === 'yes' : val);
              }
            }}
          />
        );
      }}
    </Field>
  );
};

export default FormikRadioGroup;
