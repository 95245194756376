import { Request } from './request';

// GET
export const apiGetClients = async (filters = {}) => {
  return Request.call({
    url: `/admin/client`,
    method: 'GET',
    params: {
      ...filters,
    },
  });
};
export const apiGetClientDetail = async (id) => {
  return Request.call({
    url: `/admin/client/${id}`,
    method: 'GET',
  });
};
export const apiGetClientMerchants = async (id, pageNumber, pageSize) => {
  return Request.call({
    url: `/admin/client/${id}/merchant/${pageSize}/${pageNumber}`,
    method: 'GET',
  });
};
export const apiGetClientAvailableMerchants = async (
  id,
  pageNumber,
  pageSize
) => {
  return Request.call({
    url: `/admin/client/${id}/merchant/available/${pageSize}/${pageNumber}`,
    method: 'GET',
  });
};
export const apiUpdateClientMerchants = async (id, data) => {
  return Request.call({
    url: `/admin/client/${id}/merchant`,
    method: 'PUT',
    data,
  });
};
export const apiDeleteClientMerchants = async (id, data) => {
  return Request.call({
    url: `/admin/client/${id}/merchant`,
    method: 'DELETE',
    data,
  });
};
export const apiAddClientMerchants = async (id, data) => {
  return Request.call({
    url: `/admin/client/${id}/merchant`,
    method: 'POST',
    data,
  });
};
