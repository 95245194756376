import { Title as ManTitle, TitleProps } from '@mantine/core';
import cn from 'clsx';
import classes from './Title.module.scss';

type IProps = TitleProps & {
  className?: string;
};

const Title = ({ className, ...other }: IProps) => {
  return <ManTitle {...other} className={cn(classes.wrapper, className)} />;
};
export default Title;
