import { IconAlertTriangle } from '@tabler/icons';
import { Button, Group, Modal, Stack, Text } from 'src/components';
import classes from './ModelDialog.module.scss';
interface MerchantModalProps {
  location: string;
  isOpened: boolean;
  setPreviousBack: (IsBack: boolean) => void;
  des?: string;
  submitText?: string;
  cancelText?: string;
}

export const Confirm = (props: MerchantModalProps) => {
  const { isOpened, setPreviousBack, des, submitText, cancelText } = props;
  const handleCancel = () => {
    setPreviousBack(false);
  };

  return (
    <Modal
      centered
      withCloseButton
      size={556}
      opened={isOpened}
      onClose={() => setPreviousBack(false)}
      closeOnClickOutside={false}
      closeOnEscape={false}
      title="Please Confirm"
      className={classes.modal}
    >
      <Stack justify="center">
        <div className={classes.content}>
          <span className={classes.icon}>
            <IconAlertTriangle />
          </span>
          <Text className={classes.description}>
            {des ||
              `The information you’ve entered for this merchant has not been saved.
            By confirming, any details completed will not be saved.`}
          </Text>
        </div>
        <Group position="right" spacing={16}>
          <Button size="sm" onClick={handleCancel}>
            {cancelText || 'Cancel'}
          </Button>
          <Button
            size="sm"
            variant="outline"
            onClick={() => setPreviousBack(true)}
          >
            {submitText || 'Confirm'}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
