export type IDefaultObj = {
  loading: boolean;
  data: Record<string, unknown>;
  error: string | Record<string, unknown>;
};
export const defaultObj = {
  loading: false,
  data: {},
  error: '',
};
export const defaultArray = {
  loading: false,
  data: [],
  error: '',
};
