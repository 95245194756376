import { PaginationState } from '@tanstack/react-table';
import _ from 'lodash';
import { useContext } from 'react';
import { Download } from 'tabler-icons-react';
import { Button, Group } from 'src/components';
import { TableContext } from '../context';
import { TablePagination } from './TablePagination';

interface TableFooterProps {
  hasPagination: boolean;
  pageSize?: number;
  onPaginationChange: (val: PaginationState) => void;
  onDownload?: (pageSize: number) => void;
  setPagination: (val: PaginationState) => void;
  pagination: PaginationState;
}

export const TableFooter = ({
  pageSize,
  onPaginationChange,
  onDownload,
  hasPagination,
  setPagination,
  pagination,
}: TableFooterProps) => {
  const {
    table,
    theme,
    buttonCancel,
    isSelected,
    buttonDelete,
    buttonSubmit,
    onButtonSubmit,
    onButtonDelete,
    pageCount,
  } = useContext(TableContext);

  return (
    <Group
      position="apart"
      py={'xs'}
      px={'sm'}
      sx={{
        backgroundColor: theme.colors.primaryWhite[0],
        position: 'fixed',
        left: '300px',
        right: 0,
        gap: '5px',
        bottom: 0,
        boxShadow: '1px 3px 1px #888, 0px 0px 6px #888',
        '@media (max-width: 1200px)': {
          left: '200px',
        },
        zIndex: 2,
      }}
    >
      <Button
        isLink
        {...buttonCancel}
        disabled={!isSelected || buttonCancel.disabled}
        onClick={
          buttonCancel.onClick
            ? buttonCancel.onClick
            : () => table.resetRowSelection()
        }
      />
      {hasPagination && (
        <TablePagination
          pageSize={pageSize}
          onPaginationChange={onPaginationChange}
          pageCount={pageCount}
          setPagination={setPagination}
          pagination={pagination}
        />
      )}
      <Group>
        {!!onDownload && (
          <Download color={theme.colors.primaryBlue[3]} cursor="pointer" />
        )}
        <Button
          {...buttonSubmit}
          onClick={() =>
            onButtonSubmit &&
            onButtonSubmit(
              _.map(table.getSelectedRowModel().flatRows, 'original')
            )
          }
          disabled={!isSelected}
        />
        {buttonDelete && (
          <Button
            type="reset"
            isLink
            {...buttonDelete}
            // disabled={!isSelected || buttonDelete.disabled}
            onClick={() =>
              onButtonDelete &&
              onButtonDelete(
                _.map(table.getSelectedRowModel().flatRows, 'original')
              )
            }
          />
        )}
      </Group>
    </Group>
  );
};
