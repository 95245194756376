import * as merchantActions from 'src/store/actions/merchant';
import * as merchantLocationActions from 'src/store/actions/merchantLocation';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  const newMerchantsStore = useReducerData('merchant', 'newMerchants', {});
  return {
    newMerchants: newMerchantsStore.data || {},
    newMerchantsLoading: newMerchantsStore.loading || false,
  };
};

export const useActions = () => {
  return useStoreActions({
    ...merchantActions,
    ...merchantLocationActions,
  });
};
