import { ActionIconProps, ActionIcon as ManActionIcon } from '@mantine/core';
import cn from 'clsx';
import classes from './ActionIcon.module.scss';

type IProps = ActionIconProps & {
  className?: string;
  onClick?: () => void;
};

const ActionIcon = ({ className, ...other }: IProps) => {
  return (
    <ManActionIcon {...other} className={cn(classes.wrapper, className)} />
  );
};
export default ActionIcon;
