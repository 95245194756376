import { TextInput as ManTextInput, TextInputProps } from '@mantine/core';
import cn from 'clsx';
import React from 'react';
import classes from './TextInput.module.scss';

export type ITextInputProps = TextInputProps & {
  className?: string;
};

const TextInput = ({ className = '', ...other }: ITextInputProps) => {
  return <ManTextInput {...other} className={cn(classes.wrapper, className)} />;
};

export default TextInput;
