export const DataMerchants = {
  Data: {
    CurrentPage: 1,
    TotalPages: 36,
    PageSize: 10,
    TotalCount: 356,
    HasPrevious: false,
    HasNext: true,
    Items: [
      {
        Id: 6,
        IsActive: true,
        IsDeleted: false,
        CreatedBy: '1',
        CreatedDate: '2017-01-30T03:19:18.210Z',
        ModifiedBy: '1',
        ModifiedDate: '2022-04-22T00:28:40.927Z',
        LegalName: 'American Frame',
        SystemName: 'americanframe',
        Description:
          '•\tRely on AmericanFrame.com for all your custom printing & framing needs!\r\n•\tCustom print & frame your art & photos on art papers or canvas and receive ready to hang \r\n•\tCustom design DIY picture frame kits and frame your own art\r\n•\tPurchase art reproductions by working artists via the online Art Gallery',
        Website: 'http://www.americanframe.com',
        TermsAndConditions:
          'Terms and Conditions\r\nPurchases will be deducted from the value of the gift card until the balance reaches zero. This card is non-refundable and non-redeemable for cash. There are no fees associated with this gift card. This card cannot be used in combination with other promotions. To check card balance please visit Balance Checker or email customer.service@americanframe.com or call us at (800) 537-0944 M-F 8:30-5:30 EST for fast and friendly answers to your questions.\r\n',
        LogoUrl:
          'https://api.giftango.com/imageservice/Images/0561069_logo_600x380.png',
        CardImageUrl:
          'https://api.giftango.com/imageservice/Images/300x190/CIR_000921_00.png',
        CardholderAgreement: '',
        PurchaseFee: 0.0,
        RevenueShare: 14.0,
        MarketingFee: 0.95,
        MinimumDiscount: 11.6,
        Level1: 11.6,
        Level2: 11.6,
        Level3: 11.6,
        Level4: 11.6,
        Level5: 11.6,
        Level6: 11.6,
        Level7: 11.6,
        IsOnline: true,
        IsPhysical: false,
        IsVariablePurchase: true,
        MinimumCardPurchase: 10.0,
        MaximumCardPurchase: 500.0,
        AcceptsTips: false,
        IsRegional: false,
        UsageInstructions: '',
        UsageInstructionsBak: null,
        BalanceInstructions:
          'Phone: 1-800-537-0944. Other: customer.service@americanframe.com',
        PaymentGatewayId: 1,
        GiftCardGatewayId: 1,
        IsHtmlDescription: false,
        PurchaseInstructions: '',
        AmountPerCard: null,
        ProcessingMessage:
          'This payment will use an eGift code at checkout. \r\n\r\nHang tight as we prepare your digital code for this transaction.',
        HasBarcode: false,
        HasInventory: false,
        IsVoidable: false,
        ReceiptMessage:
          "Picture this... you'll never pay alone again! Thanks for letting us pitch in!",
        CssBorderCode: '#FFFFFF',
        PaymentInstructions:
          '[{"title":"other","description":"At checkout, select the option to pay using a gift card and enter the egift number."}]',
        AlderSku: 'AMFR-D-V-00',
        NgcSku: null,
        AcceptedCurrency: 'USD',
        DeepLink: null,
        IsPayLater: false,
        ProductSubCode: null,
        CertegyMerchantId: null,
        SubProductCode: null,
        ProductCode: null,
        SavingTypeId: null,
        SavingsPercentage: 0.0,
        IsAchEnabled: false,
      },
      {
        Id: 12,
        IsActive: true,
        IsDeleted: false,
        CreatedBy: '1',
        CreatedDate: '2017-01-30T03:19:20.417Z',
        ModifiedBy: '1',
        ModifiedDate: '2022-04-22T00:28:46.797Z',
        LegalName: 'Banana Republic',
        SystemName: 'bananarepublic',
        Description:
          'Exceptional quality, luxurious fabrics and refined tailoring define Banana Republic’s signature style. From work to casual occasions, Banana Republic offers covetable, uncomplicated style. For the nearest store call 1-888-BR-STYLE or visit us online at bananarepublic.com.',
        Website: 'http://www.gap.com',
        TermsAndConditions:
          'Terms and Conditions\r\nGap, Old Navy, Banana Republic and, Athleta eGift Cards are issued by and represent an obligation of Direct Consumer Services (“DCS”). The eGiftCard may be redeemed for merchandise at any Gap, Old Navy, Banana Republic and, Athleta location, including Outlet and Factory stores. The eGiftCard may also be redeemed online for merchandise at gap.com, gapfactory.com, oldnavy.com, bananarepublic.com, brfactory.com and, athleta.com. If you return merchandise originally purchased with an eGiftCard, any refund will be issued in the form of a plastic gift card issued by DCS which may be used at any of these stores or online. Any merchandise purchased with an eGiftCard is subject to the applicable store or online return policies. Purchases will be deducted from the amount of this eGiftCard until the value reaches zero. This eGiftCard may not be used or redeemed with certain promotional offers and may not be applied as payment on any account. You may not use an eGiftCard to purchase other eGiftCards. Value may not be added to this card, new card purchase required for additional value. Not redeemable for cash unless required by law. Restricted to use in the USA, Puerto Rico and Canada. Void if resold or transferred for value. By purchasing this eGiftCard, you agree that the laws of the State of California, without regard to principles of conflict of laws, will govern this Agreement. Limit 5 eGiftCards redeemable per online transaction.\r\n\r\n1-800-BRSTYLE. No replacement cards will be issued without original receipt.',
        LogoUrl:
          'https://api.giftango.com/imageservice/Images/032981_logo_600x380.png',
        CardImageUrl:
          'https://api.giftango.com/imageservice/Images/300x190/CIR_000255_05.png',
        CardholderAgreement: '',
        PurchaseFee: 0.0,
        RevenueShare: 10.5,
        MarketingFee: 0.61,
        MinimumDiscount: 8.5,
        Level1: 8.5,
        Level2: 8.5,
        Level3: 8.5,
        Level4: 8.5,
        Level5: 8.5,
        Level6: 8.5,
        Level7: 8.5,
        IsOnline: true,
        IsPhysical: true,
        IsVariablePurchase: true,
        MinimumCardPurchase: 10.0,
        MaximumCardPurchase: 500.0,
        AcceptsTips: false,
        IsRegional: false,
        UsageInstructions: '',
        UsageInstructionsBak: null,
        BalanceInstructions:
          'Online: https://oldnavy.gap.com/browse/info.do?cid=35433#giftcardbalance',
        PaymentGatewayId: 1,
        GiftCardGatewayId: 1,
        IsHtmlDescription: false,
        PurchaseInstructions: '',
        AmountPerCard: null,
        ProcessingMessage:
          "Let your cashier know that you'll be using an eGift code to pay the bill. \r\n\r\nHang tight as we get it ready for this transaction.",
        HasBarcode: false,
        HasInventory: false,
        IsVoidable: true,
        ReceiptMessage:
          "Now strut out like someone just paid part of your bill - 'cause we did.",
        CssBorderCode: '#FFFFFF',
        PaymentInstructions:
          '[{"title":"in-store","description":"Tell the cashier that you are paying with a digital gift card. Present the barcode for scanning, or read them the numbers to enter manually."},{"title":"online","description":"In the payment section, enter your digital gift card number into the gift card field."}]',
        AlderSku: 'BNRP-D-V-01',
        NgcSku: null,
        AcceptedCurrency: 'USD',
        DeepLink: 'https://dashdirect.page.link/BRepublic',
        IsPayLater: false,
        ProductSubCode: null,
        CertegyMerchantId: null,
        SubProductCode: null,
        ProductCode: null,
        SavingTypeId: null,
        SavingsPercentage: 0.0,
        IsAchEnabled: false,
      },
      {
        Id: 15,
        IsActive: true,
        IsDeleted: false,
        CreatedBy: '1',
        CreatedDate: '2017-01-30T03:19:21.623Z',
        ModifiedBy: '1',
        ModifiedDate: '2022-04-22T00:28:49.390Z',
        LegalName: 'Bed Bath & Beyond',
        SystemName: 'bedbathbeyond',
        Description:
          'BED BATH & BEYOND® is a nationwide chain of 850 stores offering one of the largest selections of products at everyday low prices to help you home, happier. The vast assortment of BED BATH & BEYOND® merchandise includes bed linens, bath accessories, kitchen electrics, kitchen linens, cookware, dinnerware, glassware, lifestyle accessories, window treatments, storage & organization, and decorative accessories. You’ll find the brand names you know and trust: Keurig®, Nestwell™, Wamsutta®, Haven®, Simply Essential™, Real Simple®, Shark®, OXO, Henckels, Blendtec®, Cuisinart®, Lenox®, Noritake®, All-Clad, Barbara Barry® and hundreds more of the best brands for your home. Our nationwide Bridal and Gift registry is also available to customers online.',
        Website: 'http://Bedbathandbeyond.com',
        TermsAndConditions:
          'For balance inquiries or store locations, call 1-800-GO BEYOND (1-800-462-3966) or visit bedbathandbeyond.com/giftcard\r\nThis gift card may not be used towards the purchase of other gift cards, including pre-paid gift cards, such as Visa®, Mastercard® or American Express®, for portrait studio services, nor may it be redeemed for cash, except where required by law. If lost or stolen, a replacement gift card will be issued with the balance remaining, when an original receipt is presented. No service fees or expiration dates are connected with the use of this gift card. Gift card may not be redeemed until activated.\r\n \r\nGift card can also be redeemed at our other retail concepts or online at bedbathandbeyond.com and buybuybaby.com\r\n \r\nFor periodic updates visit: bedbathandbeyond.com/giftcardpolicy\r\n \r\nIssued by BBB Value Services Inc. ©Liberty Procurement Co. Inc.',
        LogoUrl:
          'https://api.giftango.com/imageservice/Images/698261_logo_600x380.png',
        CardImageUrl:
          'https://api.giftango.com/imageservice/Images/300x190/CIR_001012_02.png',
        CardholderAgreement: '',
        PurchaseFee: 0.0,
        RevenueShare: 5.6,
        MarketingFee: 0.19,
        MinimumDiscount: 4.0,
        Level1: 4.0,
        Level2: 4.0,
        Level3: 4.0,
        Level4: 4.0,
        Level5: 4.0,
        Level6: 4.0,
        Level7: 4.0,
        IsOnline: true,
        IsPhysical: true,
        IsVariablePurchase: true,
        MinimumCardPurchase: 1.0,
        MaximumCardPurchase: 500.0,
        AcceptsTips: false,
        IsRegional: false,
        UsageInstructions: '',
        UsageInstructionsBak: null,
        BalanceInstructions:
          'Online: https://www.bedbathandbeyond.com/store/static/GiftCardHomePage',
        PaymentGatewayId: 1,
        GiftCardGatewayId: 1,
        IsHtmlDescription: false,
        PurchaseInstructions: '',
        AmountPerCard: null,
        ProcessingMessage:
          "Let your cashier know that you'll be using an eGift code to pay the bill. \r\n\r\nHang tight as we get it ready for this transaction.",
        HasBarcode: false,
        HasInventory: false,
        IsVoidable: false,
        ReceiptMessage:
          "Now strut out like someone just paid part of your bill - 'cause we did.",
        CssBorderCode: '#323794',
        PaymentInstructions:
          '[{"title":"in-store","description":"Tell the cashier that you are paying with a digital gift card. Present the barcode for scanning, or read them the numbers to enter manually."},{"title":"online","description":"In the payment section, enter your digital gift card number into the gift card field."}]',
        AlderSku: 'BBBY-D-V-00',
        NgcSku: null,
        AcceptedCurrency: 'USD',
        DeepLink: 'https://dashdirect.page.link/BBB',
        IsPayLater: true,
        ProductSubCode: null,
        CertegyMerchantId: null,
        SubProductCode: null,
        ProductCode: null,
        SavingTypeId: null,
        SavingsPercentage: 0.0,
        IsAchEnabled: false,
      },
      {
        Id: 22,
        IsActive: true,
        IsDeleted: false,
        CreatedBy: '1',
        CreatedDate: '2017-01-30T03:19:23.953Z',
        ModifiedBy: '1',
        ModifiedDate: '2022-04-22T00:29:00.703Z',
        LegalName: 'Buffalo Wild Wings®',
        SystemName: 'buffalowildwings',
        Description:
          'Buffalo Wild Wings® is a sports bar with beer and wings and a crazy amount of TVs showing all the games. And rowdy fans and all the sauces for all the wings. Did we mention beer? Because we pour more beer than anyone in the country. So bring your friends, your outside voice and cheer on your favorite team to victory at any of our 1,200+ locations nationwide. Why aren’t you here already? www.buffalowildwings.com',
        Website: 'http://www.buffalowildwings.com',
        TermsAndConditions:
          'Terms and Conditions\r\nThis Card never expires and there are no fees. To get your Card balance, visit buffalowildwings.com. Purchase, use or acceptance of this Card constitutes acceptance of these Terms. This Card is redeemable up to the balance for food, product, beverage or alcohol (where permitted by law) at participating Buffalo Wild Wings locations in the U.S. This Card is not redeemable for cash or refundable unless required by law. It is not reloadable or usable to purchase gift cards. Blazin Wings, Inc. is the Card issuer and sole obligor to Card owner, and may delegate its issuer obligations to an assignee, without recourse. If delegated, the assignee, and not Blazin Wings, Inc. will be sole obligor to Card owner. This Card may not be resold without authorization; Blazin Wings, Inc. has the right to deactivate or reject Cards sold by unauthorized sellers or Cards involved in fraudulent activity. This Card is not a debit or credit card. It will not be replaced if lost, stolen, damaged or used without authorization. Other Terms apply, visit: buffalowildwings.com. TM & © 2021 Buffalo Wild Wings, Inc.',
        LogoUrl:
          'https://api.giftango.com/imageservice/Images/471089_Logo_300x190.png',
        CardImageUrl:
          'https://api.giftango.com/imageservice/Images/300x190/CIR_000683_08.png',
        CardholderAgreement: '',
        PurchaseFee: 0.0,
        RevenueShare: 7.0,
        MarketingFee: 0.53,
        MinimumDiscount: 5.0,
        Level1: 5.0,
        Level2: 5.0,
        Level3: 5.0,
        Level4: 5.0,
        Level5: 5.0,
        Level6: 5.0,
        Level7: 5.0,
        IsOnline: true,
        IsPhysical: true,
        IsVariablePurchase: true,
        MinimumCardPurchase: 5.0,
        MaximumCardPurchase: 250.0,
        AcceptsTips: true,
        IsRegional: false,
        UsageInstructions: '',
        UsageInstructionsBak: null,
        BalanceInstructions:
          'Online: https://buffalowildwingsstore.wgiftcard.com/rbc/buffalowildwings_resp',
        PaymentGatewayId: 1,
        GiftCardGatewayId: 1,
        IsHtmlDescription: false,
        PurchaseInstructions: '',
        AmountPerCard: null,
        ProcessingMessage:
          "Let your server know that you'll be using an eGift code to pay the bill. \r\n\r\nHang tight as we get it ready for this transaction.",
        HasBarcode: false,
        HasInventory: false,
        IsVoidable: false,
        ReceiptMessage:
          "Now strut out like someone just paid part of your bill - 'cause we did.",
        CssBorderCode: '#FFD900',
        PaymentInstructions:
          '[{"title":"other","description":"Write the egift code down on the bottom of your bill. Tell the server you will be paying with a digital gift card."}]',
        AlderSku: 'BWW-D-V-00',
        NgcSku: null,
        AcceptedCurrency: 'USD',
        DeepLink: 'https://dashdirect.page.link/BuffaloWW',
        IsPayLater: false,
        ProductSubCode: null,
        CertegyMerchantId: null,
        SubProductCode: null,
        ProductCode: null,
        SavingTypeId: null,
        SavingsPercentage: 0.0,
        IsAchEnabled: false,
      },
      {
        Id: 22,
        IsActive: true,
        IsDeleted: false,
        CreatedBy: '1',
        CreatedDate: '2017-01-30T03:19:23.953Z',
        ModifiedBy: '1',
        ModifiedDate: '2022-04-22T00:29:00.703Z',
        LegalName: 'Buffalo Wild Wings®',
        SystemName: 'buffalowildwings',
        Description:
          'Buffalo Wild Wings® is a sports bar with beer and wings and a crazy amount of TVs showing all the games. And rowdy fans and all the sauces for all the wings. Did we mention beer? Because we pour more beer than anyone in the country. So bring your friends, your outside voice and cheer on your favorite team to victory at any of our 1,200+ locations nationwide. Why aren’t you here already? www.buffalowildwings.com',
        Website: 'http://www.buffalowildwings.com',
        TermsAndConditions:
          'Terms and Conditions\r\nThis Card never expires and there are no fees. To get your Card balance, visit buffalowildwings.com. Purchase, use or acceptance of this Card constitutes acceptance of these Terms. This Card is redeemable up to the balance for food, product, beverage or alcohol (where permitted by law) at participating Buffalo Wild Wings locations in the U.S. This Card is not redeemable for cash or refundable unless required by law. It is not reloadable or usable to purchase gift cards. Blazin Wings, Inc. is the Card issuer and sole obligor to Card owner, and may delegate its issuer obligations to an assignee, without recourse. If delegated, the assignee, and not Blazin Wings, Inc. will be sole obligor to Card owner. This Card may not be resold without authorization; Blazin Wings, Inc. has the right to deactivate or reject Cards sold by unauthorized sellers or Cards involved in fraudulent activity. This Card is not a debit or credit card. It will not be replaced if lost, stolen, damaged or used without authorization. Other Terms apply, visit: buffalowildwings.com. TM & © 2021 Buffalo Wild Wings, Inc.',
        LogoUrl:
          'https://api.giftango.com/imageservice/Images/471089_Logo_300x190.png',
        CardImageUrl:
          'https://api.giftango.com/imageservice/Images/300x190/CIR_000683_08.png',
        CardholderAgreement: '',
        PurchaseFee: 0.0,
        RevenueShare: 7.0,
        MarketingFee: 0.53,
        MinimumDiscount: 5.0,
        Level1: 5.0,
        Level2: 5.0,
        Level3: 5.0,
        Level4: 5.0,
        Level5: 5.0,
        Level6: 5.0,
        Level7: 5.0,
        IsOnline: true,
        IsPhysical: true,
        IsVariablePurchase: true,
        MinimumCardPurchase: 5.0,
        MaximumCardPurchase: 250.0,
        AcceptsTips: true,
        IsRegional: false,
        UsageInstructions: '',
        UsageInstructionsBak: null,
        BalanceInstructions:
          'Online: https://buffalowildwingsstore.wgiftcard.com/rbc/buffalowildwings_resp',
        PaymentGatewayId: 1,
        GiftCardGatewayId: 1,
        IsHtmlDescription: false,
        PurchaseInstructions: '',
        AmountPerCard: null,
        ProcessingMessage:
          "Let your server know that you'll be using an eGift code to pay the bill. \r\n\r\nHang tight as we get it ready for this transaction.",
        HasBarcode: false,
        HasInventory: false,
        IsVoidable: false,
        ReceiptMessage:
          "Now strut out like someone just paid part of your bill - 'cause we did.",
        CssBorderCode: '#FFD900',
        PaymentInstructions:
          '[{"title":"other","description":"Write the egift code down on the bottom of your bill. Tell the server you will be paying with a digital gift card."}]',
        AlderSku: 'BWW-D-V-00',
        NgcSku: null,
        AcceptedCurrency: 'USD',
        DeepLink: 'https://dashdirect.page.link/BuffaloWW',
        IsPayLater: false,
        ProductSubCode: null,
        CertegyMerchantId: null,
        SubProductCode: null,
        ProductCode: null,
        SavingTypeId: null,
        SavingsPercentage: 5.0,
        IsAchEnabled: false,
      },
      {
        Id: 22,
        IsActive: true,
        IsDeleted: false,
        CreatedBy: '1',
        CreatedDate: '2017-01-30T03:19:23.953Z',
        ModifiedBy: '1',
        ModifiedDate: '2022-04-22T00:29:00.703Z',
        LegalName: 'Buffalo Wild Wings®',
        SystemName: 'buffalowildwings',
        Description:
          'Buffalo Wild Wings® is a sports bar with beer and wings and a crazy amount of TVs showing all the games. And rowdy fans and all the sauces for all the wings. Did we mention beer? Because we pour more beer than anyone in the country. So bring your friends, your outside voice and cheer on your favorite team to victory at any of our 1,200+ locations nationwide. Why aren’t you here already? www.buffalowildwings.com',
        Website: 'http://www.buffalowildwings.com',
        TermsAndConditions:
          'Terms and Conditions\r\nThis Card never expires and there are no fees. To get your Card balance, visit buffalowildwings.com. Purchase, use or acceptance of this Card constitutes acceptance of these Terms. This Card is redeemable up to the balance for food, product, beverage or alcohol (where permitted by law) at participating Buffalo Wild Wings locations in the U.S. This Card is not redeemable for cash or refundable unless required by law. It is not reloadable or usable to purchase gift cards. Blazin Wings, Inc. is the Card issuer and sole obligor to Card owner, and may delegate its issuer obligations to an assignee, without recourse. If delegated, the assignee, and not Blazin Wings, Inc. will be sole obligor to Card owner. This Card may not be resold without authorization; Blazin Wings, Inc. has the right to deactivate or reject Cards sold by unauthorized sellers or Cards involved in fraudulent activity. This Card is not a debit or credit card. It will not be replaced if lost, stolen, damaged or used without authorization. Other Terms apply, visit: buffalowildwings.com. TM & © 2021 Buffalo Wild Wings, Inc.',
        LogoUrl:
          'https://api.giftango.com/imageservice/Images/471089_Logo_300x190.png',
        CardImageUrl:
          'https://api.giftango.com/imageservice/Images/300x190/CIR_000683_08.png',
        CardholderAgreement: '',
        PurchaseFee: 0.0,
        RevenueShare: 7.0,
        MarketingFee: 0.53,
        MinimumDiscount: 5.0,
        Level1: 5.0,
        Level2: 5.0,
        Level3: 5.0,
        Level4: 5.0,
        Level5: 5.0,
        Level6: 5.0,
        Level7: 5.0,
        IsOnline: true,
        IsPhysical: true,
        IsVariablePurchase: true,
        MinimumCardPurchase: 5.0,
        MaximumCardPurchase: 250.0,
        AcceptsTips: true,
        IsRegional: false,
        UsageInstructions: '',
        UsageInstructionsBak: null,
        BalanceInstructions:
          'Online: https://buffalowildwingsstore.wgiftcard.com/rbc/buffalowildwings_resp',
        PaymentGatewayId: 1,
        GiftCardGatewayId: 1,
        IsHtmlDescription: false,
        PurchaseInstructions: '',
        AmountPerCard: null,
        ProcessingMessage:
          "Let your server know that you'll be using an eGift code to pay the bill. \r\n\r\nHang tight as we get it ready for this transaction.",
        HasBarcode: false,
        HasInventory: false,
        IsVoidable: false,
        ReceiptMessage:
          "Now strut out like someone just paid part of your bill - 'cause we did.",
        CssBorderCode: '#FFD900',
        PaymentInstructions:
          '[{"title":"other","description":"Write the egift code down on the bottom of your bill. Tell the server you will be paying with a digital gift card."}]',
        AlderSku: 'BWW-D-V-00',
        NgcSku: null,
        AcceptedCurrency: 'USD',
        DeepLink: 'https://dashdirect.page.link/BuffaloWW',
        IsPayLater: false,
        ProductSubCode: null,
        CertegyMerchantId: null,
        SubProductCode: null,
        ProductCode: null,
        SavingTypeId: null,
        SavingsPercentage: 3.0,
        IsAchEnabled: false,
      },
      {
        Id: 22,
        IsActive: true,
        IsDeleted: false,
        CreatedBy: '1',
        CreatedDate: '2017-01-30T03:19:23.953Z',
        ModifiedBy: '1',
        ModifiedDate: '2022-04-22T00:29:00.703Z',
        LegalName: 'Buffalo Wild Wings®',
        SystemName: 'buffalowildwings',
        Description:
          'Buffalo Wild Wings® is a sports bar with beer and wings and a crazy amount of TVs showing all the games. And rowdy fans and all the sauces for all the wings. Did we mention beer? Because we pour more beer than anyone in the country. So bring your friends, your outside voice and cheer on your favorite team to victory at any of our 1,200+ locations nationwide. Why aren’t you here already? www.buffalowildwings.com',
        Website: 'http://www.buffalowildwings.com',
        TermsAndConditions:
          'Terms and Conditions\r\nThis Card never expires and there are no fees. To get your Card balance, visit buffalowildwings.com. Purchase, use or acceptance of this Card constitutes acceptance of these Terms. This Card is redeemable up to the balance for food, product, beverage or alcohol (where permitted by law) at participating Buffalo Wild Wings locations in the U.S. This Card is not redeemable for cash or refundable unless required by law. It is not reloadable or usable to purchase gift cards. Blazin Wings, Inc. is the Card issuer and sole obligor to Card owner, and may delegate its issuer obligations to an assignee, without recourse. If delegated, the assignee, and not Blazin Wings, Inc. will be sole obligor to Card owner. This Card may not be resold without authorization; Blazin Wings, Inc. has the right to deactivate or reject Cards sold by unauthorized sellers or Cards involved in fraudulent activity. This Card is not a debit or credit card. It will not be replaced if lost, stolen, damaged or used without authorization. Other Terms apply, visit: buffalowildwings.com. TM & © 2021 Buffalo Wild Wings, Inc.',
        LogoUrl:
          'https://api.giftango.com/imageservice/Images/471089_Logo_300x190.png',
        CardImageUrl:
          'https://api.giftango.com/imageservice/Images/300x190/CIR_000683_08.png',
        CardholderAgreement: '',
        PurchaseFee: 0.0,
        RevenueShare: 7.0,
        MarketingFee: 0.53,
        MinimumDiscount: 5.0,
        Level1: 5.0,
        Level2: 5.0,
        Level3: 5.0,
        Level4: 5.0,
        Level5: 5.0,
        Level6: 5.0,
        Level7: 5.0,
        IsOnline: true,
        IsPhysical: true,
        IsVariablePurchase: true,
        MinimumCardPurchase: 5.0,
        MaximumCardPurchase: 250.0,
        AcceptsTips: true,
        IsRegional: false,
        UsageInstructions: '',
        UsageInstructionsBak: null,
        BalanceInstructions:
          'Online: https://buffalowildwingsstore.wgiftcard.com/rbc/buffalowildwings_resp',
        PaymentGatewayId: 1,
        GiftCardGatewayId: 1,
        IsHtmlDescription: false,
        PurchaseInstructions: '',
        AmountPerCard: null,
        ProcessingMessage:
          "Let your server know that you'll be using an eGift code to pay the bill. \r\n\r\nHang tight as we get it ready for this transaction.",
        HasBarcode: false,
        HasInventory: false,
        IsVoidable: false,
        ReceiptMessage:
          "Now strut out like someone just paid part of your bill - 'cause we did.",
        CssBorderCode: '#FFD900',
        PaymentInstructions:
          '[{"title":"other","description":"Write the egift code down on the bottom of your bill. Tell the server you will be paying with a digital gift card."}]',
        AlderSku: 'BWW-D-V-00',
        NgcSku: null,
        AcceptedCurrency: 'USD',
        DeepLink: 'https://dashdirect.page.link/BuffaloWW',
        IsPayLater: false,
        ProductSubCode: null,
        CertegyMerchantId: null,
        SubProductCode: null,
        ProductCode: null,
        SavingTypeId: null,
        SavingsPercentage: 5.0,
        IsAchEnabled: false,
      },
      {
        Id: 22,
        IsActive: true,
        IsDeleted: false,
        CreatedBy: '1',
        CreatedDate: '2017-01-30T03:19:23.953Z',
        ModifiedBy: '1',
        ModifiedDate: '2022-04-22T00:29:00.703Z',
        LegalName: 'Buffalo Wild Wings®',
        SystemName: 'buffalowildwings',
        Description:
          'Buffalo Wild Wings® is a sports bar with beer and wings and a crazy amount of TVs showing all the games. And rowdy fans and all the sauces for all the wings. Did we mention beer? Because we pour more beer than anyone in the country. So bring your friends, your outside voice and cheer on your favorite team to victory at any of our 1,200+ locations nationwide. Why aren’t you here already? www.buffalowildwings.com',
        Website: 'http://www.buffalowildwings.com',
        TermsAndConditions:
          'Terms and Conditions\r\nThis Card never expires and there are no fees. To get your Card balance, visit buffalowildwings.com. Purchase, use or acceptance of this Card constitutes acceptance of these Terms. This Card is redeemable up to the balance for food, product, beverage or alcohol (where permitted by law) at participating Buffalo Wild Wings locations in the U.S. This Card is not redeemable for cash or refundable unless required by law. It is not reloadable or usable to purchase gift cards. Blazin Wings, Inc. is the Card issuer and sole obligor to Card owner, and may delegate its issuer obligations to an assignee, without recourse. If delegated, the assignee, and not Blazin Wings, Inc. will be sole obligor to Card owner. This Card may not be resold without authorization; Blazin Wings, Inc. has the right to deactivate or reject Cards sold by unauthorized sellers or Cards involved in fraudulent activity. This Card is not a debit or credit card. It will not be replaced if lost, stolen, damaged or used without authorization. Other Terms apply, visit: buffalowildwings.com. TM & © 2021 Buffalo Wild Wings, Inc.',
        LogoUrl:
          'https://api.giftango.com/imageservice/Images/471089_Logo_300x190.png',
        CardImageUrl:
          'https://api.giftango.com/imageservice/Images/300x190/CIR_000683_08.png',
        CardholderAgreement: '',
        PurchaseFee: 0.0,
        RevenueShare: 7.0,
        MarketingFee: 0.53,
        MinimumDiscount: 5.0,
        Level1: 5.0,
        Level2: 5.0,
        Level3: 5.0,
        Level4: 5.0,
        Level5: 5.0,
        Level6: 5.0,
        Level7: 5.0,
        IsOnline: true,
        IsPhysical: true,
        IsVariablePurchase: true,
        MinimumCardPurchase: 5.0,
        MaximumCardPurchase: 250.0,
        AcceptsTips: true,
        IsRegional: false,
        UsageInstructions: '',
        UsageInstructionsBak: null,
        BalanceInstructions:
          'Online: https://buffalowildwingsstore.wgiftcard.com/rbc/buffalowildwings_resp',
        PaymentGatewayId: 1,
        GiftCardGatewayId: 1,
        IsHtmlDescription: false,
        PurchaseInstructions: '',
        AmountPerCard: null,
        ProcessingMessage:
          "Let your server know that you'll be using an eGift code to pay the bill. \r\n\r\nHang tight as we get it ready for this transaction.",
        HasBarcode: false,
        HasInventory: false,
        IsVoidable: false,
        ReceiptMessage:
          "Now strut out like someone just paid part of your bill - 'cause we did.",
        CssBorderCode: '#FFD900',
        PaymentInstructions:
          '[{"title":"other","description":"Write the egift code down on the bottom of your bill. Tell the server you will be paying with a digital gift card."}]',
        AlderSku: 'BWW-D-V-00',
        NgcSku: null,
        AcceptedCurrency: 'USD',
        DeepLink: 'https://dashdirect.page.link/BuffaloWW',
        IsPayLater: false,
        ProductSubCode: null,
        CertegyMerchantId: null,
        SubProductCode: null,
        ProductCode: null,
        SavingTypeId: null,
        SavingsPercentage: 2.5,
        IsAchEnabled: false,
      },
      {
        Id: 22,
        IsActive: true,
        IsDeleted: false,
        CreatedBy: '1',
        CreatedDate: '2017-01-30T03:19:23.953Z',
        ModifiedBy: '1',
        ModifiedDate: '2022-04-22T00:29:00.703Z',
        LegalName: 'Buffalo Wild Wings®',
        SystemName: 'buffalowildwings',
        Description:
          'Buffalo Wild Wings® is a sports bar with beer and wings and a crazy amount of TVs showing all the games. And rowdy fans and all the sauces for all the wings. Did we mention beer? Because we pour more beer than anyone in the country. So bring your friends, your outside voice and cheer on your favorite team to victory at any of our 1,200+ locations nationwide. Why aren’t you here already? www.buffalowildwings.com',
        Website: 'http://www.buffalowildwings.com',
        TermsAndConditions:
          'Terms and Conditions\r\nThis Card never expires and there are no fees. To get your Card balance, visit buffalowildwings.com. Purchase, use or acceptance of this Card constitutes acceptance of these Terms. This Card is redeemable up to the balance for food, product, beverage or alcohol (where permitted by law) at participating Buffalo Wild Wings locations in the U.S. This Card is not redeemable for cash or refundable unless required by law. It is not reloadable or usable to purchase gift cards. Blazin Wings, Inc. is the Card issuer and sole obligor to Card owner, and may delegate its issuer obligations to an assignee, without recourse. If delegated, the assignee, and not Blazin Wings, Inc. will be sole obligor to Card owner. This Card may not be resold without authorization; Blazin Wings, Inc. has the right to deactivate or reject Cards sold by unauthorized sellers or Cards involved in fraudulent activity. This Card is not a debit or credit card. It will not be replaced if lost, stolen, damaged or used without authorization. Other Terms apply, visit: buffalowildwings.com. TM & © 2021 Buffalo Wild Wings, Inc.',
        LogoUrl:
          'https://api.giftango.com/imageservice/Images/471089_Logo_300x190.png',
        CardImageUrl:
          'https://api.giftango.com/imageservice/Images/300x190/CIR_000683_08.png',
        CardholderAgreement: '',
        PurchaseFee: 0.0,
        RevenueShare: 7.0,
        MarketingFee: 0.53,
        MinimumDiscount: 5.0,
        Level1: 5.0,
        Level2: 5.0,
        Level3: 5.0,
        Level4: 5.0,
        Level5: 5.0,
        Level6: 5.0,
        Level7: 5.0,
        IsOnline: true,
        IsPhysical: true,
        IsVariablePurchase: true,
        MinimumCardPurchase: 5.0,
        MaximumCardPurchase: 250.0,
        AcceptsTips: true,
        IsRegional: false,
        UsageInstructions: '',
        UsageInstructionsBak: null,
        BalanceInstructions:
          'Online: https://buffalowildwingsstore.wgiftcard.com/rbc/buffalowildwings_resp',
        PaymentGatewayId: 1,
        GiftCardGatewayId: 1,
        IsHtmlDescription: false,
        PurchaseInstructions: '',
        AmountPerCard: null,
        ProcessingMessage:
          "Let your server know that you'll be using an eGift code to pay the bill. \r\n\r\nHang tight as we get it ready for this transaction.",
        HasBarcode: false,
        HasInventory: false,
        IsVoidable: false,
        ReceiptMessage:
          "Now strut out like someone just paid part of your bill - 'cause we did.",
        CssBorderCode: '#FFD900',
        PaymentInstructions:
          '[{"title":"other","description":"Write the egift code down on the bottom of your bill. Tell the server you will be paying with a digital gift card."}]',
        AlderSku: 'BWW-D-V-00',
        NgcSku: null,
        AcceptedCurrency: 'USD',
        DeepLink: 'https://dashdirect.page.link/BuffaloWW',
        IsPayLater: false,
        ProductSubCode: null,
        CertegyMerchantId: null,
        SubProductCode: null,
        ProductCode: null,
        SavingTypeId: null,
        SavingsPercentage: 2.5,
        IsAchEnabled: false,
      },
      {
        Id: 32,
        IsActive: true,
        IsDeleted: false,
        CreatedBy: '1',
        CreatedDate: '2017-01-30T03:23:05.043Z',
        ModifiedBy: '1',
        ModifiedDate: '2022-04-22T00:29:16.887Z',
        LegalName: 'Columbia',
        SystemName: 'columbiasportswear',
        Description:
          "Columbia, the flagship brand of Portland, Oregon-based Columbia Sportswear Company, has been creating innovative apparel, footwear, accessories and equipment for outdoor enthusiasts since 1938. Columbia has become a leading global brand by channeling the company's passion for the outdoors into technologies and performance products that keep people warm, dry, cool and protected year-round. To learn more, please visit the company's website at www.columbia.com. \r\n THE GIFT OF ADVENTURE.\r\nGift cards are the perfect way to keep friends and family warm, dry, cool, and protected outdoors",
        Website: '',
        TermsAndConditions:
          'This Gift Card is redeemable for merchandise at all Columbia Sportswear retail and outlet stores in the United States or online at Columbia.com. This Gift Card is non-refundable, does not expire, and has no fees. May not be redeemed at third party retailers or sites; redeemed outside the U.S.; transferred for value; redeemed for cash; or returned for a cash refund except where required by law.  Columbia Sportswear is not responsible for, and will not replace, lost, stolen, or damaged Gift Cards. For balance inquiries, information about the terms of this Gift Card, and store locations, go to columbia.com or call 866-682-9879.  Gift Card Terms & Conditions apply. Columbia Sportswear Company™ is a registered trademark of Columbia Sportswear North America Company. This Gift Card is issued by Columbia Sportswear Brands USA, LLC, Portland, Oregon. ©2021 Columbia Sportswear Brands USA, LLC. All rights reserved.',
        LogoUrl:
          'https://api.giftango.com/imageservice/Images/053485_logo_600x380.png',
        CardImageUrl:
          'https://api.giftango.com/imageservice/Images/300x190/CIR_000219_07.png',
        CardholderAgreement: '',
        PurchaseFee: 0.0,
        RevenueShare: 7.0,
        MarketingFee: 0.78,
        MinimumDiscount: 4.8,
        Level1: 4.8,
        Level2: 4.8,
        Level3: 4.8,
        Level4: 4.8,
        Level5: 4.8,
        Level6: 4.8,
        Level7: 4.8,
        IsOnline: true,
        IsPhysical: true,
        IsVariablePurchase: true,
        MinimumCardPurchase: 5.0,
        MaximumCardPurchase: 500.0,
        AcceptsTips: false,
        IsRegional: false,
        UsageInstructions: '',
        UsageInstructionsBak: null,
        BalanceInstructions: 'Online: https://www.columbia.com/giftcards/',
        PaymentGatewayId: 1,
        GiftCardGatewayId: 1,
        IsHtmlDescription: false,
        PurchaseInstructions: '',
        AmountPerCard: null,
        ProcessingMessage:
          'This payment will use an eGift code at checkout. \r\n\r\nHang tight as we prepare your digital code for this transaction.',
        HasBarcode: false,
        HasInventory: false,
        IsVoidable: false,
        ReceiptMessage:
          "That's a little more to keep in your pocket.\r\nYou're welcome!",
        CssBorderCode: '#FFFFFF',
        PaymentInstructions:
          '[{"title":"in-store","description":"Tell the cashier that you are paying with a digital gift card. Present the barcode for scanning, or read them the numbers to enter manually."},{"title":"online","description":"In the payment section, enter your digital gift card number into the gift card field."}]',
        AlderSku: 'CLBA-D-V-00',
        NgcSku: null,
        AcceptedCurrency: 'USD',
        DeepLink: 'https://dashdirect.page.link/Columbia',
        IsPayLater: false,
        ProductSubCode: null,
        CertegyMerchantId: null,
        SubProductCode: null,
        ProductCode: null,
        SavingTypeId: null,
        SavingsPercentage: 0.0,
        IsAchEnabled: false,
      },
    ],
  },
  Successful: true,
  ErrorMessage: '',
  IsDelayed: false,
  DelayedToken: null,
  AccessToken: null,
};
