import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from 'src/components/layouts/layout';
import { LoginLayout } from 'src/components/layouts/loginLayout';
import { ERoutes } from 'src/helper/constants';
import { ClientDetail } from '../ClientDetail';
import { Clients } from '../Clients';
import { MerchantDetail } from '../MerchantDetail';
import WalletBot from '../WalletBot';
import { Home } from '../home';
import { Merchants } from '../merchant';
import CreateMerchant from '../merchant/CreateMerchant';
import { MerchantLocation } from '../merchantLocation';
import { AllMerchants } from '../merchants/allMerchants';
import { NewMerchants } from '../merchants/newMerchants';
import { PrivateRoute } from './privateRoute';
import { useActions } from './selectorData';

export const AppRoutes = () => {
  const { getMerchantCategories, getMerchantTags } = useActions();
  useEffect(() => {
    getMerchantCategories();
    getMerchantTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path={ERoutes.Login} element={<LoginLayout />}></Route>

      <Route
        path={ERoutes.Home}
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      >
        <Route path={ERoutes.Home} element={<AllMerchants />} />
        <Route path={ERoutes.User} element={<Home />} />
        <Route path={ERoutes.Merchants} element={<Merchants />} />
        <Route path={ERoutes.MerchantDetail} element={<MerchantDetail />} />
        <Route path={ERoutes.AllMerchants} element={<AllMerchants />} />
        <Route path={ERoutes.NewMerchants} element={<NewMerchants />} />
        <Route path={ERoutes.CreateMerchant} element={<CreateMerchant />} />
        <Route path={ERoutes.Clients} element={<Clients />} />
        <Route path={ERoutes.ClientDetail} element={<ClientDetail />} />
        <Route path={ERoutes.WalletBot} element={<WalletBot />} />
        <Route path={ERoutes.MerchantLocation} element={<MerchantLocation />} />
      </Route>
    </Routes>
  );
};
