import { Switch as ManSwitch, SwitchProps } from '@mantine/core';
import cn from 'clsx';
import classes from './Switch.module.scss';

export type ISwtichProps = SwitchProps & {
  className?: string;
};

const Switch = ({ className, value, ...other }: ISwtichProps) => {
  return (
    <ManSwitch
      {...other}
      className={cn(classes.wrapper, className)}
      checked={!!value}
    />
  );
};

export default Switch;
