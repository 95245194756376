import { ReactNode } from 'react';
import { Refresh } from 'tabler-icons-react';

export type EmptyStatesList = 'findNewMerchant';

export interface EmptyStateItem {
  icon: ReactNode;
  title: string;
  description: string;
}

export type EmptyStateRecord = Record<EmptyStatesList, EmptyStateItem>;

export const emptyStates: EmptyStateRecord = {
  findNewMerchant: {
    icon: <Refresh size={269} strokeWidth={2} color="#D4DFFF" />,
    title: 'Find New Merchants',
    description:
      'To view the most current list of new merchants, press Find New Merchants above.',
  },
};
