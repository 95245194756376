import { Divider } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';
import { TypeMerchant } from 'src/business/app/types/TypeMerchant';
import { Group, Stack, Title } from 'src/components';
import { NumberInput, TextInput } from 'src/components/FormFields';
import { Dropzones } from './DropZone';
interface StepOneCreateMerchantProps {
  form: UseFormReturnType<TypeMerchant>;
  nextStep: () => void;
}
export const StepOneCreateMerchant = (props: StepOneCreateMerchantProps) => {
  const { form, nextStep } = props;
  return (
    <form onSubmit={nextStep} style={{ marginTop: 0 }}>
      <Stack
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
          maxheight: 400,
          // maxWidth: 1200,
          padding: 15,
          '&:hover': {
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.dark[5]
                : theme.colors.gray[2],
          },
        })}
      >
        <Title order={5}> Creating the Merchant</Title>
        Update your details
        <Divider> </Divider>
        <Group sx={{ justifyContent: 'space-between' }}>
          <TextInput
            required
            label="Legal Name"
            placeholder="Legal Name"
            {...form.getInputProps('LegalName')}
          />
          <TextInput
            required
            label="System Name"
            placeholder="System Name"
            {...form.getInputProps('SystemName')}
          />
          <TextInput
            style={{ flexGrow: '2' }}
            required
            label="Description"
            placeholder="Description"
            {...form.getInputProps('Description')}
          />
        </Group>
        <Group>
          <TextInput
            required
            label="Website"
            placeholder="Website"
            {...form.getInputProps('Website')}
          />
          <TextInput
            required
            label="Terms & Conditions"
            placeholder="Terms & Conditions"
            {...form.getInputProps('TermsAndConditions')}
          />
          <TextInput
            required
            label="Logo Url"
            placeholder="Logo Url"
            {...form.getInputProps('LogoUrl')}
          />
          <TextInput
            required
            label="Card Image Url"
            placeholder="Card Image Url"
            {...form.getInputProps('CardImageUrl')}
          />
        </Group>
        <Group>
          <Stack sx={{ display: 'flex', flex: 1, gap: 0 }}>
            Card Holder Agreement
            <Dropzones></Dropzones>
          </Stack>
        </Group>
        <Group>
          <NumberInput
            label="Purchase Fees"
            required
            defaultValue={0}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            formatter={(value) =>
              !Number.isNaN(parseFloat(value as string))
                ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : '$ '
            }
            {...form.getInputProps('PurchaseFee')}
            hideControls
          />
          <NumberInput
            label="Revenue Share"
            required
            // formatter={(value) => `% ${value}`}
            min={0}
            max={100}
            {...form.getInputProps('RevenueShare')}
            hideControls
          />

          <NumberInput
            label="Marketing Fee"
            required
            defaultValue={0}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            formatter={(value) =>
              !Number.isNaN(parseFloat(value as string))
                ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : '$ '
            }
            {...form.getInputProps('MarketingFee')}
            hideControls
          />
          <NumberInput
            label="Minimum Discount"
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            formatter={(value) =>
              !Number.isNaN(parseFloat(value as string))
                ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : '$ '
            }
            {...form.getInputProps('MinimumDiscount')}
            hideControls
          />
        </Group>
        <Group>
          <TextInput
            label="Level 1"
            placeholder="Level 1"
            {...form.getInputProps('Level1')}
          />
          <TextInput
            label="Level 2"
            placeholder="Level 2"
            {...form.getInputProps('Level2')}
          />
          <TextInput
            label="Level 3"
            placeholder="Level 3"
            {...form.getInputProps('Level3')}
          />
          <TextInput
            label="Level 4"
            placeholder="Level 4"
            {...form.getInputProps('Level4')}
          />
        </Group>
        <Group>
          <TextInput
            label="Level 5"
            placeholder="Level 5"
            {...form.getInputProps('Level5')}
          />
          <TextInput
            label="Level 6"
            placeholder="Level 6"
            {...form.getInputProps('Level6')}
          />
          <TextInput
            label="Level 7"
            placeholder="Level 7"
            {...form.getInputProps('Level7')}
          />
        </Group>
        <Group>
          <NumberInput
            label="Minimum Card Purchase"
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            formatter={(value) =>
              !Number.isNaN(parseFloat(value as string))
                ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : '$ '
            }
            {...form.getInputProps('MinimumCardPurchase')}
            hideControls
          />
          <NumberInput
            label="Maximum Card Purchase"
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            formatter={(value) =>
              !Number.isNaN(parseFloat(value as string))
                ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : '$ '
            }
            {...form.getInputProps('MaximumCardPurchase')}
            hideControls
          />
        </Group>
      </Stack>
    </form>
  );
};
