import * as merchantActions from 'src/store/actions/merchant';
import * as merchantLocationActions from 'src/store/actions/merchantLocation';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  const merchantStore = useReducerData('merchant', 'merchantDetail', {});
  const merchantLocationStore = useReducerData(
    'merchantLocation',
    'merchantLocations',
    {}
  );
  const verifyLocationCSVStore = useReducerData(
    'merchantLocation',
    'verifyLocationCSV',
    {}
  );
  return {
    merchantDetail: merchantStore.data || {},
    merchantLocation: merchantLocationStore.data || {},
    merchantDetailLoading: merchantStore.loading || false,
    verifyLocationData: verifyLocationCSVStore.data || [],
    updateMerchantLoading: useReducerData(
      'merchant',
      'updateMerchant.loading',
      false
    ),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...merchantActions,
    ...merchantLocationActions,
  });
};
