import { Stack as ManStack, StackProps } from '@mantine/core';
import cn from 'clsx';
import classes from './Stack.module.scss';

type IProps = StackProps & {
  className?: string;
};

const Stack = ({ className, ...other }: IProps) => {
  return <ManStack {...other} className={cn(classes.wrapper, className)} />;
};
export default Stack;
