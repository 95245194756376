import { IconChevronDown, IconEye, IconTrash } from '@tabler/icons';
import cn from 'clsx';
import React from 'react';
import { ReactComponent as XLSFileSvg } from 'src/assets/icons/XLS_File.svg';
import { CSVTable, Group, Loader, Stack, Text } from 'src/components';
import classes from './AddEditMerchants.module.scss';

interface Props {
  csvView: boolean[];
  updateCsvView: (index: number, val: boolean, isRemove?: boolean) => void;
  index: number;
  fileObj: any;
  onView: () => void;
  onRemove: () => void;
  csvViewHeader: string[];
  csvViewContent: any[];
  loading?: boolean;
}

const LocationCard = ({
  csvView,
  updateCsvView,
  fileObj,
  index,
  onView,
  onRemove,
  csvViewHeader,
  csvViewContent,
  loading,
}: Props) => {
  return (
    <React.Fragment>
      {!!csvView[index] && (
        <div className={classes.closeFileDetails}>
          <span
            onClick={() => {
              updateCsvView(index, false);
            }}
          >
            Close File Details View
          </span>
          <IconChevronDown />
        </div>
      )}
      <Group
        position="apart"
        spacing={5}
        className={cn(classes.uploadedFileItem, {
          [classes.itemIsView]: !!csvView[index],
        })}
      >
        {!!loading && (
          <div className={classes.loader}>
            <Loader />
          </div>
        )}
        <Group
          spacing={16}
          align={'center'}
          style={{ minHeight: '2rem', pointerEvents: 'none' }}
        >
          <XLSFileSvg />
          <Stack style={{ gap: 0 }} className={classes.fileName}>
            <Text size={'sm'}>{fileObj.name}</Text>
            <Text>{((fileObj.size as number) / 1024).toFixed(2) + 'Kb'}</Text>
          </Stack>
        </Group>
        <Group
          spacing={16}
          className={cn(classes.fileAction, {
            [classes.fileActionIsView]: !!csvView[index],
          })}
        >
          <span className={classes.eyeIcon} onClick={onView}>
            <IconEye size={24} strokeWidth={2} color={'#5A82FD'} />
          </span>
          <div />
          <IconTrash
            size={24}
            strokeWidth={2}
            color={'#F93746'}
            onClick={onRemove}
          />
        </Group>
      </Group>
      {!!csvView[index] && (
        <div className={classes.csvPreview}>
          <CSVTable
            csvViewHeader={csvViewHeader}
            csvViewContent={csvViewContent}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default LocationCard;
