import { Table as ManTable, TableProps } from '@mantine/core';
import cn from 'clsx';
import classes from './BaseTable.module.scss';

type Props = TableProps & {
  className?: string;
};

const BaseTable = ({ className, ...other }: Props) => {
  return <ManTable {...other} className={cn(classes.wrapper, className)} />;
};

export default BaseTable;
