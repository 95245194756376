import { useMantineTheme } from '@mantine/core';
import { memo } from 'react';
import { Button, Stack, Text } from 'src/components';
import { EmptyStatesList, emptyStates } from './utils/emptyStates';

interface EmptyStateProps {
  name?: EmptyStatesList;
  onClick?: () => void;
  //   isApiDataFound: boolean;
}

export const EmptyState = memo((props: EmptyStateProps) => {
  //   const emptyStateName = props.isApiDataFound
  //     ? props.name ?? "noDataLoad"
  //     : "noDataLoad";
  const emptyStateName = 'findNewMerchant';
  const { onClick } = props;

  const { icon, title, description } = emptyStates[emptyStateName];
  const theme = useMantineTheme();

  return (
    <Stack
      spacing={0}
      justify="center"
      align="center"
      sx={{ position: 'relative', flex: '1' }}
    >
      {icon}

      <Button
        sx={{ position: 'absolute', top: '42%' }}
        size="md"
        px={60}
        onClick={onClick}
      >
        <Text weight={400} size={20}>
          {title}
        </Text>
      </Button>

      <Text
        size={20}
        weight={400}
        mt={-20}
        align="center"
        sx={{ width: '40%', color: theme.colors.secondaryBlack }}
        style={{ width: '466px' }}
      >
        {description}
      </Text>
    </Stack>
  );
});
