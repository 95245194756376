import { Plus } from 'tabler-icons-react';
import { Button, Group, Text } from 'src/components';
interface Props {
  type: string;
  footerFixed?: boolean;
  active: number;
  selectedLength: number;
  onClose: (forceClose?: boolean) => void;
}
const Topbar = ({
  type,
  footerFixed,
  active,
  selectedLength,
  onClose,
}: Props) => {
  return (
    <>
      {!footerFixed && (
        <Group position="apart">
          <Group>
            <Text size={32}>{`${
              type === 'Add' ? 'Adding New Merchants' : 'Edit Merchants'
            }`}</Text>
            <Text size={16} weight={500} pt={4} color="primaryBlack">
              {`${active} of  ${selectedLength} Merchant${
                selectedLength > 1 ? 's' : ''
              }`}
            </Text>
          </Group>
          <Button variant="subtle" onClick={onClose}>
            <Group spacing={4}>
              <Text underline> Cancel</Text>{' '}
              <Plus style={{ transform: 'rotate(45deg)' }} />
            </Group>
          </Button>
        </Group>
      )}
    </>
  );
};

export default Topbar;
