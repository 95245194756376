import { MultiSelect as ManMultiSelect, MultiSelectProps } from '@mantine/core';
import { IconCaretDown, IconX } from '@tabler/icons';
import cn from 'clsx';
import React from 'react';
import classes from './MultiSelect.module.scss';

export type IMultiSelectProps = MultiSelectProps & {
  className?: string;
};

const MultiSelect = ({ className = '', ...other }: IMultiSelectProps) => {
  const hasValue = !!other.value?.length;
  return (
    <ManMultiSelect
      searchable
      nothingFound="No data"
      rightSection={
        hasValue ? (
          <IconX
            cursor="pointer"
            color="#0E121A"
            onClick={() => {
              if (other.onChange) {
                other.onChange([]);
              }
            }}
          />
        ) : (
          <IconCaretDown />
        )
      }
      {...other}
      className={cn(
        classes.wrapper,
        {
          [classes.hasValue]: hasValue,
        },
        className
      )}
    />
  );
};

export default MultiSelect;
