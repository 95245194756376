import { Dispatch } from 'redux';
import {
  apiAddLocationCSV,
  apiGetMechantLocations,
  apiVerifyLocationCSV,
} from 'src/api';
import Types from '../types/merchantLocation';

export const syncResetLocationCSV = () => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: false,
    type: Types.SYNC_RESET_LOCATION_CSV,
  });
};
export const syncResetMerchantLocations = () => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: false,
    type: Types.SYNC_RESET_MERCHANT_LOCATIONS,
  });
};
export const getMerchantLocations =
  (id: string | number, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_MECHANT_LOCATIONS,
      asyncCall: () => {
        return apiGetMechantLocations(id);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const verifyLocationCSV =
  (data: any, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.VERIFY_LOCATION_CSV,
      asyncCall: () => {
        return apiVerifyLocationCSV(data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const addLocationCSV =
  (id: string | number, data: any, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.ADD_LOCATION_CSV,
      asyncCall: () => {
        return apiAddLocationCSV(id, data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
