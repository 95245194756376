import { useForm } from '@mantine/form';
import {
  IconApps,
  IconAward,
  IconCircleCheck,
  IconListDetails,
  IconUserCircle,
} from '@tabler/icons';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TypeMerchant } from 'src/business/app/types/TypeMerchant';
import {
  Button,
  Group,
  Stack,
  Stepper,
  StepperCompleted,
  StepperStep,
} from 'src/components';
import { Switch } from 'src/components/FormFields';
import {
  MerchantModal,
  StepOneCreateMerchant,
  StepThreeAppEnv,
  StepTwoAdditionalDetail,
} from '../merchant';
// enum AppName {
//   AllApp = 1,
//   CrayPay = 2,
//   DishDirect = 3,
//   NSLSPay = 4,
//   Partner = 5,
// }

type env = {
  allEnv: boolean;
  dev: boolean;
  stg: boolean;
  prod: boolean;
};
type AppNames = { name: string; active: boolean };
const appNames: AppNames[] = [
  { name: 'CrayPay', active: false },
  { name: 'DishDirect', active: false },
  { name: 'NSLSPay', active: false },
  { name: 'Partner', active: false },
];

const appEnvData: Record<
  string | 'CrayPay' | 'DishDirect' | 'NSLSPay' | 'Partner',
  env
> = {
  CrayPay: {
    allEnv: true,
    dev: true,
    stg: true,
    prod: true,
  },
  DishDirect: {
    allEnv: true,
    dev: true,
    stg: true,
    prod: true,
  },
  NSLSPay: {
    allEnv: true,
    dev: true,
    stg: true,
    prod: true,
  },
  Partner: {
    allEnv: true,
    dev: true,
    stg: true,
    prod: true,
  },
};

export default function CreateMerchant() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const [isPrevBack, setPrevBack] = useState(false);
  const [data, setData] = useState<AppNames[]>(appNames);
  const setChecked = (app: AppNames) => {
    const fData = data.map((d: AppNames) => {
      return {
        name: d.name,
        active:
          d.name === app.name
            ? !data.find((i) => i.name === app.name)?.active
            : d.active,
      };
    });
    setData(fData);
  };

  const isAnyAppSelected = useMemo(() => {
    return data.some((value) => !!value.active);
  }, [data]);

  const isAllAppSelected = useMemo(() => {
    return data.every((value) => !!value.active);
  }, [data]);

  const toggleAllAppSelection = useCallback(() => {
    if (isAllAppSelected) {
      const allAppsUnselected = data.map((value) => {
        return {
          ...value,
          active: false,
        };
      });
      setData(allAppsUnselected);
    } else {
      setData(appNames);
    }
  }, [data, isAllAppSelected]);
  const handleEnvCheck = useCallback(() => {
    // if (isAllAppSelected) {
    //   const allAppsUnselected = data.map((value) => {
    //     return {
    //       ...value,
    //       active: false,
    //     };
    //   });
    //   setData(allAppsUnselected);
    // } else {
    //   setData(appNames);
  }, []);

  // const [revenueShare, setRevenueShare] = useState<number | undefined>(1000000);
  const form = useForm<TypeMerchant>({
    initialValues: {
      Id: 0,
      IsActive: false,
      IsDeleted: false,
      CreatedBy: '1',
      CreatedDate: '',
      ModifiedBy: '1',
      ModifiedDate: '',
      LegalName: '',
      SystemName: '',
      Description: '',
      Website: '',
      TermsAndConditions: '',
      LogoUrl: '',
      CardImageUrl: '',
      CardholderAgreement: '',
      PurchaseFee: 0,
      RevenueShare: 0,
      MarketingFee: 0,
      MinimumDiscount: 0,
      Level1: 0,
      Level2: 0,
      Level3: 0,
      Level4: 0,
      Level5: 0,
      Level6: 0,
      Level7: 0,
      IsOnline: false,
      IsPhysical: false,
      IsVariablePurchase: false,
      MinimumCardPurchase: 0,
      MaximumCardPurchase: 0,
      AcceptsTips: false,
      IsRegional: false,
      UsageInstructions: '',
      UsageInstructionsBak: '',
      BalanceInstructions: '',
      PaymentGatewayId: 0,
      GiftCardGatewayId: 0,
      IsHtmlDescription: false,
      PurchaseInstructions: '',
      AmountPerCard: '',
      ProcessingMessage: '',
      HasBarcode: false,
      HasInventory: false,
      IsVoidable: false,
      ReceiptMessage: '',
      CssBorderCode: '',
      PaymentInstructions: '',
      AlderSku: '',
      NgcSku: '',
      AcceptedCurrency: '',
      DeepLink: '',
      IsPayLater: false,
      ProductSubCode: '',
      CertegyMerchantId: '',
      SubProductCode: '',
      ProductCode: '',
      SavingTypeId: '',
      SavingsPercentage: 0,
      IsACHEnabled: false,
    },
    validate: {
      LegalName: (value: string) =>
        !value.length ? 'Enter vallid LegalName' : null,
      SystemName: (value: string) =>
        !value.length ? 'Enter system name' : null,
      Description: (value: string) =>
        !value.length ? 'Enter Description' : null,
      Website: (value: string) => (!value.length ? 'Enter Website' : null),
      TermsAndConditions: (value: string) =>
        !value.length ? 'Enter TermsAndConditions' : null,
      LogoUrl: (value: string) => (!value.length ? 'Enter LogoUrl' : null),
      CardImageUrl: (value: string) =>
        !value.length ? 'Enter CardImageUrl' : null,
      CardholderAgreement: (value: string) =>
        !value.length ? 'Enter CardholderAgreement' : null,
      PurchaseFee: (value: number) => (!value ? 'Enter PurchaseFee' : null),
      RevenueShare: (value: number) => (!value ? 'Enter RevenueShare' : null),
      MarketingFee: (value: number) => (!value ? 'Enter MarketingFee' : null),
      MinimumDiscount: (value: number) =>
        !value ? 'Enter MinimumDiscount' : null,
      Level1: (value: number) => (!value ? 'Enter Level1' : null),
      Level2: (value: number) => (!value ? 'Enter Level2' : null),
      Level3: (value: number) => (!value ? 'Enter Level3' : null),
      Level4: (value: number) => (!value ? 'Enter Level4' : null),
      Level5: (value: number) => (!value ? 'Enter Level5' : null),
      Level6: (value: number) => (!value ? 'Enter Level6' : null),
      Level7: (value: number) => (!value ? 'Enter Level7' : null),
      MinimumCardPurchase: (value) =>
        !value ? 'Enter MinimumCardPurchase' : null,
      MaximumCardPurchase: (value) =>
        !value ? 'Enter MaximumCardPurchase' : null,
    },
  });
  const nextStep = () => {
    const temp = form.validate();
    if (temp.hasErrors) {
      // TODO : Add toast for warning
      //return;
    }

    setActive((current) => (current < 3 ? current + 1 : current));
  };
  const prevStep = () => {
    !active
      ? setPrevBack(true)
      : setActive((current) => (current > 0 ? current - 1 : current));
  };
  return (
    <Stack>
      <Stepper
        active={active}
        onStepClick={setActive}
        breakpoint="sm"
        size="xs"
        completedIcon={<IconCircleCheck />}
      >
        <StepperStep
          label="Creating the Merchant"
          icon={<IconUserCircle size={20} />}
          allowStepSelect={active > 0}
        >
          <StepOneCreateMerchant form={form} nextStep={nextStep} />
        </StepperStep>
        <StepperStep
          label="Additional Details"
          icon={<IconListDetails size={18} />}
          allowStepSelect={active > 1}
        >
          <StepTwoAdditionalDetail form={form} nextStep={nextStep} />
        </StepperStep>
        <StepperStep
          label="Apps & Environment"
          icon={<IconApps size={18} />}
          allowStepSelect={active > 2}
        >
          <StepThreeAppEnv
            appEnvData={appEnvData}
            data={data}
            handleEnvCheck={handleEnvCheck}
            isAllAppSelected={isAllAppSelected}
            isAnyAppSelected={isAnyAppSelected}
            setChecked={setChecked}
            toggleAllAppSelection={toggleAllAppSelection}
            form={form}
            nextStep={nextStep}
          />
          {/* Select Apps and Environments
            <Divider> </Divider>
            <Stack
              sx={(theme) => ({
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[8]
                    : theme.colors.gray[0],
                maxheight: 400,
                maxWidth: 1200,
                padding: 15,
                "&:hover": {
                  backgroundColor:
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[5]
                      : theme.colors.gray[2],
                },
              })}
            >
              <Text>Apps</Text>
              <Group>
                <Checkbox
                  label="All Apps"
                  checked={isAllAppSelected}
                  indeterminate={isAnyAppSelected && !isAllAppSelected}
                  onChange={() => toggleAllAppSelection()}
                />
                {data.map((M) => (
                  <Checkbox
                    label={M.name}
                    checked={M.active}
                    onChange={(event) => setChecked(M)}
                  />
                ))}
                {/*                 
                <Checkbox
                  label="CrayPay"
                  // onChange={(event) => setChecked(event.currentTarget.checked)}
                />
                <Checkbox
                  label="DashDirect"
                  // onChange={(event) => setChecked(event.currentTarget.checked)}
                />
                <Checkbox
                  label="NSLS Pay"
                  onChange={(event) => setChecked(event.currentTarget.checked)}
                />
                <Checkbox
                  label="Clients"
                  onChange={(event) => setChecked(event.currentTarget.checked)}
                />  
              </Group>
              Environments
              <Group position="center">
                {Object.entries(appEnvData)
                  .filter(([key, value]) =>
                    data
                      .filter((i) => i.active)
                      .map((m) => m.name)
                      .includes(key)
                  )
                  .map(([key, value]) => (
                    <Stack
                      id={key}
                      sx={{ border: "1px solid #60676e" }}
                      style={{ gap: 5 }}
                    >
                      <Title order={6} align="center" pt={5}>
                        {key}
                      </Title>
                      <Divider color={"#60676e"}></Divider>
                      <Stack m={5} p={5} style={{ gap: 5 }}>
                        <Checkbox
                          size="xs"
                          label="All Environments"
                          checked={value.allEnv}
                        />
                        <Checkbox
                          checked={value.dev}
                          size="xs"
                          label="Development"
                          onChange={(event) => setEnvChecked()}
                        />
                        <Checkbox
                          checked={value.stg}
                          size="xs"
                          label="Staging"
                        />
                        <Checkbox
                          checked={value.prod}
                          size="xs"
                          label="Production"
                        />
                      </Stack>
                    </Stack>
                  ))}

                {/* <Stack
                  id="DashDirect"
                  sx={{ border: "1px solid #60676e" }}
                  style={{ gap: 5 }}
                >
                  <Title order={6} align="center" pt={5}>
                    DashDirect
                  </Title>
                  <Divider color={"#60676e"}></Divider>
                  <Stack m={5} p={5} style={{ gap: 5 }}>
                    <Checkbox size="xs" label="All Environments" />
                    <Checkbox size="xs" label="Development" />
                    <Checkbox size="xs" label="Staging" />
                    <Checkbox size="xs" label="Production" />
                  </Stack>
                </Stack>
                <Stack
                  id="NSLSPay"
                  sx={{ border: "1px solid #60676e" }}
                  style={{ gap: 5 }}
                >
                  <Title order={6} align="center" pt={5}>
                    NSLS Pay
                  </Title>
                  <Divider color={"#60676e"}></Divider>
                  <Stack m={5} p={5} style={{ gap: 5 }}>
                    <Checkbox size="xs" label="All Environments" />
                    <Checkbox size="xs" label="Development" />
                    <Checkbox size="xs" label="Staging" />
                    <Checkbox size="xs" label="Production" />
                  </Stack>
                </Stack>
                <Stack
                  id="Clients"
                  sx={{ border: "1px solid #60676e" }}
                  style={{ gap: 5 }}
                >
                  <Title order={6} align="center" pt={5}>
                    Clients
                  </Title>
                  <Divider color={"#60676e"}></Divider>
                  <Stack m={5} p={5} style={{ gap: 5 }}>
                    <Checkbox size="xs" label="All Environments" />
                    <Checkbox size="xs" label="Development" />
                    <Checkbox size="xs" label="Staging" />
                    <Checkbox size="xs" label="Production" />
                  </Stack>
                </Stack>  
              </Group>
            </Stack> */}
        </StepperStep>
        <StepperStep
          label="Merchant Status"
          icon={<IconAward size={18} />}
          allowStepSelect={active > 3}
        >
          <h3>Set merchant status </h3>
          <form>
            <Switch label="Merchant is Active" onLabel="ON" offLabel="OFF" />
          </form>
        </StepperStep>
        <StepperCompleted>
          Completed, click back button to get to previous step
        </StepperCompleted>
      </Stepper>
      <MerchantModal
        isOpened={isPrevBack}
        location={null}
        setPreviousBack={setPrevBack}
      />
      <Group position="right" mt={5}>
        <Button variant="default" onClick={prevStep}>
          Back
        </Button>
        <Button onClick={nextStep}>Next step</Button>
      </Group>
    </Stack>
  );
}
