import { Radio as ManRadio, RadioGroupProps, RadioProps } from '@mantine/core';
import cn from 'clsx';
import React from 'react';
import classes from './Radio.module.scss';

type IProps = RadioProps & {
  className?: string;
};
export type IGroupProps = RadioGroupProps & {
  className?: string;
};

const RadioGroup = ({ className, ...other }: IGroupProps) => {
  return <ManRadio.Group {...other} className={cn(classes.group, className)} />;
};

const Radio = ({ className = '', ...other }: IProps) => {
  return <ManRadio {...other} className={cn(classes.wrapper, className)} />;
};

export { RadioGroup };
export default Radio;
