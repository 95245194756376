import { Field } from 'formik';
// import get from "lodash/get";
import TextInput, { ITextInputProps } from '../TextInput';

type Props = ITextInputProps & {
  name: string;
};

const FormikInputText = (props: Props) => {
  const { name, ...otherProps } = props;

  return (
    <Field name={name}>
      {(fObj: any) => {
        const {
          field, // { name, value, onChange, onBlur }
          // form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        } = fObj;
        // const { touched, errors } = form;
        // const value = field.value;
        // const errorMessage = get(errors, field.name);
        return <TextInput {...otherProps} {...field} />;
      }}
    </Field>
  );
};

export default FormikInputText;
