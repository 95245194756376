import { BoxProps, Box as ManBox } from '@mantine/core';
import cn from 'clsx';
import classes from './Box.module.scss';

type IProps = BoxProps & {
  className?: string;
};

const Box = ({ className, ...other }: IProps) => {
  return <ManBox {...other} className={cn(classes.wrapper, className)} />;
};
export default Box;
