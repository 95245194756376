import cn from 'clsx';
import { Plus } from 'tabler-icons-react';
import { Button } from 'src/components';
import classes from './CloseButton.module.scss';

const CloseButton = ({ className = '', onClick = undefined }) => {
  return (
    <Button
      onClick={onClick}
      isLink
      type="button"
      variant="outline"
      className={cn(classes.wrapper, className)}
    >
      Close <Plus style={{ transform: 'rotate(45deg)' }} />
    </Button>
  );
};

export default CloseButton;
