import {
  AppShell,
  Header as MantineHeader,
  useMantineTheme,
} from '@mantine/core';
import React from 'react';
import { Group } from 'src/components';
import logo from '../../assets/images/logo.png';
import { Login } from '../../modules/authentication';
export const LoginLayout = () => {
  const theme = useMantineTheme();
  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
          lineHeight: '5px',
        },
      }}
      header={
        <MantineHeader height={70} p="md">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Group>
              <img src={logo} alt="Ionia" width="90" />
            </Group>
          </div>
        </MantineHeader>
      }
    >
      <Login />
    </AppShell>
  );
};
