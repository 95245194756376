import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import { PaginationState } from '@tanstack/react-table';
import cn from 'clsx';
import { useContext, useState } from 'react';
import {
  ActionIcon,
  Menu,
  MenuDropdown,
  MenuItem,
  MenuTarget,
  Pagination,
  Text,
} from 'src/components';
import { PAGE_SIZES } from 'src/helper/constants';
import { TableContext } from '../context';
import classes from './TableFooter.module.scss';

interface TablePaginationProps {
  pageSize?: number;
  onPaginationChange: (val: PaginationState) => void;
  pageCount: number;
  setPagination: (val: PaginationState) => void;
  pagination: PaginationState;
}

export const CustomPagination = ({
  pageCount,
  pagination,
  setPagination,
  onPaginationChange,
  theme,
  className,
}: {
  pageCount: number;
  pagination: any;
  setPagination: any;
  onPaginationChange: any;
  theme: any;
  className?: string;
}) => {
  return (
    <Pagination
      total={pageCount || 0}
      onChange={(val: any) => {
        const newPagination = {
          ...pagination,
          pageIndex: val,
        };
        setPagination(newPagination);
        onPaginationChange(newPagination);
      }}
      siblings={1}
      boundaries={1}
      withEdges
      page={pagination.pageIndex}
      styles={{
        item: {
          paddingLeft: 0,
          paddingRight: 0,
          maxWidth: '32px',
          '&[data-active]': {
            backgroundColor: theme.colors.primaryWhite[0],
            borderWidth: '1px',
            borderColor: theme.colors.primaryBlue[3],
            color: theme.colors.primaryBlue[3],
          },
        },
      }}
      className={className}
    ></Pagination>
  );
};
export const TablePagination = ({
  pageSize,
  onPaginationChange,
  pageCount,
  setPagination,
  pagination,
}: TablePaginationProps) => {
  const { theme } = useContext(TableContext);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const updatePageSize = (cPageSize: number) => {
    const newPagination = {
      pageIndex: 1,
      pageSize: cPageSize,
    };
    setPagination(newPagination);
    onPaginationChange(newPagination);
  };
  return (
    <>
      <CustomPagination
        pageCount={pageCount}
        pagination={pagination}
        setPagination={setPagination}
        onPaginationChange={onPaginationChange}
        theme={theme}
      />
      <div className={classes.pageSizeWrapper}>
        <Menu width={94} onChange={setDropdownOpened}>
          <MenuTarget>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                cursor: 'pointer',
                padding: '2px 5px',
                border: dropdownOpened ? '0.5px solid #696A72' : '',
                background: dropdownOpened ? '#F5F7FF' : 'transparent',
                borderRadius: '2px',
              }}
            >
              <Text color="primaryBlue" size="xs">
                Per Page: {pageSize}
              </Text>
              <ActionIcon
                variant="transparent"
                color="primaryBlue"
                size={20}
                style={{ marginLeft: '4px' }}
              >
                {dropdownOpened ? <IconChevronUp /> : <IconChevronDown />}
              </ActionIcon>
            </div>
          </MenuTarget>
          <MenuDropdown className={classes.paginationSizeDropdown}>
            {PAGE_SIZES.map((pSize, index) => {
              return (
                <MenuItem
                  onClick={() => updatePageSize(pSize)}
                  key={index}
                  className={cn({ [classes.sizeActive]: pageSize === pSize })}
                >
                  {pSize}
                </MenuItem>
              );
            })}
          </MenuDropdown>
        </Menu>
      </div>
    </>
  );
};
