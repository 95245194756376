import { GroupProps, Group as ManGroup } from '@mantine/core';
import cn from 'clsx';
import classes from './Group.module.scss';

type IProps = GroupProps & {
  className?: string;
};

const Group = ({ className, ...other }: IProps) => {
  return <ManGroup {...other} className={cn(classes.wrapper, className)} />;
};
export default Group;
