import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Group, Stack, Title } from 'src/components';
import classes from './Clients.module.scss';
import { useActions, useIndexData } from './selectorData';

export const Clients = () => {
  const { getClients } = useActions();
  const { clients } = useIndexData();
  const navigate = useNavigate();
  const reloadData = () => {
    getClients();
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Stack>
        <Group>
          <Title size={32} className={classes.title}>
            Client Dashboard
          </Title>
        </Group>
        <div className={classes.mainContent}>
          <ul>
            {clients.map((client) => {
              return (
                <li key={client.Id}>
                  <span
                    onClick={() => {
                      navigate(`/clients/${client.Id}`);
                    }}
                  >
                    {client.LogoUrl ? (
                      <img src={client.LogoUrl} alt={client.Name} />
                    ) : (
                      <span className={classes.clientLogo}>{client.Name}</span>
                    )}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </Stack>
    </>
  );
};
