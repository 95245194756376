import { Dispatch } from 'redux';
import {
  apiAddClientMerchants,
  apiDeleteClientMerchants,
  apiGetClientAvailableMerchants,
  apiGetClientDetail,
  apiGetClientMerchants,
  apiGetClients,
  apiUpdateClientMerchants,
} from 'src/api';
import Types from '../types/client';

export const syncUpdateClientMerchants =
  (merchants: any[]) => (dispatch: Dispatch) => {
    dispatch({
      type: Types.SYNC_UPDATE_CLIENT_MERCHANTS,
      payload: merchants,
    });
  };

export const getClients = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_CLIENTS,
    asyncCall: () => {
      return apiGetClients();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getClientDetail =
  (id: number | string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_CLIENT_DETAIL,
      asyncCall: () => {
        return apiGetClientDetail(id);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getClientMerchants =
  (id: number | string, filters: any, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_CLIENT_MERCHANTS,
      asyncCall: () => {
        return apiGetClientMerchants(
          id,
          filters?.pageNumber || 1,
          filters?.pageSize || 20
        );
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getClientAvailableMerchants =
  (id: number | string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_CLIENT_AVAILABLE_MERCHANTS,
      asyncCall: () => {
        return apiGetClientAvailableMerchants(id, 1, 1000);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const updateClientMerchants =
  (id: number | string, data: any[], cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.UPDATE_CLIENT_MERCHANTS,
      asyncCall: () => {
        return apiUpdateClientMerchants(id, data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const deleteClientMerchants =
  (id: number | string, data: any[], cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.DELETE_CLIENT_MERCHANTS,
      asyncCall: () => {
        return apiDeleteClientMerchants(id, data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const addClientMerchants =
  (id: number | string, data: any[], cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.ADD_CLIENT_MERCHANTS,
      asyncCall: () => {
        return apiAddClientMerchants(id, data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
