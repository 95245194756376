import { useReducerData } from 'src/store/hooks';

interface IMeta {
  Id: number | string;
  Name: string;
}

const useOptions = () => {
  const merchantCategories = useReducerData(
    'merchant',
    'merchantCategories.data',
    []
  );
  const merchantTags = useReducerData('merchant', 'merchantTags.data', []);
  const categoryOptions = merchantCategories.map((m: IMeta) => ({
    value: m.Id,
    label: m.Name,
  }));
  const tagOptions = merchantTags.map((m: IMeta) => ({
    value: m.Id,
    label: m.Name,
  }));
  return {
    categoryOptions,
    tagOptions,
  };
};

export default useOptions;
