import { Select as ManSelect, SelectProps } from '@mantine/core';
import { IconCaretDown } from '@tabler/icons';
import cn from 'clsx';
import classes from './Select.module.scss';

type IProps = SelectProps & {
  className?: string;
};

const Select = ({ className = '', ...other }: IProps) => {
  return (
    <ManSelect
      searchable
      {...other}
      rightSection={<IconCaretDown />}
      className={cn(classes.wrapper, className)}
    />
  );
};

export default Select;
