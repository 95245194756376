import { Table } from '@mantine/core';
import { IconArrowUp, IconSearch } from '@tabler/icons';
import { rankItem } from '@tanstack/match-sorter-utils';
import {
  FilterFn,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TypeMerchant } from 'src/business/app/types/TypeMerchant';
import { Box, Button, Group, Pagination, Stack, Title } from 'src/components';
import { Input, MultiSelect } from 'src/components/FormFields';
import { DataMerchants } from '../../temp/GetMerchants';
// import CreateMerchant from "./CreateMerchant";
import { getColumnHeader } from './tableHeader';

export const Merchants = () => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const data: any[] = DataMerchants.Data.Items;
  const navigate = useNavigate();

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
      itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  const onAddLocation = (merchantId: string) => {
    navigate(`/merchantLocation/${merchantId}`);
  };
  const table = useReactTable<TypeMerchant>({
    data: data,
    columns: getColumnHeader(onAddLocation),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
    filterFns: {
      fuzzy: fuzzyFilter,
    },

    globalFilterFn: fuzzyFilter,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activePage, setActivePage] = useState(0);

  const goHome = () => {
    navigate('/createmerchant');
  };

  return (
    <Stack>
      <Stack>
        <Group position="apart">
          <Title order={4}>Merchants </Title>
          <Button onClick={goHome}>Create Merchant</Button>
        </Group>
        <Group position="apart">
          <Box>
            <MultiSelect
              data={['All', '10', '20', '30']}
              // label="Your favorite frameworks/libraries"
              placeholder="Filer"
              searchable
              nothingFound="Nothing found"
            />
          </Box>
          <Group>
            <Input
              icon={<IconSearch />}
              placeholder="Search by Name, Phone or email"
            />
            <Button>Search</Button>
          </Group>
        </Group>
      </Stack>

      <Stack>
        <Stack sx={{ minHeight: 200 }}>
          <Table striped highlightOnHover>
            <thead>
              <tr>
                {table.getFlatHeaders().map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                        style={{ display: 'flex' }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {!!header.column.getIsSorted() && (
                          <IconArrowUp
                            width={16}
                            height={16}
                            style={{
                              transform:
                                header.column.getIsSorted() === 'desc'
                                  ? 'rotate(180deg)'
                                  : 'rotate(0deg)',
                              transition: 'transform 0.3s ease-in-out',
                            }}
                          />
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {/* )} */}
          {/* <pre>{JSON.stringify(table.getState().pagination, null, 2)}</pre> */}
        </Stack>

        {data ? (
          <Pagination
            color={'#0A4ABF'}
            style={{ paddingTop: 10 }}
            styles={(theme) => ({
              item: {
                '&[data-active]': {
                  backgroundColor: theme.colors.blue[3],
                },
              },
            })}
            align={'end'}
            position={'right'}
            page={activePage}
            // onChange={(page: number) => {
            //   table.setPageIndex(page);
            //   setActivePage(page);
            // }}
            total={DataMerchants.Data.TotalCount}
            withEdges
            withControls
          />
        ) : null}
      </Stack>
    </Stack>
  );
};
