import { ColumnDef, flexRender } from '@tanstack/react-table';
import cn from 'clsx';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useContext } from 'react';
import { TableContext } from '../context';
import classes from './TableBody.module.scss';

interface IProps {
  onRowClick?: (row?: any) => void;
  inlineEdit?: any;
  onEditChange?: any;
  columns: ColumnDef<any>[];
  selectedColor?: boolean;
}

export const TableBody = ({
  onRowClick,
  inlineEdit,
  columns,
  selectedColor,
}: IProps) => {
  const { table, theme } = useContext(TableContext);
  const editRows = inlineEdit?.rows || [];
  return (
    <tbody
      style={{ backgroundColor: theme.colors.primaryWhite[0] }}
      className={cn(classes.tableBody, {
        [classes.selectedColor]: selectedColor,
      })}
    >
      {table.getRowModel().rows.map((row: any) => {
        const realId = get(row, 'original.Id');
        const editObj = find(editRows, { Id: realId }) || {};
        const isEdit = !isEmpty(editObj);
        const onValue = (val: any, name: string) => {
          if (inlineEdit.onEditChange)
            inlineEdit.onEditChange(editObj, val, name);
        };
        return (
          <tr
            key={row.id}
            onClick={() => {
              if (onRowClick) onRowClick(row);
            }}
            className={cn({
              [classes.rowCursor]: !!onRowClick,
              [classes.isSelected]: row.getIsSelected(),
            })}
          >
            {row.getVisibleCells().map((cell: any) => {
              const columnId = cell.column?.id;
              const foundColumn = find(columns, { accessorKey: columnId });
              const renderEdit = get(foundColumn, 'renderEdit');
              return (
                <td key={cell.id}>
                  {isEdit && !!renderEdit
                    ? renderEdit(editObj[columnId], columnId, onValue)
                    : flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};
