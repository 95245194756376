import { ThemeIcon as ManThemeIcon, ThemeIconProps } from '@mantine/core';
import cn from 'clsx';
import classes from './ThemeIcon.module.scss';

type IProps = ThemeIconProps & {
  className?: string;
};

const ThemeIcon = ({ className, ...other }: IProps) => {
  return <ManThemeIcon {...other} className={cn(classes.wrapper, className)} />;
};
export default ThemeIcon;
