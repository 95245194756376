import { TypeMerchant } from 'src/business/app/types/TypeMerchant';
import { Request } from './request';

// GET
export const apiGetAllMerchants = async (
  pageNumber: number,
  pageSize: number,
  filters = {}
) => {
  return Request.call({
    url: `/admin/merchant/${pageNumber}/${pageSize}`,
    method: 'GET',
    params: {
      ...filters,
    },
  });
};
export const apiGetNewMerchants = async (filters = {}) => {
  return Request.call({
    url: `/admin/merchant/sync`,
    method: 'GET',
    params: {
      ...filters,
    },
  });
};
export const apiGetMerchant = async (id: string | number) => {
  return Request.call({
    url: `/admin/merchant/${id}`,
    method: 'GET',
  });
};
export const apiGetMerchantCategories = async () => {
  return Request.call({
    url: `/admin/merchant/Categories`,
    method: 'GET',
  });
};
export const apiGetMerchantTags = async () => {
  return Request.call({
    url: `/admin/merchant/Tags`,
    method: 'GET',
  });
};
export const apiGetUncofirmMechants = async () => {
  return Request.call({
    url: `/admin/merchant/GetUnconfirmMerchants`,
    method: 'GET',
  });
};

// POST
export const apiAddMerchant = async (data: TypeMerchant[]) => {
  return Request.call({
    url: `/admin/merchant`,
    method: 'POST',
    data,
  });
};
export const apiUpdateMerchant = async (
  id: number | string,
  data: TypeMerchant
) => {
  return Request.call({
    url: `/admin/merchant/${id}`,
    method: 'PUT',
    data,
  });
};
export const apiConfirmMechants = async (ids: number[] | string[]) => {
  return Request.call({
    url: `/admin/merchant/ConfirmMerchant`,
    method: 'POST',
    data: ids,
  });
};
