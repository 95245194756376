import { Dispatch } from 'redux';
import {
  apiAddMerchant,
  apiConfirmMechants,
  apiGetAllMerchants,
  apiGetMerchant,
  apiGetMerchantCategories,
  apiGetMerchantTags,
  apiGetNewMerchants,
  apiGetUncofirmMechants,
  apiUpdateMerchant,
} from 'src/api';
import { TypeMerchant } from 'src/business/app/types/TypeMerchant';
import Types from '../types/merchant';

interface IFilters {
  pageNumber: number;
  pageSize: number;
}
interface IOptionFilters {
  searchText?: string;
}

export const syncUpdateMerchantDetail =
  (response: any) => (dispatch: Dispatch) => {
    dispatch({
      type: Types.SYNC_UPDATE_MERCHANT,
      payload: response,
    });
  };

export const getAllMerchants =
  (filters: IFilters & IOptionFilters, cb?: any) => (dispatch: Dispatch) => {
    const { pageNumber, pageSize, ...other } = filters;
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_ALL_MERCHANTS,
      asyncCall: () => {
        return apiGetAllMerchants(pageNumber, pageSize, other);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getNewMerchants =
  (filters: IOptionFilters, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_NEW_MERCHANTS,
      asyncCall: () => {
        return apiGetNewMerchants(filters);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getMerchant =
  (id: string | number, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_MERCHANT,
      asyncCall: () => {
        return apiGetMerchant(id);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getMerchantCategories = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_MERCHANT_CATEGORIES,
    asyncCall: () => {
      return apiGetMerchantCategories();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getMerchantTags = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_MERCHANT_TAGS,
    asyncCall: () => {
      return apiGetMerchantTags();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getUnconfirmMerchants = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_UNCONFIRM_MERCHANTS,
    asyncCall: () => {
      return apiGetUncofirmMechants();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const addMerchant =
  (data: TypeMerchant[], cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.ADD_MERCHANT,
      asyncCall: () => {
        return apiAddMerchant(data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const updateMerchant =
  (id: number | string, data: TypeMerchant, cb?: any) =>
  (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.UPDATE_MERCHANT,
      asyncCall: () => {
        return apiUpdateMerchant(id, data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const confirmMerchants =
  (ids: number[] | string[], cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.CONFIRM_MERCHANTS,
      asyncCall: () => {
        return apiConfirmMechants(ids);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
