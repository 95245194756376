import cn from 'clsx';
import { Group } from 'src/components';
import classes from './FixedFooter.module.scss';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const FixedFooter = ({ children, className }: IProps) => {
  return (
    <Group
      position="apart"
      py={'xs'}
      px={'sm'}
      sx={{
        backgroundColor: 'white',
        position: 'fixed',
        left: '300px',
        right: 0,
        gap: '5px',
        bottom: 0,
        boxShadow: '1px 3px 1px #888, 0px 0px 6px #888',
        '@media (max-width: 1200px)': {
          left: '200px',
        },
        zIndex: 11,
      }}
      className={cn(classes.wrapper, className)}
    >
      {children}
    </Group>
  );
};
export default FixedFooter;
