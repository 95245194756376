import { BadgeProps, Badge as ManBadge } from '@mantine/core';
import cn from 'clsx';
import classes from './Badge.module.scss';

type IProps = BadgeProps & {
  className?: string;
};

const Badge = ({ className, ...other }: IProps) => {
  return <ManBadge {...other} className={cn(classes.wrapper, className)} />;
};
export default Badge;
