import React from 'react';
import { TypeMerchant } from 'src/business/app/types/TypeMerchant';
import { Chip, Group, Text } from 'src/components';
import { FormikSwitch } from 'src/components/FormFields';
import { getGateway } from './AddEditMerchants';
import classes from './AddEditMerchants.module.scss';

interface Props {
  currentMerchant: TypeMerchant;
}

const Header = ({ currentMerchant }: Props) => {
  return (
    <Group position="apart">
      <div className={classes.merchantTitleWrapper}>
        <Text size={22} weight={700}>
          {currentMerchant?.LegalName}
        </Text>
        <Chip size="xs" checked={false}>
          {getGateway(currentMerchant?.GiftCardGatewayId)}
        </Chip>
      </div>
      <div className={classes.isActiveWrapper}>
        <FormikSwitch
          onLabel="ON"
          offLabel="OFF"
          size="lg"
          name="IsActive"
          color="primaryBlue"
          label="Merchant is Active"
          labelPosition="left"
        />
      </div>
    </Group>
  );
};

export default Header;
