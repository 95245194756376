import { createTypes } from './createTypes';

export default createTypes(
  'SYNC_UPDATE_CLIENT_MERCHANTS',
  'GET_CLIENTS',
  'GET_CLIENT_DETAIL',
  'GET_CLIENT_MERCHANTS',
  'GET_CLIENT_AVAILABLE_MERCHANTS',
  'UPDATE_CLIENT_MERCHANTS',
  'ADD_CLIENT_MERCHANTS',
  'DELETE_CLIENT_MERCHANTS'
);
