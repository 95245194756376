export const getRowValue = (
  val: string | number | unknown,
  defaultValue = '-'
) => {
  if (!val) return defaultValue;
  return val;
};
export const getPriceValue = (
  val: string | number | unknown,
  defaultValue = '-'
) => {
  if (typeof val !== 'number' && typeof val !== 'string')
    return val !== 0 && !val ? defaultValue : val;
  return !!val || val === 0 ? `$${val}` : '-';
};
