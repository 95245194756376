import { Divider } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { TypeMerchant } from 'src/business/app/types/TypeMerchant';
import { Group, Stack, Text, Title } from 'src/components';
import { Checkbox } from 'src/components/FormFields';
type AppNames = { name: string; active: boolean };

type env = {
  allEnv: boolean;
  dev: boolean;
  stg: boolean;
  prod: boolean;
};
interface StepThreeAppEnvProps {
  form: UseFormReturnType<TypeMerchant>;
  isAllAppSelected: boolean;
  isAnyAppSelected: boolean;
  data: AppNames[];
  appEnvData: Record<string, env>;
  nextStep: () => void;
  toggleAllAppSelection: () => void;
  setChecked: (app: AppNames) => void;
  handleEnvCheck: () => void;
}
export const StepThreeAppEnv = (props: StepThreeAppEnvProps) => {
  const {
    nextStep,
    isAllAppSelected,
    isAnyAppSelected,
    toggleAllAppSelection,
    data,
    setChecked,
    appEnvData,
    handleEnvCheck,
  } = props;
  return (
    <form onSubmit={nextStep} style={{ marginTop: 0 }}>
      <Stack
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
          maxheight: 400,
          // maxWidth: 1200,
          padding: 15,
          '&:hover': {
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.dark[5]
                : theme.colors.gray[2],
          },
        })}
      >
        <Title order={5}>Select Apps and Environments </Title>
        <Divider> </Divider>
        <Text>Apps</Text>
        <Group>
          <Checkbox
            label="All Apps"
            checked={isAllAppSelected}
            indeterminate={isAnyAppSelected}
            onChange={() => toggleAllAppSelection()}
          />
          {data.map((app) => (
            <Checkbox
              key={app.name}
              label={app.name}
              checked={app.active}
              onChange={() => setChecked(app)}
            />
          ))}
        </Group>
        Environments
        <Group position="left">
          {Object.entries(appEnvData)
            .filter(([key]) =>
              data
                .filter((i) => i.active)
                .map((app) => app.name)
                .includes(key)
            )
            .map(([key]) => (
              <Stack
                id={key}
                sx={{ border: '1px solid #60676e' }}
                style={{ gap: 5 }}
                key={key}
              >
                <Title order={6} align="center" pt={5}>
                  {key}
                </Title>
                <Divider color={'#60676e'}></Divider>
                <Stack m={5} p={5} style={{ gap: 5 }}>
                  <Checkbox
                    size="xs"
                    label="All Environments"
                    onChange={handleEnvCheck}
                  />
                  <Checkbox size="xs" label="Development" />
                  <Checkbox size="xs" label="Staging" />
                  <Checkbox size="xs" label="Production" />
                </Stack>
              </Stack>
            ))}
        </Group>
      </Stack>
    </form>
  );
};
