import { ChipProps, Chip as ManChip } from '@mantine/core';
import cn from 'clsx';
import classes from './Chip.module.scss';

type IProps = ChipProps & {
  className?: string;
};

const Chip = ({ className, ...other }: IProps) => {
  return (
    <ManChip checked {...other} className={cn(classes.wrapper, className)} />
  );
};
export default Chip;
