import { AvatarProps, Avatar as ManAvatar } from '@mantine/core';
import cn from 'clsx';
import classes from './Avatar.module.scss';

type IProps = AvatarProps & {
  className?: string;
};

const Avatar = ({ className, ...other }: IProps) => {
  return <ManAvatar {...other} className={cn(classes.wrapper, className)} />;
};
export default Avatar;
