import { AnyAction } from 'redux';
import { defaultArray, defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/wallet';

const initialState = {
  apiServer: 'prod',
  wallet: {
    ...defaultObj,
  },
  balances: {
    ...defaultObj,
  },
  invoices: {
    ...defaultArray,
  },
};

const WalletReducer = (
  state = initialState,
  action: AnyAction
): typeof initialState => {
  const { type, payload } = action;

  switch (type) {
    case Types.SYNC_UPDATE_API_PREFIX: {
      return {
        ...state,
        apiServer: payload.apiServer,
      };
    }
    // GET
    case Types.GET_WALLET:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          wallet: {
            ...prevState.wallet,
            loading: true,
            data: {
              ...(prevState.wallet?.data || {}),
              balances: [],
            },
          },
        }),
        success: (prevState) => ({
          ...prevState,
          wallet: {
            data: payload || {},
            loading: false,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          wallet: {
            data: {},
            error: payload,
            loading: false,
          },
        }),
      });
    case Types.GET_BALANCES:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          balances: {
            ...prevState.balances,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          balances: {
            data: payload || {},
            loading: false,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          balances: {
            data: {},
            error: payload,
            loading: false,
          },
        }),
      });
    case Types.GET_INVOICES:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          invoices: {
            ...prevState.invoices,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          invoices: {
            data: payload || [],
            loading: false,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          invoices: {
            data: [],
            error: payload,
            loading: false,
          },
        }),
      });
    default:
      return state;
  }
};

export default WalletReducer;
