import get from 'lodash/get';

export const PAGE_SIZES = [20, 50, 100];
export const STATUS_MAPPING = {
  paid: 'Paid',
  unpaid: 'Pending',
  cancelled: 'Cancelled',
} as Record<string, unknown>;
export const WALLET_PASSWORD = 'Ionia@2022!';
export const ERoutes = {
  Home: '/',
  Login: '/login',
  User: '/user',
  wizard: '/wizard',
  OpenIssues: '/open-issues',
  Discussions: '/discussions',
  Merchants: '/merchants',
  MerchantDetail: '/merchant/:id',
  AllMerchants: '/all-merchants',
  NewMerchants: '/new-merchants',
  Clients: '/clients',
  ClientDetail: '/clients/:id',
  WalletBot: '/wallet-bot',
  CreateMerchant: '/createmerchant',
  MerchantLocation: '/merchantlocation/:merchantId',
};
export const ROUTES = Object.keys(ERoutes).map((key) => ({
  path: get(ERoutes, key) as string,
}));
