import { Stepper as ManStepper, StepperProps } from '@mantine/core';
import cn from 'clsx';
import classes from './Stepper.module.scss';

type IProps = StepperProps & {
  className?: string;
};

const StepperStep = ManStepper.Step;
const StepperCompleted = ManStepper.Completed;

const Stepper = ({ className, ...other }: IProps) => {
  return <ManStepper {...other} className={cn(classes.wrapper, className)} />;
};

export { StepperStep, StepperCompleted };
export default Stepper;
