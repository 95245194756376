import * as clientActions from 'src/store/actions/client';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  const clientStore = useReducerData('client', 'clients', {});
  return {
    clients: clientStore.data || [],
    clientsLoading: clientStore.loading,
  };
};

export const useActions = () => {
  return useStoreActions({
    ...clientActions,
  });
};
