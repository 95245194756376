import { AnyAction } from 'redux';
import { defaultArray, defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/merchant';

const initialState = {
  allMerchants: {
    ...defaultObj,
  },
  newMerchants: {
    ...defaultArray,
  },
  merchantDetail: {
    ...defaultObj,
  },
  merchantCategories: {
    ...defaultArray,
  },
  merchantTags: {
    ...defaultArray,
  },
  unconfirmMerchants: {
    ...defaultArray,
  },
  addMerchant: {
    ...defaultObj,
  },
  updateMerchant: {
    ...defaultObj,
  },
  confirmMerchants: {
    ...defaultObj,
  },
};

const MerchantReducer = (
  state = initialState,
  action: AnyAction
): typeof initialState => {
  const { type, payload } = action;

  switch (type) {
    // SYNC
    case Types.SYNC_UPDATE_MERCHANT: {
      return {
        ...state,
        merchantDetail: {
          ...state.merchantDetail,
          data: {
            ...state.merchantDetail.data,
            ...(payload || {}),
          },
        },
      };
    }
    // GET
    case Types.GET_ALL_MERCHANTS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          allMerchants: {
            ...prevState.allMerchants,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          allMerchants: {
            data: payload?.Data || {},
            loading: false,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          allMerchants: {
            data: {},
            error: payload,
            loading: false,
          },
        }),
      });
    case Types.GET_NEW_MERCHANTS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          newMerchants: {
            data: [],
            error: '',
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          newMerchants: {
            data: payload || [],
            loading: false,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          newMerchants: {
            data: [],
            error: payload,
            loading: false,
          },
        }),
      });
    case Types.GET_MERCHANT:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          merchantDetail: {
            data: {},
            error: '',
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          merchantDetail: {
            data: payload?.Data || {},
            loading: false,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          merchantDetail: {
            data: {},
            error: payload,
            loading: false,
          },
        }),
      });
    case Types.GET_MERCHANT_CATEGORIES:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          merchantCategories: {
            ...prevState.merchantCategories,
            loading: true,
          },
        }),
        success: (prevState) => {
          return {
            ...prevState,
            merchantCategories: {
              data: payload?.Data || [],
              loading: false,
              error: '',
            },
          };
        },
        failure: (prevState) => ({
          ...prevState,
          merchantCategories: {
            data: [],
            error: payload,
            loading: false,
          },
        }),
      });
    case Types.GET_MERCHANT_TAGS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          merchantTags: {
            ...prevState.merchantTags,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          merchantTags: {
            data: payload?.Data || [],
            loading: false,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          merchantTags: {
            data: [],
            error: payload,
            loading: false,
          },
        }),
      });
    case Types.GET_UNCONFIRM_MERCHANTS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          unconfirmMerchants: {
            ...prevState.unconfirmMerchants,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          unconfirmMerchants: {
            data: payload || [],
            loading: false,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          unconfirmMerchants: {
            data: [],
            error: payload,
            loading: false,
          },
        }),
      });
    // POST
    case Types.ADD_MERCHANT:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          addMerchant: {
            ...prevState.addMerchant,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          addMerchant: {
            data: payload?.Data || {},
            loading: false,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          addMerchant: {
            data: {},
            error: payload,
            loading: false,
          },
        }),
      });
    case Types.UPDATE_MERCHANT:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          updateMerchant: {
            ...prevState.updateMerchant,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          updateMerchant: {
            data: payload?.Data || {},
            loading: false,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          updateMerchant: {
            data: {},
            error: payload,
            loading: false,
          },
        }),
      });
    case Types.CONFIRM_MERCHANTS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          confirmMerchants: {
            ...prevState.confirmMerchants,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          confirmMerchants: {
            data: payload?.Data || {},
            loading: false,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          confirmMerchants: {
            data: {},
            error: payload,
            loading: false,
          },
        }),
      });
    default:
      return state;
  }
};

export default MerchantReducer;
