import {
  LoadingOverlayProps,
  LoadingOverlay as ManLoadingOverlay,
} from '@mantine/core';
import cn from 'clsx';
import React from 'react';
import classes from './LoadingOverlay.module.scss';

type IProps = LoadingOverlayProps & {
  className?: string;
};

const LoadingOverlay = ({ className = '', ...other }: IProps) => {
  return (
    <ManLoadingOverlay
      overlayBlur={1}
      {...other}
      className={cn(classes.wrapper, className)}
    />
  );
};

export default LoadingOverlay;
