import { AnyAction } from 'redux';
import { handleData } from '../middlewares/handleData';
import Types from '../types/auth';

const initialState = {
  login: {
    loading: false,
    data: {},
    error: '',
  },
};

const AuthReducer = (
  state = initialState,
  action: AnyAction
): typeof initialState => {
  const { type, payload } = action;

  switch (type) {
    case Types.LOGIN:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          login: {
            ...prevState.login,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          login: {
            data: payload,
            loading: false,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          login: {
            data: {},
            error: payload,
            loading: false,
          },
        }),
      });
    default:
      return state;
  }
};

export default AuthReducer;
