import { Divider } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { TypeMerchant } from 'src/business/app/types/TypeMerchant';
import { Group, Stack, Title } from 'src/components';
import { Select, Switch } from 'src/components/FormFields';
const merchantType: string[] = [
  'Merchant is Physical',
  'Merchant is Online',
  'Merchant is Regional',
  'Merchant Accepts Tips',
  'Merchant is Pay Later',
];
interface StepTwoAdditionalDetailProps {
  form: UseFormReturnType<TypeMerchant>;

  nextStep: () => void;
}
export const StepTwoAdditionalDetail = (
  props: StepTwoAdditionalDetailProps
) => {
  const { nextStep } = props;
  return (
    <form onSubmit={nextStep} style={{ marginTop: 0 }}>
      <Stack
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
          maxheight: 400,
          // maxWidth: 1200,
          padding: 15,
          '&:hover': {
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.dark[5]
                : theme.colors.gray[2],
          },
        })}
      >
        <Title order={5}>Assign Categories & Tags </Title>
        Update your Details
        <Divider> </Divider>
        <Group>
          <Select
            label="Categories"
            placeholder="Categories"
            searchable
            nothingFound="No options"
            data={['React', 'Angular', 'Svelte', 'Vue']}
          />
          <Select
            label="Tags"
            placeholder="Tags"
            searchable
            nothingFound="No options"
            data={['React', 'Angular', 'Svelte', 'Vue']}
          />
        </Group>
        <Group>
          {merchantType.map((m) => (
            <Switch label={m} onLabel="Yes" key={m} offLabel="No" />
          ))}
        </Group>
      </Stack>
    </form>
  );
};
