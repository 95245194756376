import { Divider } from '@mantine/core';
import { IconCircleMinus, IconEdit, IconEye } from '@tabler/icons';
import { useState } from 'react';
import {
  ActionIcon,
  Button,
  Card,
  Group,
  Stack,
  Text,
  Title,
} from 'src/components';
import { Checkbox } from 'src/components/FormFields';
import { Dropzones } from '../merchant/DropZone';
import { MerchantModal } from '../merchant/MerchantModal';

export const MerchantLocation = () => {
  const [isPrevBack, setPrevBack] = useState(false);
  return (
    <Stack>
      <Stack>
        <Group position="apart">
          <Title order={4}>Add Merchant Location </Title>
        </Group>
        <form style={{ marginTop: 0 }}>
          {/* <Card withBorder shadow="sm" radius="md">
                <Card.Section withBorder inheritPadding py="xs">
        <Group position="apart">
          <Text weight={500}>Upload Merchant Location</Text>
          </Group>
          </Card.Section>
          <Card.Section mt="sm">
          <Dropzones></Dropzones>
      </Card.Section>
                </Card> */}
          {/* <Box sx={{ maxWidth: 1500 }} mx="auto"> */}
          <Stack
            sx={(theme) => ({
              backgroundColor:
                theme.colorScheme === 'dark'
                  ? theme.colors.dark[8]
                  : theme.colors.gray[0],
              maxheight: 400,
              maxWidth: 1200,
              padding: 5,
              gap: 5,
              '&:hover': {
                backgroundColor:
                  theme.colorScheme === 'dark'
                    ? theme.colors.dark[5]
                    : theme.colors.gray[2],
              },
            })}
          >
            <Stack sx={{ display: 'flex', flex: 1, gap: 2 }}>
              Upload merchant location
              <Dropzones></Dropzones>
            </Stack>
            <Stack>
              <Card withBorder>
                <Group position="apart">
                  <Group>
                    <Checkbox></Checkbox>
                    <Divider orientation="vertical" />
                    <Text>File1.txt</Text>
                    <Divider orientation="vertical" />
                  </Group>
                  <Group>
                    <ActionIcon>
                      <IconEye></IconEye>
                    </ActionIcon>
                    <Divider orientation="vertical" />
                    <ActionIcon>
                      <IconCircleMinus></IconCircleMinus>
                    </ActionIcon>
                    <ActionIcon>
                      <IconEdit></IconEdit>
                    </ActionIcon>
                  </Group>
                </Group>
              </Card>
              <Card withBorder>
                <Group position="apart">
                  <Group>
                    <Checkbox></Checkbox>
                    <Divider orientation="vertical" />
                    <Text>File1.txt</Text>
                    <Divider orientation="vertical" />
                  </Group>
                  <Group>
                    <ActionIcon>
                      <IconEye></IconEye>
                    </ActionIcon>
                    <Divider orientation="vertical" />
                    <ActionIcon>
                      <IconCircleMinus></IconCircleMinus>
                    </ActionIcon>
                    <ActionIcon>
                      <IconEdit></IconEdit>
                    </ActionIcon>
                  </Group>
                </Group>
              </Card>
            </Stack>
          </Stack>

          <MerchantModal
            isOpened={isPrevBack}
            location={'location'}
            setPreviousBack={setPrevBack}
          />
          <Group position="right" mt={5}>
            <Button variant="default" onClick={() => setPrevBack(true)}>
              Back
            </Button>
            {/* <Button onClick={nextStep}>Next step</Button> */}
          </Group>
        </form>
      </Stack>
    </Stack>
  );
};
