import { showNotification } from '@mantine/notifications';

export const actionCb =
  (cb?: (res?: any) => void, errCb?: (err?: any) => void) =>
  (res: any, err: any) => {
    if (res) {
      if (cb) cb(res);
    } else {
      if (errCb) errCb(err);
      showNotification({
        title: err?.data?.ErrorMessage || 'Something went wrong!',
        message: '',
        color: 'red',
      });
    }
  };
