import { GridProps, Grid as ManGrid } from '@mantine/core';
import cn from 'clsx';
import classes from './Grid.module.scss';

type IProps = GridProps & {
  className?: string;
};
const GridCol = ManGrid.Col;

const Grid = ({ className = '', ...other }: IProps) => {
  return <ManGrid {...other} className={cn(classes.wrapper, className)} />;
};

export { GridCol };
export default Grid;
