import { Divider, ScrollArea, useMantineTheme } from '@mantine/core';
import { IconCircleCheck } from '@tabler/icons';
import { Form, Formik } from 'formik';
import get from 'lodash/get';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus } from 'tabler-icons-react';
import * as Yup from 'yup';
import {
  GiftCardGateway,
  GiftCardGateways,
} from 'src/business/app/constants/merchant.constant';
import { TypeMerchant } from 'src/business/app/types/TypeMerchant';
import { Button, Group, Paper, Stack, Text } from 'src/components';
import { Confirm } from 'src/components/common/ModelDialog';
import { ERoutes } from 'src/helper/constants';
import { applySearch } from 'src/utils';
import CategoryTag from './CategoryTag';
import DetailSection from './DetailSection';
import Footer from './Footer';
import Header from './Header';
import Topbar from './Topbar';
import { useIndexData } from './selectorData';
import {
  editTransformFormData,
  getInitialValues,
  transformFormData,
} from './utils';

interface Other {
  index?: number;
  isSaveList?: boolean;
  values?: any;
}
interface AddMerchantProps {
  selectedMerchant: TypeMerchant[];
  type: 'Add' | 'Edit';
  setIsSuccess: (value: boolean) => void;
  setOpened: (value: boolean) => void;
  onClose: (forceClose?: boolean) => void;
  onSubmit: (payload: any, cb?: any, other?: Other) => void;
  footerFixed?: boolean;
  onBack?: (isDirty?: boolean) => void;
  notScroll?: boolean;
  onFormChange?: (val: boolean) => void;
  onStepCb?: (active: number) => void;
}

export const getGateway = (val: GiftCardGateway) => {
  return GiftCardGateways[val];
};

export const AddEditMerchants = ({
  selectedMerchant,
  onClose,
  setIsSuccess,
  setOpened,
  type,
  onSubmit,
  footerFixed,
  onBack,
  notScroll,
  onFormChange,
  onStepCb,
}: AddMerchantProps) => {
  const { verifyLocationLoading, mechantLocationData } = useIndexData();
  // validation schedule defined
  const schema = Yup.object().shape({
    LegalName: Yup.string().required('This field is required!'),
    MinimumCardPurchase: Yup.mixed().required('This field is required!'),
    MaximumCardPurchase: Yup.mixed().required('This field is required!'),
    RevenueShare: Yup.mixed().required('This field is required!'),
    Categories: Yup.mixed().required('This field is required!'),
    Tags: Yup.mixed().required('This field is required!'),
    LocationFiles: Yup.mixed()
      .nullable()
      .when(['IsPhysical', 'currentLocationData', 'currentType'], {
        is: (IsPhysical: boolean, currentLocationData: any) =>
          IsPhysical && !currentLocationData?.Items?.length,
        then: Yup.mixed().required(),
      }),
  });

  const selectedLength = selectedMerchant.length;
  const [active, setActive] = useState(1);
  const [isSaveSuccess, setIsSaveSuccess] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const viewport = useRef<HTMLDivElement>(null);
  const formRef = useRef(null) as any;
  const navigate = useNavigate();
  const nextStep = () => {
    setTimeout(() => {
      viewport.current?.scrollTo({ top: 0, behavior: 'smooth' });
    });
    if (active === selectedLength) {
      setIsSaveSuccess(true);
    } else {
      const newActive = active < selectedLength ? active + 1 : active;
      setActive(newActive);
      if (onStepCb) onStepCb(newActive);
    }
  };
  const prevStep = () => {
    setTimeout(() => {
      viewport.current?.scrollTo({ top: 0, behavior: 'smooth' });
    });
    const newActive = active > 1 ? active - 1 : active;
    setActive(newActive);
    if (onStepCb) onStepCb(newActive);
  };
  if (isSaveSuccess) setIsSuccess(true);
  const currentMerchant = useMemo(() => {
    return selectedMerchant[active - 1];
  }, [active, selectedMerchant]);
  const theme = useMantineTheme();
  const handleFormResponce = (values: TypeMerchant) => {
    const transformFn =
      type === 'Add' ? transformFormData : editTransformFormData;
    const payload = transformFn(values);
    onSubmit(
      payload,
      () => {
        if (footerFixed) {
        } else {
          nextStep();
        }
      },
      {
        index: active,
        isSaveList: active === selectedLength,
        values,
      }
    );
  };
  const handleSubmit = () => {
    onClose(true);
    navigate(ERoutes.AllMerchants);
  };
  // Form change trigger and return dirty of form
  const formChangeTrigger = () => {
    if (onFormChange) onFormChange(!!get(formRef, 'current.dirty'));
  };
  const handleFormChange = () => {
    applySearch('', formChangeTrigger);
  };

  const renderForm = (formProps: any) => {
    const { values, setFieldValue } = formProps;
    const isPhysical = !!values.IsPhysical;
    const isOnline = !!values.IsOnline;
    const isPOError = !isPhysical && !isOnline;
    return (
      <Form onChange={handleFormChange}>
        <Stack justify="space-between" sx={{ flex: '1' }}>
          <Topbar
            type={type}
            footerFixed={footerFixed}
            active={active}
            selectedLength={selectedLength}
            onClose={onClose}
          />
          <Paper withBorder={!footerFixed} p="sm" radius={12}>
            <Stack spacing="xs">
              <Header currentMerchant={currentMerchant} />
              <Divider />
              <DetailSection />
              <Divider />
              <CategoryTag
                merChantId={currentMerchant.Id}
                formProps={formProps}
                isPhysical={isPhysical}
                isPOError={isPOError}
                onPhysicalChange={(val) => {
                  if (val === 'no' && !!values.LocationFiles?.length) {
                    setIsWarning(true);
                  } else {
                    setFieldValue('IsPhysical', val === 'yes');
                  }
                }}
                locationFiles={values.LocationFiles}
                onLocationRemove={(i) => {
                  const newLocationFiles = (values.LocationFiles || []).filter(
                    (f: any, fIndex: number) => fIndex !== i
                  );
                  setFieldValue(
                    'LocationFiles',
                    newLocationFiles.length ? newLocationFiles : null
                  );
                }}
                formChangeTrigger={handleFormChange}
              />
            </Stack>
          </Paper>
          <Footer
            footerFixed={footerFixed}
            onBack={onBack}
            formProps={formProps}
            submitDisabled={verifyLocationLoading}
            isPOError={isPOError}
            prevStep={prevStep}
            active={active}
            selectedLength={selectedLength}
            formChangeTrigger={handleFormChange}
          />
        </Stack>
      </Form>
    );
  };

  useEffect(() => {
    // eslint-disable-next-line
  }, [currentMerchant]);

  return (
    <>
      <Stack>
        {!isSaveSuccess && (
          <ScrollArea
            style={notScroll ? {} : { height: window.innerHeight - 50 }}
            viewportRef={viewport}
          >
            <Formik
              initialValues={getInitialValues(
                currentMerchant,
                mechantLocationData,
                type
              )}
              validateOnMount
              validationSchema={schema}
              enableReinitialize
              onSubmit={handleFormResponce}
              innerRef={formRef}
            >
              {renderForm}
            </Formik>
          </ScrollArea>
        )}
        {isSaveSuccess && (
          <Stack sx={{ flex: '1', height: '100%' }}>
            <Group position="right">
              <Button
                variant="subtle"
                onClick={() => {
                  onClose(true);
                  setOpened(false);
                  navigate(ERoutes.AllMerchants);
                }}
              >
                <Group spacing={4}>
                  <Text underline> Close</Text>
                  <Plus style={{ transform: 'rotate(45deg)' }} />
                </Group>
              </Button>
            </Group>
            <Stack sx={{ flex: '1' }} justify="center" align="center">
              <IconCircleCheck
                color={theme.colors.primaryWhite[0]}
                size={60}
                style={{ backgroundColor: '#00AC59', borderRadius: 30 }}
              />
              <Text
                size={32}
                weight={500}
                mt={0}
                align="center"
                sx={{ width: '40%' }}
              >
                All new merchants have been successfully{' '}
                {type === 'Add' ? 'added' : 'updated'}.
              </Text>
              <Button onClick={handleSubmit}>Done</Button>
            </Stack>
          </Stack>
        )}
      </Stack>
      <Confirm
        isOpened={isWarning}
        location={''}
        setPreviousBack={(value: boolean) => {
          setIsWarning(false);
          const { setFieldValue } = (formRef.current || {}) as any;
          if (value && setFieldValue) {
            setFieldValue('IsPhysical', false);
            setFieldValue('LocationFiles', null);
          }
        }}
        des="By changing merchant location to ‘No’, files you have uploaded will be lost. Do you want to continue?"
        submitText="Confirm"
      />
    </>
  );
};
