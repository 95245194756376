import { NumberInput as ManNumberInput, NumberInputProps } from '@mantine/core';
import cn from 'clsx';
import React from 'react';
import classes from './NumberInput.module.scss';

export type INumberInputProps = NumberInputProps & {
  className?: string;
  prefix?: string;
  hideFormatter?: boolean;
  suffix?: string;
};

const NumberInput = ({
  className = '',
  prefix,
  hideFormatter,
  suffix,
  ...other
}: INumberInputProps) => {
  return (
    <ManNumberInput
      parser={(value) => {
        return hideFormatter ? value : value?.replace(/\$\s?|(,*)/g, '');
      }}
      formatter={(value) => {
        return hideFormatter
          ? String(value)
          : !Number.isNaN(parseFloat(value as string))
          ? `${prefix ? `${prefix} ` : ''}${value}`
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              .replace('.0', '')
          : `${prefix ? `${prefix} ` : ''}`;
      }}
      precision={1}
      step={0.1}
      {...other}
      icon={suffix}
      className={cn(classes.wrapper, className)}
    />
  );
};

export default NumberInput;
