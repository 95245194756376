import { BurgerProps, Burger as ManBurger } from '@mantine/core';
import cn from 'clsx';
import classes from './Burger.module.scss';

type IProps = BurgerProps & {
  className?: string;
};

const Burger = ({ className, ...other }: IProps) => {
  return <ManBurger {...other} className={cn(classes.wrapper, className)} />;
};
export default Burger;
