import {
  Header as MantineHeader,
  MediaQuery,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { Burger, Group, Text } from 'src/components';
import logo from '../../assets/images/logo.png';
interface HeaderProps {
  opened: boolean;
  onOpenNavbar: () => void;
  onCloseNavbar: () => void;
}
export const Header = ({
  opened,
  onCloseNavbar,
  onOpenNavbar,
}: HeaderProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  return (
    <MantineHeader height={70} p="md">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Group>
          <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
            <Burger
              opened={opened}
              onClick={opened ? onCloseNavbar : onOpenNavbar}
              size="sm"
              color={theme.colors.gray[6]}
            />
          </MediaQuery>
          <Text
            sx={(theme) => ({
              color: theme.colorScheme === 'dark' ? 'white' : 'black',
              fontWeight: 'bolder',
            })}
          >
            <div style={{ display: 'flex', gap: '5px' }}>
              {colorScheme === 'dark' ? (
                <img src={logo} alt="Ionia" width="90" />
              ) : (
                <img src={logo} alt="Ionia" width="90" />
              )}
            </div>
          </Text>
        </Group>
      </div>
    </MantineHeader>
  );
};
