import { Divider } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons';
import { Fragment } from 'react';
import { Avatar, Box, Text } from 'src/components';

export const ProfileTab = () => {
  return (
    <Fragment>
      <Divider />
      <Box pt="sm">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              padding: '0 2px',
              width: '100%',
            }}
          >
            <Avatar
              radius="xl"
              size={38}
              color="primaryBlue"
              variant="filled"
            />

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text
                sx={() => ({
                  fontWeight: 500,
                  fontSize: 14,
                  lineHeight: '22px',
                })}
              >
                Ionia Admin
              </Text>
              <Text
                sx={(theme) => ({
                  color: theme.colors.secondaryBlack[0],
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: '22px',
                  '@media (max-width: 1200px) and (min-width: 760px)': {
                    display: 'none',
                  },
                })}
              >
                testing@ionia.io
              </Text>
            </div>
            <IconChevronRight
              size={18}
              strokeWidth={2}
              color={'#0E121A'}
              style={{ marginLeft: 'auto' }}
            />
          </div>
        </div>
      </Box>
    </Fragment>
  );
};
