import { CardProps, Card as ManCard } from '@mantine/core';
import cn from 'clsx';
import classes from './Card.module.scss';

type IProps = CardProps & {
  className?: string;
};

const Card = ({ className, ...other }: IProps) => {
  return <ManCard {...other} className={cn(classes.wrapper, className)} />;
};
export default Card;
