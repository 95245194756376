import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import { ColumnDef, flexRender } from '@tanstack/react-table';
import cn from 'clsx';
import find from 'lodash/find';
import get from 'lodash/get';
import { useContext } from 'react';
import { ReactComponent as SortDefaultSvg } from 'src/assets/icons/SortDefault.svg';
import { Group } from 'src/components';
import { TableContext } from '../context';
import classes from './TableHeader.module.scss';

interface Props {
  columns: ColumnDef<any>[];
  sortObj?: any;
  onSortChange?: (key: string, val: string) => void;
  hasMultiselection?: boolean;
  selectColumnWidth?: string;
}

export const TableHeader = ({
  columns,
  sortObj,
  onSortChange,
  hasMultiselection,
  selectColumnWidth,
}: Props) => {
  const { table, theme } = useContext(TableContext);
  return (
    <>
      <colgroup>
        {[
          ...(hasMultiselection
            ? [
                {
                  width: selectColumnWidth,
                },
              ]
            : []),
          ...columns,
        ].map((c, i) => {
          return <col key={i} style={{ width: get(c, 'width') }}></col>;
        })}
      </colgroup>
      <thead
        style={{ backgroundColor: '#E9ECEF' }}
        className={cn(classes.tableHeader, {
          [classes.hasMultiselection]: hasMultiselection,
        })}
      >
        {table.getHeaderGroups().map((headerGroup: any) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header: any) => {
              const foundColumn = find(columns, { accessorKey: header.id });
              const columnKey = get(foundColumn, 'accessorKey');
              const sort = get(sortObj, columnKey);
              return (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <Group spacing="xs">
                      <div style={{ color: theme.colors.primaryBlack }}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                      <span
                        className={classes.sortIcon}
                        onClick={() => {
                          if (onSortChange) {
                            onSortChange(
                              columnKey,
                              sort === 'desc' ? 'asc' : 'desc'
                            );
                          }
                        }}
                      >
                        {get(foundColumn, 'sortable') && (
                          <>
                            {sort === 'asc' ? (
                              <IconChevronUp size={12} />
                            ) : sort === 'desc' ? (
                              <IconChevronDown size={12} />
                            ) : (
                              <SortDefaultSvg />
                            )}
                          </>
                        )}
                      </span>
                    </Group>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
    </>
  );
};
