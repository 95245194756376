import { InputProps, Input as ManInput } from '@mantine/core';
import cn from 'clsx';
import React from 'react';
import classes from './Input.module.scss';

type IProps = InputProps & {
  className?: string;
  value?: string;
  placeholder?: string;
  onChange?: (event: any) => void;
};

const Input = ({ className = '', ...other }: IProps) => {
  return <ManInput {...other} className={cn(classes.wrapper, className)} />;
};

export default Input;
