import { store } from 'src/store';
import { Request } from './request';

const getWalletApiPrefix = () => {
  return store.getState()?.wallet?.apiServer === 'prod'
    ? 'https://api.ionia.io'
    : 'https://apidev.ionia.io';
};

export const apiGetWallet = async () => {
  return Request.call({
    url: `/WalletBot/dashboard`,
    method: 'GET',
    prefix: getWalletApiPrefix(),
  });
};
export const apiGetBalances = async () => {
  return Request.call({
    url: `/WalletBot/balances`,
    method: 'GET',
    prefix: getWalletApiPrefix(),
  });
};
export const apiGetInvoices = async (filters = {}) => {
  return Request.call({
    url: `/WalletBot/invoices`,
    method: 'GET',
    params: filters,
    prefix: getWalletApiPrefix(),
  });
};
