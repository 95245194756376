import { Request } from './request';

export const apiGetMechantLocations = async (id: string | number) => {
  return Request.call({
    url: `/admin/merchant/${id}/location/1/100`,
    method: 'GET',
  });
};
export const apiVerifyLocationCSV = async (data: any) => {
  return Request.call({
    url: `/admin/merchant/ValidateLocationsCSV`,
    method: 'POST',
    data,
    multipart: true,
  });
};
export const apiAddLocationCSV = async (id: string | number, data: any) => {
  return Request.call({
    url: `/admin/merchant/${id}/AddMerchantLocationsFromCSV`,
    method: 'POST',
    data,
    multipart: true,
  });
};
