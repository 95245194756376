import { DropzoneProps, Dropzone as ManDropzone } from '@mantine/dropzone';
import { IconCloudUpload } from '@tabler/icons';
import cn from 'clsx';
import React from 'react';
import classes from './Dropzone.module.scss';

type BaseDropzoneProps = Pick<
  DropzoneProps,
  Exclude<keyof DropzoneProps, 'children'>
>;

type IProps = BaseDropzoneProps & {
  className?: string;
  label?: string | React.ReactNode;
  children?: React.ReactNode;
  required?: boolean;
};

const Dropzone = ({
  className,
  label,
  children,
  required,
  ...other
}: IProps) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      {!!label && (
        <label>
          {label}
          {required && <span className={classes.required}> *</span>}
        </label>
      )}
      <ManDropzone {...other}>
        <div className={classes.placeholder}>
          <span className={classes.placeholderIcon}>
            <IconCloudUpload />
          </span>
          Click to upload or drag & drop
        </div>
        {children}
      </ManDropzone>
    </div>
  );
};
export default Dropzone;
