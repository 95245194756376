import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { tempUser } from 'src/business/app/types/types';
import { Stack, Text } from 'src/components';

export const getColumnHeader = () => {
  const columnHelper = createColumnHelper<tempUser>();
  const defaultColumns: ColumnDef<tempUser, any>[] = [
    columnHelper.accessor((row) => row.appName, {
      id: 'appName',
      minSize: 20,
      maxSize: 40,
      cell: (props) => (
        <Stack align={'start'}>
          <Text>{props.getValue()}</Text>
        </Stack>
      ),
      header: () => <span>App Name</span>,
    }),
    columnHelper.accessor((row) => row.phone, {
      id: 'phone',
      minSize: 20,
      maxSize: 40,
      cell: (props) => (
        <Stack align={'start'}>
          <Text>{props.getValue()}</Text>
        </Stack>
      ),
      header: () => <span>Phone</span>,
    }),
    columnHelper.accessor((row) => row.firstName, {
      id: 'firstName',
      minSize: 20,
      maxSize: 40,
      cell: (props) => (
        <Stack align={'start'}>
          <Text>{props.getValue()}</Text>
        </Stack>
      ),
      header: () => <span>First Name</span>,
    }),
    columnHelper.accessor((row) => row.lastName, {
      id: 'lastName',
      minSize: 20,
      maxSize: 40,
      cell: (props) => (
        <Stack align={'start'}>
          <Text>{props.getValue()}</Text>
        </Stack>
      ),
      header: () => <span>Last Name</span>,
    }),
    columnHelper.accessor((row) => row.status, {
      id: 'status',
      minSize: 20,
      maxSize: 40,
      cell: (props) => (
        <Stack align={'start'}>
          <Text>{props.getValue()}</Text>
          {/* <Badge
            size="lg"
            radius="sm"
            variant="filled"
            style={{
              fontSize: 14,
              fontWeight: "normal",
              backgroundColor: BadgeStatus[props.getValue()].color,
            }}
          >
            {BadgeStatus[props.getValue()].text}
          </Badge> */}
        </Stack>
      ),
      header: () => <span>Status</span>,
    }),
    // columnHelper.accessor((row) => row.userId, {
    //   id: "userId",
    //   minSize: 20,
    //   maxSize: 40,
    //   cell: (props) => (
    //     <Stack align={"start"}>
    //       <Text weight={"bold"} size={16}>
    //         {props.getValue()}
    //       </Text>
    //     </Stack>
    //   ),
    //   header: () => <span>userId</span>,
    // }),
  ];
  return defaultColumns;
};
