import cn from 'clsx';
import classes from './ContentHeader.module.scss';

interface IProps {
  children: React.ReactNode;
  className?: string;
  title?: string | React.ReactNode;
}

const ContentHeader = ({ children, className, title }: IProps) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      {title && <div className={classes.title}>{title}</div>}
      {children}
    </div>
  );
};
export default ContentHeader;
