import cn from 'clsx';
import BaseTable from 'src/components/BaseTable';
import classes from './CSVTable.module.scss';

interface Props {
  csvViewHeader: string[];
  csvViewContent: any[];
  className?: string;
}

const CSVTable = ({ csvViewHeader, csvViewContent, className }: Props) => {
  return (
    <BaseTable className={cn(classes.wrapper, className)}>
      <thead>
        <tr>
          {csvViewHeader.map((h: string, hIndex: number) => {
            return <th key={hIndex}>{h}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {csvViewContent.map((c: any, cIndex: number) => {
          return (
            <tr key={cIndex}>
              {csvViewHeader.map((h: string, hIndex: number) => {
                return <td key={hIndex}>{c[h]}</td>;
              })}
            </tr>
          );
        })}
        <tr></tr>
      </tbody>
    </BaseTable>
  );
};

export default CSVTable;
