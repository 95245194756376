import { IconCircleCheck } from '@tabler/icons';
import cn from 'clsx';
import { Button } from 'src/components';
import classes from './SuccessMessage.module.scss';

const SuccessMessage = ({
  className = '',
  onClick = undefined,
  message = '',
}) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <IconCircleCheck
        color={'white'}
        size={60}
        style={{ backgroundColor: '#00AC59', borderRadius: 30 }}
      />
      <p>{message || 'Success message'}</p>
      <Button type="button" className={classes.button} onClick={onClick}>
        Close
      </Button>
    </div>
  );
};

export default SuccessMessage;
