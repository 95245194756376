import { Modal as ManModal, ModalProps } from '@mantine/core';
import cn from 'clsx';
import classes from './Modal.module.scss';

type IProps = ModalProps & {
  className?: string;
};

const Modal = ({ className, ...other }: IProps) => {
  return <ManModal {...other} className={cn(classes.wrapper, className)} />;
};
export default Modal;
