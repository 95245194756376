const colors = {
  primaryWhite: ['#FFFFFF'],
  primaryBlue: ['#F5F7FF', '#D4DFFF', '#819FFD', '#5A82FD'],
  primaryBlack: ['#0E121A'],
  secondaryBlack: ['#3E444F'],
  primaryRed: ['#F9E0E2', '#F93746'],
  primaryGreen: ['#DEFDF3', '#099768'],
  green: ['#DEFDF3'],
  gray: [
    '#F8F9FA',
    '#F1F3F5',
    '#E9ECEF',
    '#DEE2E6',
    '#CED4DA',
    '#CFD1D8',
    '#868E96',
    '#495057',
    '#343A40',
    '#212529',
  ],
};
export default {
  fontFamily: 'Satoshi-Variable',
  spacing: { xs: 16, sm: 22, md: 25, lg: 30, xl: 40 },
  primaryShade: 3,
  primaryColor: 'primaryBlue',
  colors,
  components: {
    Switch: {
      styles: {
        label: {
          fontWeight: 500,
          fontSize: 14,
          lineHeight: '22px',
          paddingRight: '16px',
          cursor: 'pointer',
        },
        track: {
          cursor: 'pointer',
        },
      },
    },
    Checkbox: {
      styles: {
        input: { cursor: 'pointer' },
      },
    },
    Badge: {
      styles: {
        inner: { fontSize: 12, fontWeight: 600 },
      },
    },
    InputWrapper: {
      styles: {
        label: { marginBottom: 2, color: colors.primaryBlack[0] },
        required: {
          color: '#D92937',
        },
      },
    },
    Dropzone: {
      styles: {
        root: {
          borderColor: colors.primaryBlue[3],
          borderWidth: 1,
          padding: '22px 25px',
          '&:hover': {
            background: colors.primaryBlue[0],
          },
        },
      },
    },
  },
};
