import { useNavigate } from 'react-router-dom';
import { Button, Group, Modal, Stack, Text, Title } from 'src/components';
interface MerchantModalProps {
  location: string | null;
  isOpened: boolean;
  setPreviousBack: (IsBack: boolean) => void;
}

export const MerchantModal = (props: MerchantModalProps) => {
  const { isOpened, setPreviousBack, location } = props;
  const navigate = useNavigate();
  return (
    <Modal
      centered
      withCloseButton={false}
      size={'xs'}
      opened={isOpened}
      onClose={() => setPreviousBack(false)}
      closeOnClickOutside={false}
      closeOnEscape={false}
    >
      <Stack>
        <Title order={4}>
          Are you sure you want to exit adding merchant {location}?
        </Title>
        {!location && <Text> The information you entered will not save</Text>}
        <Group position="center">
          <Button
            size="xs"
            color={'dark'}
            onClick={() => setPreviousBack(false)}
          >
            No
          </Button>
          <Button size="xs" onClick={() => navigate('/merchants')}>
            Yes
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
