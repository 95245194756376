import { Navbar as MantineNavbar } from '@mantine/core';
import { IconApps, IconBuildingStore, IconCpu, IconUsers } from '@tabler/icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from 'src/components';
import { ERoutes } from 'src/helper/constants';
import { NavbarItem, ProfileTab } from '../common';
import { getMenuOpened } from './utils';

interface NavbarProps {
  opened: boolean;
  onCloseNavbar: () => void;
}

export interface MenuItem {
  text: string;
  tooltip?: string;
  icon?: React.ReactNode;
  urlLink: string;
  defaultOpen?: boolean;
  activeUrls?: string[];
  activeSubUrls?: string[];
  subMenus?: MenuItem[];
}

const navbarItemList = [
  {
    text: 'Users',
    icon: <IconUsers />,
    urlLink: ERoutes.User,
  },
  {
    text: 'Clients',
    icon: <IconApps />,
    urlLink: ERoutes.Clients,
    activeSubUrls: [ERoutes.ClientDetail],
  },
  {
    text: 'Merchants',
    icon: <IconBuildingStore />,
    urlLink: ERoutes.AllMerchants,
    defaultOpen: true,
    subMenus: [
      {
        text: 'All Merchants',
        urlLink: ERoutes.AllMerchants,
        activeUrls: [ERoutes.MerchantDetail, ERoutes.Home],
      },
      {
        text: 'New Merchants',
        urlLink: ERoutes.NewMerchants,
      },
    ],
  },
  {
    text: 'WalletBot',
    icon: <IconCpu />,
    urlLink: ERoutes.WalletBot,
  },
] as MenuItem[];

export const Navbar = ({ opened, onCloseNavbar }: NavbarProps) => {
  const navigate = useNavigate();
  const [itemIndex, setItemIndex] = useState(0);
  const [menuOpened, setMenuOpened] = useState(
    getMenuOpened(navbarItemList) as Record<number, unknown>
  );

  const handleMenuClick = (urlLink: string, i: number) => {
    navigate(urlLink);
    setItemIndex(i);
    onCloseNavbar();
  };

  return (
    <MantineNavbar
      p="sm"
      hiddenBreakpoint="sm"
      hidden={!opened}
      width={{ sm: 200, lg: 300 }}
    >
      <MantineNavbar.Section grow mt="md">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          {navbarItemList.map((item, i) => (
            <NavbarItem
              key={i}
              index={i}
              text={item.text}
              icon={item.icon}
              itemIndex={itemIndex}
              tooltip={item.text}
              urlLink={item.urlLink}
              onCloseNavbar={onCloseNavbar}
              onClick={() => {
                handleMenuClick(item.urlLink, i);
              }}
              subMenus={item.subMenus}
              menuIsOpened={!!menuOpened[i]}
              activeSubUrls={item.activeSubUrls}
              openedChange={(opened) => {
                setMenuOpened({
                  [i]: opened,
                });
              }}
            />
          ))}
        </Box>
      </MantineNavbar.Section>
      <MantineNavbar.Section mt="sm">
        <ProfileTab />
      </MantineNavbar.Section>
    </MantineNavbar>
  );
};
