import { Drawer, useMantineTheme } from '@mantine/core';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Edit } from 'tabler-icons-react';
import {
  GiftCardGateway,
  GiftCardGateways,
} from 'src/business/app/constants/merchant.constant';
import { TypeMerchant } from 'src/business/app/types/TypeMerchant';
import { Avatar, Badge, Group, Stack, Text } from 'src/components';
import { Confirm } from 'src/components/common/ModelDialog';
import { Table } from 'src/components/table';
import { ERoutes } from 'src/helper/constants';
import { applySearch } from 'src/utils';
import { actionCb } from 'src/utils/action';
import { AddEditMerchants } from '../addEditMerchants';
import classes from './AllMerchants.module.scss';
import { useActions, useIndexData } from './selectorData';

export const AllMerchants = () => {
  const navigate = useNavigate();
  const {
    getAllMerchants,
    updateMerchant,
    syncResetLocationCSV,
    syncResetMerchantLocations,
    getMerchantLocations,
    addLocationCSV,
  } = useActions();
  const { allMerchants, allMerchantsLoading } = useIndexData();
  const [resetSelection, setResetSelection] = useState('0');
  const [selectedMerchant, setSelectedMerchant] = useState<TypeMerchant[]>([]);
  const [opened, setOpened] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [closeDrawer, setCloseDrawer] = useState<boolean>(false);
  const [searchVal, setSearchVal] = useState<string>('');
  // const [sortObj, setSortObj] = useState({
  //   LegalName: 'desc',
  // } as Record<string, unknown>);
  const theme = useMantineTheme();
  const columns = useMemo<ColumnDef<TypeMerchant>[]>(
    () => [
      {
        header: 'Id',
        accessorKey: 'Id',
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        width: '100px',
      },
      {
        header: 'Merchant Name',
        accessorKey: 'LegalName',
        cell: (info: CellContext<TypeMerchant, any>) => (
          <Group>
            <Avatar
              radius="xl"
              size="md"
              color="primaryBlue"
              src={info.row.original.LogoUrl}
              className={classes.avatar}
            />
            <Text>{info.getValue()}</Text>
          </Group>
        ),
        footer: (props) => props.column.id,
        // TODO: hide sort for now
        // sortable: true,
      },
      {
        header: 'Status',
        accessorKey: 'IsActive',
        cell: (info) => (
          <Badge
            py={10}
            color={info.getValue() ? 'green' : 'primaryRed'}
            sx={{
              textTransform: 'capitalize',
              color: info.getValue()
                ? theme.colors.primaryGreen[1]
                : theme.colors.primaryRed[1],
            }}
            size="md"
          >
            {info.getValue() ? 'Active' : 'Inactive'}
          </Badge>
        ),
        footer: (props) => props.column.id,
        // sortable: true,
      },
      {
        header: 'Discount From Gateway',
        accessorKey: 'RevenueShare',
        cell: (info) => `${info.getValue()}%`,
        footer: (props) => props.column.id,
        // sortable: true,
      },
      {
        header: 'Gateway',
        accessorKey: 'GiftCardGatewayId',
        cell: (info) => {
          const type: GiftCardGateway = info.getValue() as GiftCardGateway;
          return GiftCardGateways[type];
        },
        footer: (props) => props.column.id,
        // sortable: true,
      },
    ],
    // eslint-disable-next-line
    []
  );
  const handleFetchData = (
    pNumber: number,
    pSize: number,
    pSearch?: string
  ) => {
    getAllMerchants({
      pageNumber: pNumber,
      pageSize: pSize,
      searchText: pSearch,
    });
  };
  const onEditRows = (rows: any) => {
    syncResetMerchantLocations();
    syncResetLocationCSV();
    getMerchantLocations(rows[0]?.Id);
    setSelectedMerchant(rows);
    setOpened(true);
  };
  const handleActionCb = ({ res, cb, values }: any) => {
    const thisMerchantId = res?.Id || '';
    const cbFn = () => {
      const newMerchants = selectedMerchant.map((m) => {
        if (m.Id === thisMerchantId) {
          return res;
        }
        return m;
      });
      setSelectedMerchant(newMerchants);
      if (cb) cb();
      handleFetchData(pageNumber, pageSize, searchVal);
    };
    const locationFile = (values?.LocationFiles || [])[0];
    if (
      !!locationFile &&
      locationFile.path &&
      locationFile.type &&
      locationFile.lastModified &&
      !!thisMerchantId
    ) {
      const fData = new FormData();
      fData.append('File', locationFile);
      addLocationCSV(
        thisMerchantId,
        fData,
        actionCb(() => {
          cbFn();
        })
      );
    } else {
      cbFn();
    }
  };
  useEffect(() => {
    syncResetMerchantLocations();
    syncResetLocationCSV();
    handleFetchData(pageNumber, pageSize, searchVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Stack>
        <Stack>
          <Table
            data={allMerchants.Items || []}
            columns={columns}
            totalCount={allMerchants?.TotalCount}
            pageCount={allMerchants?.TotalPages}
            pageSize={pageSize}
            pageNumber={pageNumber}
            hasPagination={true}
            hasMultiselection={true}
            buttonCancel={{
              children: (
                <Text underline size="md" weight={500}>
                  Cancel
                </Text>
              ),
              variant: 'subtle',
              sx: (theme) => ({
                '&:disabled': {
                  backgroundColor: theme.colors.gray[0],
                },
              }),
            }}
            buttonSubmit={{
              leftIcon: <Edit />,
              children: 'Edit',
            }}
            toolbarContent={{
              title: 'All Merchants',
              hasSearchbox: true,
              displayTotal: true,
            }}
            onButtonSubmit={onEditRows}
            onPaginationChange={({ pageIndex, pageSize: tPageSize }) => {
              setPageNumber(pageIndex);
              setPageSize(tPageSize);
              handleFetchData(pageIndex, tPageSize, searchVal);
            }}
            manualPagination={true}
            onDownload={() => {
              // TODO: export feature
            }}
            resetSelection={resetSelection}
            search={searchVal}
            setSearch={(val: string) => {
              setSearchVal(val);
              applySearch(val, (v: string) => {
                setPageNumber(1);
                handleFetchData(1, pageSize, v);
              });
            }}
            onRowClick={(row) => {
              navigate(`/merchant/${row.original?.Id}`);
            }}
            loading={allMerchantsLoading}
            // sortObj={sortObj}
            // onSortChange={(k, v) => {
            //   setSortObj({
            //     [k]: v,
            //   });
            // }}
            selectColumnWidth="80px"
          />
        </Stack>
      </Stack>
      <Drawer
        withCloseButton={false}
        opened={opened}
        onClose={() => setOpened(false)}
        padding="lg"
        size="80%"
        position="right"
        closeOnClickOutside={false}
        closeOnEscape={false}
      >
        <AddEditMerchants
          selectedMerchant={selectedMerchant}
          setIsSuccess={setIsSuccess}
          setOpened={setOpened}
          type="Edit"
          onClose={(val) => {
            if (val !== true) {
              setCloseDrawer(true);
            } else {
              setOpened(false);
              setSelectedMerchant([]);
              setResetSelection(resetSelection === '1' ? '2' : '1');
            }
          }}
          onSubmit={(payload, cb, other) => {
            const values = other?.values;
            updateMerchant(
              payload.Id,
              payload,
              actionCb((res) => {
                handleActionCb({ res, cb, values });
              })
            );
          }}
          onStepCb={(thisActive: number) => {
            syncResetMerchantLocations();
            syncResetLocationCSV();
            const thisSelectedMerchant = selectedMerchant[thisActive - 1];
            if (
              !!thisSelectedMerchant?.Id &&
              thisSelectedMerchant?.IsPhysical
            ) {
              getMerchantLocations(thisSelectedMerchant.Id);
            }
          }}
        />
      </Drawer>
      {!isSuccess && (
        <Confirm
          isOpened={closeDrawer}
          location={ERoutes.AllMerchants}
          setPreviousBack={(value: boolean) => {
            setCloseDrawer(false);
            if (value) setOpened(false);
          }}
        ></Confirm>
      )}
    </>
  );
};
