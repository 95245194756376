/* eslint-disable @typescript-eslint/no-unused-vars */
import { useForm } from '@mantine/form';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginMutationInput } from 'src/business/app/types/types';
import { Box, Button, Group, Paper, Stack } from 'src/components';
import { TextInput } from 'src/components/FormFields';

export const Login = () => {
  const navigate = useNavigate();
  const [loginErrors, setLoginErrors] = useState();

  const { onSubmit, getInputProps, setFieldError } = useForm({
    initialValues: {
      userName: '',
      password: '',
    },
    validate: {
      userName: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const onLogin = async (_values: LoginMutationInput) => {
    try {
      //hardcoding login for now, will implement auth soon
      if (
        _values.userName != 'testing@ionia.io' ||
        _values.password != 'test123'
      )
        setFieldError('userName', 'Incorrect credentials, please try again');
      else {
        const result = { Data: '1124567', Successful: true };
        //await login(values).unwrap();
        if (result.Data && result.Successful) {
          localStorage.setItem('token', result.Data);
          navigate('/');
        }
      }
    } catch (ex) {}
  };
  return (
    <Paper>
      <Stack sx={{ height: '80vh', lineHeight: 'sm' }}>
        <Box sx={{ maxWidth: 600 }} mx="auto" my="md">
          <form onSubmit={onSubmit((values) => onLogin(values))}>
            <TextInput
              required
              label="Email"
              placeholder="your@email.com"
              {...getInputProps('userName')}
            />
            <TextInput
              required
              label="Password"
              placeholder="***"
              {...getInputProps('password')}
              type="password"
            />

            <Group position="center" mt="md">
              <Button loading={false} type="submit">
                Submit
              </Button>
            </Group>
          </form>
        </Box>
      </Stack>
    </Paper>
  );
};
