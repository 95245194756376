import { ArrowNarrowLeft } from 'tabler-icons-react';
import { Button, Group, Text } from 'src/components';
import { FixedFooter } from 'src/components/Page';
import { useFooterData } from './selectorData';

interface Props {
  footerFixed?: boolean;
  onBack?: (isDirty?: boolean) => void;
  formProps: any;
  submitDisabled?: boolean;
  isPOError: boolean;
  prevStep: () => void;
  active: number;
  selectedLength: number;
  formChangeTrigger?: () => void;
}

const Footer = ({
  footerFixed,
  onBack,
  formProps,
  isPOError,
  prevStep,
  active,
  selectedLength,
  submitDisabled,
  formChangeTrigger,
}: Props) => {
  const { updateMerchantLoading, addMerchantLoading, addLocationCSVLoading } =
    useFooterData();
  const submitLoading =
    updateMerchantLoading || addMerchantLoading || addLocationCSVLoading;
  return (
    <>
      {footerFixed ? (
        <FixedFooter>
          <Button
            variant="outline"
            leftIcon={<ArrowNarrowLeft />}
            onClick={() => {
              if (onBack) onBack(formProps.dirty);
            }}
            isLink
            disabled={submitLoading}
          >
            Back
          </Button>
          <Group position="right">
            <Button
              type="button"
              variant="outline"
              disabled={!formProps.dirty || submitLoading}
              onClick={() => {
                formProps.resetForm();
                if (formChangeTrigger) formChangeTrigger();
              }}
            >
              Revert Changes
            </Button>
            <Button
              type="submit"
              disabled={
                isPOError ||
                !formProps.dirty ||
                !formProps.isValid ||
                submitDisabled
              }
              loading={submitLoading}
            >
              <Text weight={500}>Save</Text>
            </Button>
          </Group>
        </FixedFooter>
      ) : (
        <Group position="right">
          <Button
            variant="outline"
            leftIcon={<ArrowNarrowLeft />}
            onClick={prevStep}
            disabled={active === 1 || submitLoading || submitDisabled}
          >
            Back
          </Button>
          <Button
            type="submit"
            loading={submitLoading}
            disabled={isPOError || !formProps.isValid}
          >
            <Text weight={500}>
              {active === selectedLength
                ? 'Save and finish'
                : 'Save and go to next Merchant'}{' '}
            </Text>
          </Button>
        </Group>
      )}
    </>
  );
};

export default Footer;
