import * as merchantLocationActions from 'src/store/actions/merchantLocation';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useCategoryTagData = () => {
  const verifyLocationCSVStore = useReducerData(
    'merchantLocation',
    'verifyLocationCSV',
    {}
  );
  const merchantLocationStore = useReducerData(
    'merchantLocation',
    'merchantLocations',
    {}
  );
  return {
    verifyLocationLoading: verifyLocationCSVStore.loading,
    verifyLocationData: verifyLocationCSVStore.data || [],
    mechantLocationData: merchantLocationStore.data || {},
    mechantLocationLoading: merchantLocationStore.loading,
  };
};
export const useIndexData = () => {
  const verifyLocationCSVStore = useReducerData(
    'merchantLocation',
    'verifyLocationCSV',
    {}
  );
  const merchantLocationStore = useReducerData(
    'merchantLocation',
    'merchantLocations',
    {}
  );
  return {
    verifyLocationData: verifyLocationCSVStore.data || [],
    verifyLocationLoading: verifyLocationCSVStore.loading,
    mechantLocationData: merchantLocationStore.data || {},
  };
};
export const useFooterData = () => {
  return {
    updateMerchantLoading: useReducerData(
      'merchant',
      'updateMerchant.loading',
      false
    ),
    addMerchantLoading: useReducerData(
      'merchant',
      'addMerchant.loading',
      false
    ),
    addLocationCSVLoading: useReducerData(
      'merchantLocation',
      'addLocationCSV.loading',
      false
    ),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...merchantLocationActions,
  });
};
