import { combineReducers } from 'redux';

import AuthReducer from './auth';
import ClientReducer from './client';
import MerchantReducer from './merchant';
import MerchantLocationReducer from './merchantLocation';
import WalletReducer from './wallet';

const reducers = {
  auth: AuthReducer,
  merchant: MerchantReducer,
  merchantLocation: MerchantLocationReducer,
  wallet: WalletReducer,
  client: ClientReducer,
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

export type IReducerName = keyof typeof reducers;

export default combinedReducer;
